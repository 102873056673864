import { Bar } from '@nivo/bar';

import { BarSingleChartProps } from './BarSingleChart.props';
import { BarSingleChartComponent } from './BarSingleChartComponent';

// TODO: determine how these work with BarChartAreaLayer and implement accordingly.
// These are just placeholder functions to avoid typescript errors.
const showTooltip = (tooltip: React.ReactNode, event: React.MouseEvent<SVGElement>) => {};
const hideTooltip = () => {};

export const BarSingleChart = ({
  data,
  color,
  highlightItem = () => {},
  grayOutItem = () => {},
}: BarSingleChartProps): JSX.Element => (
  <Bar
    enableGridY={false}
    enableGridX={false}
    layout="horizontal"
    width={400}
    height={21}
    // data={data as Record<string, string | number>[]}
    data={data}
    keys={['value']}
    padding={0.02}
    barComponent={(props) => (
      <BarSingleChartComponent
        {...props}
        {...props.bar}
        data={props.bar.data}
        highlightItem={highlightItem}
        grayOutItem={grayOutItem}
        showTooltip={showTooltip}
        hideTooltip={hideTooltip}
      />
    )}
    borderRadius={4}
    // indexBy="indexValue"
    indexBy="label"
    colors={[color]}
    layers={['grid', 'axes', 'bars']}
    axisBottom={{
      tickSize: 0,
      tickPadding: 10,
      ariaHidden: true,
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 10,
    }}
  />
);
