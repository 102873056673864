import { CSSProperties } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * SampleTemplate component styles
 * @returns {Function}
 */

const rightLineStyles: CSSProperties = {
  position: 'absolute',
  width: variables.borderWidth.xl,
  height: '100%',
  borderRight: `solid ${variables.borderWidth.xl} ${variables.color.primary.mainPurple}`,
  top: 0,
  left: 'calc(100% - 0.4rem)',
};

const rigthLineBeforeStyles: CSSProperties = {
  content: "''",
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '-0.8rem',
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0.8rem 0.8rem 0.8rem 0',
  borderColor: `transparent ${variables.color.primary.mainPurple} transparent transparent`,
};

export const useStyles = makeStyles(() =>
  createStyles({
    sampleTemplate: {
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      position: 'relative',
    },

    rightLine: {
      ...(rightLineStyles as { [key: string]: string }),

      '&::before': {
        ...rigthLineBeforeStyles,
      },
    },

    rightLineGray: {
      ...(rightLineStyles as { [key: string]: string }),
      borderRight: `solid ${variables.borderWidth.xl} ${variables.color.primary.halfMediumGray}`,

      '&::before': {
        ...rigthLineBeforeStyles,
        borderColor: `transparent ${variables.color.primary.halfMediumGray} transparent transparent`,
      },
    },

    content: {
      height: '100%',
      position: 'relative',
    },

    iframe: {
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },

    iframeWrapper: {
      height: '100%',
      transition: 'opacity 0.4s',

      '&[data-loaded="false"]': {
        opacity: variables.opacity.medium,
        pointerEvents: 'none',
      },

      '&[data-loaded="true"]': {
        opacity: variables.opacity.full,
        pointerEvents: 'initial',
      },
    },

    moduleModalWrapper: {
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      height: '100vh',
      zIndex: 1,
      marginRight: '24.5rem',
      padding: '7.8rem 4.8rem 0 4.8rem',
    },

    moduleContent: {
      position: 'relative',
      width: '60rem',
    },

    closeBtn: {
      width: '3rem',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
      background: variables.color.primary.white,
      top: '-6rem',
      right: '0rem',
      cursor: 'pointer',
    },
  }),
);
