import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * UniversalContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    addSignalDropdown: {
      width: '100%',
    },
    addButton: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      marginLeft: '0.5rem',
      minWidth: '3.8rem',
      padding: theme.spacing(1, 1),
      width: '3.8rem',

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },

      '&:hover': {
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },

      '& svg path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:hover svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },
    },
    menuList: {
      padding: '0 10px',
      marginLeft: '-10px',
      boxShadow: '1px 1px 7px #dadee2',
      width: '30rem',
    },
    search: {
      width: '100%',
      // marginBottom: '10px',
    },
    signalsWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    signalItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '8px 20px 8px 10px',
      border: '1px solid #dadee2',
      borderRadius: '1rem 0.4rem',
      position: 'relative',
      width: 'fit-content',
      cursor: 'pointer',
    },
    closeButton: {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: 0,
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& svg': {
        stroke: variables.color.primary.darkestGray,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },
    editingItem: {
      display: 'block', // 'flex',
      justifyContent: 'space-between',
      padding: '0 0 1.5rem 0',
    },
    formatDropdown: {
      margin: '.5rem 0 0 0',
      width: '27.6rem', // '180px',
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        width: '100%',
      },
    },
    formatItem: {
      display: 'flex',
      minWidth: '240px',
      justifyContent: 'space-between',
    },
    editingSignal: {
      gap: '5px',
      // minWidth: '180px',
      border: '1px solid #dadee2',
      display: 'flex',
      padding: '5px 20px 5px 10px',
      position: 'relative',
      borderRadius: '1rem 0.4rem',
      alignItems: 'center',
    },
    removeBtn: {
      // border: '1px solid #dadee2',
      background: 'none',
      borderRadius: '0.6rem',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'inline-flex',
      height: '24px',
      margin: '0 0 0 .5rem',
      minWidth: '24px',
      padding: '0 0', //'0.85rem 0.8rem',
      position: 'relative',
      width: '24px',
    },
    personalizationToken: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    signalCapsule: {
      alignItems: 'center',
      backgroundColor: variables.color.primary.capsulePurple, // d8b5ff;
      border: '0 none',
      borderRadius: '2rem',
      color: variables.color.primary.black,
      display: 'flex',
      height: '2.4rem',
      // margin: 'auto 0',
      minHeight: '2.4rem',
      overflow: 'hidden',
      padding: '.3rem 0',
      // pointerEvents: 'none',
      // width: '18rem',
    },
    signalText: {
      margin: 'auto 0',
      overflow: 'hidden',
      padding: '0 1.5rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      // '&:hover': {
      //   whiteSpace: 'normal',
      // },
    },
  }),
);

export const useTooltipStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: variables.color.primary.halfMediumGray,
    },
    tooltip: {
      backgroundColor: variables.color.primary.white,
      boxShadow: theme.shadows[8],
    },
    tooltipArrow: {
      backgroundColor: variables.color.primary.white,
      border: `0.1rem solid ${variables.color.primary.halfMediumGray}`,
      color: variables.color.primary.mediumGray,
      fontSize: '1.1rem',
    },
    tooltipPlacementBottom: {
      margin: '4px 0 0 0',
    },
  }),
);
