import { Icon, Select } from '@components';
import { content } from '@content';
import { Box, Button, InputBase, Menu, MenuItem, Tooltip, debounce } from '@material-ui/core';
import { SignalsSearchItem, attributeLibrary } from '@modules';
import { HTMLElementWithDocument, TreatmentBuilderContext } from '@routes';
import { Attribute } from '@routes/SignalBuilder/Attribute';
import { SEARCH_ATTRIBUTE_DELAY } from '@routes/SignalBuilder/DataAttributeLibrary/DataAttributeLibrary.const';
import { useAppDispatch } from '@store';
import { format, useHistory } from '@utils';
import React, { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FORMAT_OPTIONS, PROFILE_ATTRIBUTES } from './AddSignalDropdown.const';
import { getChosenElem, rewriteChosenElementContent } from './AddSignalDropdown.helpers';
import { AddSignalDropdownProps, TPersonalizationToken } from './AddSignalDropdown.props';
import { useStyles, useTooltipStyles } from './AddSignalDropdown.styles';
import SelectDataFormat from './SelectDataFormat/SelectDataFormat';
import { variables } from '@styles';

const AddSignalDropdown = ({ targetInputFieldId, onSignalInsert = undefined }: AddSignalDropdownProps) => {
  const dispatch = useAppDispatch();
  const { businessUnit } = useHistory().query;
  const styles = useStyles();
  const tooltipStyles = useTooltipStyles();
  const { items: attributes } = attributeLibrary.useListData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSignals, setSelectedSignals] = useState<TPersonalizationToken[]>([]);
  const filteredAttributes = useMemo(
    () =>
      PROFILE_ATTRIBUTES.concat(attributes).filter(
        (attribute) =>
          !selectedSignals.map((signal) => signal.label).includes(attribute.label) &&
          ['textual', 'numeric'].includes(attribute.type),
      ),
    [PROFILE_ATTRIBUTES, attributes, selectedSignals],
  );

  const { chosenModuleElemId, cursorPosition, setCursorPosition, setIsModuleChanged } =
    useContext(TreatmentBuilderContext);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runSearch = debounce(
    useCallback(
      (keyword: string) => {
        dispatch(
          attributeLibrary.thunk.search({
            businessUnit: businessUnit!,
            keyword,
          }),
        );
      },
      [dispatch, businessUnit],
    ),
    SEARCH_ATTRIBUTE_DELAY,
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      runSearch(event.target.value);
    },
    [runSearch],
  );

  const handleSignalClick = (signal: SignalsSearchItem) => {
    const chosenElem = getChosenElem(targetInputFieldId);
    const signalBody = ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label}" contenteditable="false" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label}</personefy-token> `;

    if (chosenElem) {
      setSelectedSignals((prev) => [
        {
          label: signal.label,
          name: signal.name,
          signalType: signal.signalType,
          totalSubscribers: signal.totalSubscribers,
          updatedAt: signal.updatedAt,
          type: signal.type,
          isNew: true,
          isDropdownShown: false,
        },
        ...prev,
      ]);
      setTimeout(() => {
        setSelectedSignals((prev) =>
          prev.map((item) => ({
            ...item,
            isDropdownShown: true,
          })),
        );
      });
      rewriteChosenElementContent(chosenElem, cursorPosition, signalBody, chosenModuleElemId, onSignalInsert);

      const tokens = chosenElem.querySelectorAll('personefy-token');
      // Array.from(tokens).forEach((token: any) => {
      //   token.addEventListener(
      //     'DOMSubtreeModified',
      //     (event: any) => {
      //       console.log('<AddSignalDropdown> handleSignalClick - DOMSubtreeModified');
      //       if (event.target.parentElement && event.target.parentElement.getAttribute('initialValue')) {
      //         event.target.parentElement.innerHTML = event.target.parentElement.getAttribute('initialValue');
      //       }
      //     },
      //     false,
      //   );
      // });
      Array.from(tokens).forEach((token: any) => {
        const observer = new MutationObserver((mutationList) =>
          mutationList
            .filter((m) => m.type === 'childList')
            .forEach((m) => {
              m.addedNodes.forEach(() => {
                if (m.target.parentElement && m.target.parentElement.getAttribute('initialValue')) {
                  m.target.parentElement.innerHTML = m.target.parentElement.getAttribute('initialValue') ?? '';
                }
              });
            }),
        );
        observer.observe(token, { childList: true, subtree: true });
      });

      setTimeout(() => {
        chosenElem.focus();
      });

      handleClose();
      setIsModuleChanged(true);
    }
  };

  const handleRemoveSignal = (signal: TPersonalizationToken) => () => {
    const chosenElem = getChosenElem(targetInputFieldId);
    const simpleRegEx = new RegExp(`<personefy-token [A-Za-z]+.*?>${signal.label}</personefy-token>`, 'g');
    let simpleMatch = null as RegExpMatchArray | null;
    const formatRegEx = new RegExp(
      `<personefy-token [A-Za-z]+.*?>${signal.label} [|] ${signal.format}</personefy-token>`,
      'g',
    );
    let formatMatch = null as RegExpMatchArray | null;
    const curPotentialTokens = chosenElem?.innerHTML
      .split('</personefy-token>')
      .filter((elem: string) => elem)
      .map((elem: string) => `${elem}</personefy-token>`);

    curPotentialTokens.forEach((potentialTokens: string) => {
      if (!simpleMatch) {
        simpleMatch = potentialTokens.match(simpleRegEx);
      }

      if (!formatMatch) {
        formatMatch = potentialTokens.match(formatRegEx);
      }
    });

    let currentPosition;

    if (chosenElem) {
      if (simpleMatch?.length) {
        // checking for spaces between token
        if (chosenElem.innerHTML.indexOf(` ${simpleMatch[0]} `) != -1) {
          currentPosition = chosenElem.innerHTML.indexOf(` ${simpleMatch[0]} `);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(` ${simpleMatch[0]} `, '');
        } else {
          currentPosition = chosenElem.innerHTML.indexOf(simpleMatch[0]);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(simpleMatch[0], '');
        }
      } else if (formatMatch?.length) {
        // checking for spaces between token
        if (chosenElem.innerHTML.indexOf(` ${formatMatch.join('|')} `) !== -1) {
          currentPosition = chosenElem.innerHTML.indexOf(` ${formatMatch.join('|')} `);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(` ${formatMatch.join('|')} `, '');
        } else {
          currentPosition = chosenElem.innerHTML.indexOf(formatMatch.join('|'));
          chosenElem.innerHTML = chosenElem.innerHTML.replace(formatMatch.join('|'), '');
        }
      }

      chosenElem.dispatchEvent(new Event('input'));

      if (!signal.isNew) {
        setSelectedSignals((prev) => prev.filter((item) => item.label !== signal.label));
      }

      setTimeout(() => {
        chosenElem.focus();
      });
    }

    return currentPosition;
  };

  // const handleFormatChange = (signal: TPersonalizationToken) => (e: any) => {
  const handleFormatChange = (signal: TPersonalizationToken) => {
    const chosenElem = getChosenElem(targetInputFieldId);

    // select PTOKEN
    const signalElement = chosenElem?.querySelector(`personefy-token[data-attribute-name="${signal.name}"]`);

    // Update data format values
    if (signalElement) {
      const initialValue = signal.format === 'NO_DATA' ? signal.label : `${signal.label} | ${signal.format}`;
      const innerHtml = signal.format === 'NO_DATA' ? signal.label : `${signal.label} | ${signal.format}`;
      signalElement.setAttribute('initialValue', initialValue);
      signalElement.innerHTML = innerHtml;

      setIsModuleChanged(true);
      setCursorPosition(chosenElem.innerHTML.length);

      setTimeout(() => {
        chosenElem.focus();
      });
    }

    /*
    const signals = [...selectedSignals];
    const curSignal = signals.find((selectedSignal) => selectedSignal.label === signal.label);
    const signalBody =
      signal.format !== 'NO_DATA'
        ? ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label} | ${signal.format}"  contenteditable="false" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label} | ${signal.format}</personefy-token> `
        : ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label} | ${signal.format}"  contenteditable="false" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label}</personefy-token> `;

    if (chosenElem && curSignal) {
      const currentPosition = handleRemoveSignal(signal)() ?? cursorPosition;

      // curSignal.format = e.target.value;
      curSignal.isNew = false;
      curSignal.isDropdownShown = false;

      setSelectedSignals(signals);
      setTimeout(() => {
        setSelectedSignals((prev) =>
          prev.map((item) => ({
            ...item,
            isDropdownShown: true,
          })),
        );
      });

      rewriteChosenElementContent(chosenElem, currentPosition, signalBody, chosenModuleElemId, onSignalInsert);

      const tokens = chosenElem.querySelectorAll('personefy-token');
      Array.from(tokens).forEach((token: any) => {
        const observer = new MutationObserver((mutationList) =>
          mutationList
            .filter((m) => m.type === 'childList')
            .forEach((m) => {
              m.addedNodes.forEach(() => {
                console.log('<AddSignalDropdown> handleSignalClick - DOMSubtreeModified');
                if (m.target.parentElement && m.target.parentElement.getAttribute('initialValue')) {
                  m.target.parentElement.innerHTML = m.target.parentElement.getAttribute('initialValue') ?? '';
                }
              });
            }),
        );
        observer.observe(token, { childList: true, subtree: true });
      });

      // Array.from(tokens).forEach((token: any) => {
      //   token.addEventListener(
      //     'DOMSubtreeModified',
      //     (event: any) => {
      //       if (event.target.parentElement && event.target.parentElement.getAttribute('initialValue')) {
      //         event.target.parentElement.innerHTML = event.target.parentElement.getAttribute('initialValue');
      //       }
      //     },
      //     false,
      //   );
      // });

      
    }
    */
  };

  useEffect(() => {
    runSearch('');

    (function () {
      (window as any).currentMouseX = 0;
      (window as any).currentMouseY = 0;

      window.addEventListener(
        'mousemove',
        function (e) {
          (window as any).currentMouseX = e.pageX;
          (window as any).currentMouseY = e.pageY;
        },
        true,
      );
    })();

    return () => {
      window.removeEventListener('mousemove', () => {});
    };
  }, []);

  useEffect(() => {
    const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
    const chosenElem = moduleElem?.contentDocument.getElementById(chosenModuleElemId);
    const regEx = /<personefy-token data-attribute-name="([A-Za-z]+.*)" [A-Za-z]+.*>([A-Za-z]+.*)<\/personefy-token>/g;
    let res;
    const curSignals: any = [];
    const potentialTokens = chosenElem?.innerHTML
      .split('</personefy-token>')
      .filter((elem) => elem)
      .map((elem) => `${elem}</personefy-token>`);

    potentialTokens?.forEach((potentialToken) => {
      do {
        res = regEx.exec(potentialToken);

        if (res) {
          const parts = (res.length === 3 ? res[2] : res[1]).split(' | ');
          curSignals.push({ name: res.length === 3 ? res[1] : '', label: parts[0], format: parts[1] });
        }
      } while (res);
    });

    setSelectedSignals(
      curSignals.map(
        (signalLabel: any) =>
          ({
            label: signalLabel.label,
            format: signalLabel.format ?? 'NO_DATA',
            name: signalLabel.name,
            isNew: false,
            isDropdownShown: true,
          } as TPersonalizationToken),
      ),
    );
  }, [chosenModuleElemId]);

  return (
    <Box className={styles.addSignalDropdown}>
      <Box className={styles.signalsWrapper}>
        {selectedSignals.map((signal, index) => (
          <React.Fragment key={`selected-signal-${index}`}>
            <Box className={styles.editingItem}>
              <div className={styles.personalizationToken}>
                <Tooltip
                  title={
                    signal.updatedAt
                      ? signal.signalType === 'foreign'
                        ? `${format.date(signal.updatedAt, 'MM/dd/yy')}`
                        : `${format.date(signal.updatedAt, 'MM/dd/yy')} | ${signal.type} | ${format.number(
                            signal.totalSubscribers,
                          )}`
                      : ''
                  }
                  placement="bottom"
                  arrow
                  classes={tooltipStyles}
                >
                  <Box className={`${styles.editingSignal} ${styles.signalCapsule} signal-item`}>
                    <p className={styles.signalText}>{signal?.label}</p>
                  </Box>
                </Tooltip>

                <SelectDataFormat signal={signal} onClick={handleFormatChange} />

                <Button className={styles.removeBtn} onClick={handleRemoveSignal({ ...signal, isNew: false })}>
                  <Icon.TrashOutline width={'16px'} height={'16px'} />
                </Button>
              </div>
            </Box>
          </React.Fragment>
        ))}
      </Box>

      <Button
        id="add-signal-btn"
        className={`${styles.addButton} ${anchorEl ? 'active' : ''}`}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        data-opened={Boolean(anchorEl)}
      >
        <Icon.Add fill={variables.color.primary.darkestGray} height="20" width="20" />
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: styles.menuList,
        }}
      >
        <InputBase
          className={styles.search}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        {filteredAttributes.map((attribute, index) => (
          <Attribute
            key={`attribute-${index}`}
            className={''}
            item={attribute}
            dateOnly={true}
            isCloudShown={attribute.signalType === 'foreign'}
            isClickable={true}
            onClick={handleSignalClick}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default AddSignalDropdown;
