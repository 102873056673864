import { createAsyncThunk as base_createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunk } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { RootState } from './store';
import { ThunkAPIConfig } from './store.types';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function createAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> {
  // return base_createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(typePrefix, async (arg, thunkAPI) =>
  //   payloadCreator(arg, thunkAPI),
  // );
  return base_createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(typePrefix, payloadCreator);
}
