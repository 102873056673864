import { Icon } from '@components';
import { Box, IconButton } from '@material-ui/core';

import { MODULE_MODAL_Y_OFFSET_CORREC_NUM } from '../SampleTemplate.const';

import { addWidths } from './ModuleModal.utils';
import { useWrappModalHtmlElems } from './ModuleModal.hooks';
import { ModuleModalProps } from './ModuleModal.props';
import { useStyles } from './ModuleModal.styles';
import { HIGHLIGHTED_ELEM_BORDER_WIDTH } from './ModuleModal.const';
import { useContext, useEffect, useRef, useState } from 'react';
import { variables } from '@styles';
import { HTMLElementWithDocument } from '../SampleTemplate.types';
import { TreatmentBuilderContext } from '@routes/TemplateView/TemplateView.const';
import { useAppDispatch } from '@store';
import { modules } from '@modules';
import { ViewportSize, ViewportSizeContext, ViewportWidth } from '@layouts';
import { delay } from '@utils/func/func';

/**
 ModuleModal component
 @returns {JSX.Element}
 */

export const ModuleModal = ({
  modalTopPosition,
  moduleHtmlElem,
  isTemplateEditor = false,
  isEditorChanged,
  templateStyles,
  setIsEditorChanged,
  setRightPanelElem,
  onChange,
  onClose,
  onRemove,
}: ModuleModalProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { redefinedDoc } = useWrappModalHtmlElems(
    moduleHtmlElem,
    isEditorChanged,
    'module-modal',
    templateStyles,
    setRightPanelElem,
    onChange,
  );

  const templateIframe = document.getElementById('template') as HTMLElementWithDocument;
  // const moduleModalIframe = document.getElementById('module-modal') as HTMLElementWithDocument;
  const templateHeight = templateIframe?.getBoundingClientRect().height;
  const { setDVItems, setModuleDataVisualizationStation } = useContext(TreatmentBuilderContext);
  const iframeRef = useRef(null);

  const { viewportSize } = useContext(ViewportSizeContext);

  const { chosenElemId } = useContext(TreatmentBuilderContext);

  // update the width of the module modal
  useEffect(() => {
    if (iframeRef.current) {
      const moduleContent = document.querySelector('#module-content') as HTMLDivElement;
      const frame = iframeRef.current as HTMLIFrameElement;
      const curModuleInTemplate = frame?.contentDocument?.getElementById(chosenElemId);

      if (viewportSize === ViewportSize.Mobile) {
        const moduleWidth = addWidths(
          [ViewportWidth.Mobile, HIGHLIGHTED_ELEM_BORDER_WIDTH, HIGHLIGHTED_ELEM_BORDER_WIDTH],
          'px',
        );
        moduleContent.style.minWidth = moduleWidth;
        moduleContent.style.width = moduleWidth;
      } else {
        const moduleWidth = addWidths(
          [ViewportWidth.Desktop, HIGHLIGHTED_ELEM_BORDER_WIDTH, HIGHLIGHTED_ELEM_BORDER_WIDTH],
          'px',
        );
        moduleContent.style.minWidth = moduleWidth; // `${curModuleInTemplate?.getBoundingClientRect().width ?? 0}px`;
        moduleContent.style.width = moduleWidth; // `${curModuleInTemplate?.getBoundingClientRect().width ?? 0}px`;
      }

      // moduleContent.style.height = `${curModuleInTemplate?.getBoundingClientRect().height}px`;
    }
  }, [chosenElemId, viewportSize]);

  // update the width of the parent of the iframe
  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current as HTMLIFrameElement;

      const parent = iframe.parentNode as HTMLElement;

      if (viewportSize === ViewportSize.Mobile) {
        parent.style.width = ViewportWidth.Mobile;
      } else {
        parent.style.width = ViewportWidth.Desktop;
      }

      if (iframe.contentDocument?.body) {
        iframe.style.height = `${iframe.contentDocument.body.getBoundingClientRect().height}px`;
      }
    }
  }, [viewportSize]);

  // embed the template styles
  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current as HTMLIFrameElement;
      const iframeHead: HTMLHeadElement | undefined = iframe.contentDocument?.head;

      if (templateStyles && iframeHead) {
        // clone and append nodes to maintain the styles in the iframe
        Array.from(templateStyles).forEach((style: HTMLStyleElement) => {
          iframeHead.appendChild(style.cloneNode(true));
        });
      }

      // iframe.contentDocument.documentElement.getElementsByTagName('head')[0].appendChild(templateStyles);
    }
  }, [templateStyles, iframeRef]);

  useEffect(() => {
    return () => {
      setModuleDataVisualizationStation({});
      setDVItems([]);
      setIsEditorChanged(false);
      dispatch(modules.actions.resetModuleInfo());
    };
  }, [setModuleDataVisualizationStation, setDVItems, dispatch, setIsEditorChanged]);

  return (
    <Box
      style={{
        paddingTop: `calc(24.7rem + ${modalTopPosition - MODULE_MODAL_Y_OFFSET_CORREC_NUM}px)`,
        height: templateHeight ? `${templateHeight + 50}px` : 'calc(150vh + 24.2rem)',
        backgroundColor: isTemplateEditor ? variables.color.primary.backgroundLighterGray : 'rgba(246,247,249, 0.5)',
        //backgroundColor: isTemplateEditor ? variables.color.primary.backgroundLighterGray : '#f6f7f9',
      }}
      className={styles.moduleModalWrapper}
    >
      {/* T: {templateHeight} mtp: {modalTopPosition} */}
      <Box id="module-content" className={styles.moduleContent}>
        <iframe
          ref={iframeRef}
          id="module-modal"
          className={styles.iframe}
          srcDoc={redefinedDoc}
          title="module-modal"
        />
        <IconButton id="module-modal-close" className={styles.closeBtn} onClick={onClose}>
          <Icon.CloseOutline stroke={variables.color.primary.white} />
        </IconButton>
        {!isTemplateEditor && (
          <IconButton className={styles.removeBtn} onClick={onRemove}>
            <Icon.TrashOutline color={variables.color.primary.white} fill={variables.color.primary.white} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
