import { BaseSyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Box } from '@material-ui/core';

import {
  ContainerType,
  DivContainerState,
  EmptyPlaceholder,
  Icon,
  ImageContainerState,
  UniversalContainerState,
  Wysiwyg,
} from '@components';
import {
  DataVisualizationListItemSchema,
  deployment,
  experience,
  global,
  ExperienceDeployment,
  InfoEmailTemplatePayload,
  ModuleDisclosureStation,
  ExperienceItemMindset,
} from '@modules';
import { useAppDispatch } from '@store';
import { useHistory, parse, textTemplate, network } from '@utils';
import { Section, TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { variables } from '@styles';

import { LibrariesPanelWrapper } from './LibrariesPanel/LibrariesPanelWrapper';
import { ModuleLibrary } from './ModuleLibrary';
import {
  SampleTemplate,
  DeploymentSettings,
  Treatments,
  RightPanelLayout,
  ModuleSettings,
  EMPTY_MODULE_NOTE,
} from './SampleTemplate';
import { TreatmentPanel } from './TreatmentPanel';

import { HTMLElementWithDocument } from './SampleTemplate/SampleTemplate.types';
import { getUniqueStyles } from './SampleTemplate/SampleTemplate.utils';

import {
  ChosenImage,
  DefaultChosenTreat,
  DeletedExperience,
  ElemInputActiveType,
  HTMLElementWithOriginalInnerHTML,
  SingleModulesWrapper,
  SplitterData,
  TemplateViewProps,
  TreatmentPanelType,
} from './TemplateView.types';
import { useStyles } from './TemplateView.styles';
import {
  DEFAULT_CHOSEN_DV,
  DEFAULT_CHOSEN_IMAGE,
  DEFAULT_DELETED_EXPERIENCE,
  DEFAULT_IMAGE_WRAPPER_HEIGHT,
  DEFAULT_IMAGE_WRAPPER_WIDTH,
  DEPLOYMENTS_MINDSET_DND_ID,
  Libraries,
  RightPanelElems,
  TreatmentBuilderContext,
} from './TemplateView.const';
import {
  convertWysiwygStylesToString,
  cotvertFromCamelToHyphenCase,
  getLibrariesWrapperTitle,
  getLibraryPanelComponent,
  getUniversalInitialValues,
  getRedefinedWysiwygStyles,
  transformStylesStringToObject,
  getSentenceCasedText,
  getDivInitialValues,
  getBackgroundImageStyles,
} from './TemplateView.utils';
import { content } from '@content';
import { HTMLEditor } from '@components/Wysiwyg/HTMLEditor';
import { TemplateList } from '@routes/Deployments/TemplateList';
import { TDVItem } from '@components/Wysiwyg/DVPanel/types';
import { TreatmentCardProps } from './SampleTemplate/RightPanel/Treatments/TreatmentCard';

/**
 Viewing Deployment -
 TemplateView route component
 @returns {JSX.Element}
 */

export const TemplateView = ({
  templateId,
  templates,
  selectedTemplate,
  onTemplateSelect,
  onTemplateUpload,
}: TemplateViewProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    enterprise,
    businessUnit: businessUnitId = 0,
    deployment: depId,
    program,
    mailFile,
    template,
  } = history.query;

  const {
    status: templateLoadingStatus,
    data: { deploymentEmailTemplates },
  } = deployment.useInfoEmailTemplate();

  const [chosenElemId, setChosenElemId] = useState('');
  const [chosenModuleElemId, setChosenModuleElemId] = useState('');
  const [usedExperiences, setUsedExperiences] = useState<number[]>([]);
  const [showLibraryPanel, setShowLibraryPanel] = useState(false);
  const [chosenLibrary, setChosenLibrary] = useState<Libraries | null>(Libraries.IMAGES);
  const [rightPanelElem, setRightPanelElem] = useState<RightPanelElems>(RightPanelElems.DEPLOYMENT_SETTINGS);
  const [editableTreatmentId, setEditableTreatmentId] = useState<number | null>(null);
  const [showAddTreatmentPanel, setShowAddTreatmentPanel] = useState(false);
  const [showModuleEditor, setShowModuleEditor] = useState(false);
  const [deletedExperience, setDeletedExperience] = useState<DeletedExperience>(DEFAULT_DELETED_EXPERIENCE);
  const [chosenModuleInnerHtml, setChosenModuleInnerHtml] = useState('');
  const [moduleIndex, setModuleIndex] = useState<number>(0);
  const [defaultChosenTreats, setDefaultChosenTreats] = useState<DefaultChosenTreat[]>([]);
  const [chosenElemStyles, setChosenElemStyles] = useState('');
  const [wysiwygContainerType, setWysiwygContainerType] = useState<ContainerType>('text');
  const [chosenImage, setChosenImage] = useState<ChosenImage>(DEFAULT_CHOSEN_IMAGE);
  const [chosenDV, setChosenDV] = useState<DataVisualizationListItemSchema>();
  const [chosenExperienceId, setChosenExperienceId] = useState<number | null>(null);
  const [singleModulesWrapper, setSingleModulesWrapper] = useState<SingleModulesWrapper>(null);
  const [isTreatmentTouched, setIsTreatmentTouched] = useState(false);
  const [chosenModuleId, setChosenModuleId] = useState<number | null>(null);
  const [currentExperienceId, setCurrentExperienceId] = useState<number | null>(null);
  const [isModuleChanged, setIsModuleChanged] = useState(false);
  const [newExperienceCloned, setNewExperienceCloned] = useState<SplitterData>({
    cloned: false,
    splitterId: null,
    experienceId: null,
  });
  const [deploymentsInfo, setDeploymentsInfo] = useState<ExperienceDeployment[]>([]);
  const deploymentData = deployment.useDeploymentData();
  const isMailFileUploading = useMemo(() => deploymentData?.isMailFileUploading ?? false, [deploymentData]);
  const [disclosure, setDisclosure] = useState<ModuleDisclosureStation | null>(null);
  const [experiencesIds, setExperiencesIds] = useState<number[]>([]);
  const [treatmentPanelType, setTreatmentPanelType] = useState<TreatmentPanelType>('logicBuilder');
  const [isTargetContentShown, setIsTargetContentShown] = useState(false);
  const [moduleHtml, setModuleHtml] = useState<string>('');
  const [elemInputActive, setElemInputActive] = useState<ElemInputActiveType>('disabled');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [editMode, setEditMode] = useState('no-code');
  const [showRemoveExperienceModal, setShowRemoveExperienceModal] = useState(false);
  const [showRemoveTreatmentModal, setShowRemoveTreatmentModal] = useState(false);
  const [showRemoveWithRevisions, setShowRemoveWithRevisions] = useState(false);
  const [lastEmailTemplateId, setLastEmailTemplateId] = useState<number>();
  const [isEditorChanged, setIsEditorChanged] = useState(false);
  const [moduleDataVisualizationStation, setModuleDataVisualizationStation] = useState<any>({});
  const [DVItems, setDVItems] = useState<TDVItem[]>([]);
  const [showSelectTemplate, setShowSelectTemplate] = useState(false);

  // Determine whether the select template block should appear
  useEffect(() => {
    const hasSelectedTemplate = deploymentEmailTemplates?.some((item) => item.isSelected);

    switch (templateLoadingStatus) {
      case 'loading':
        setShowSelectTemplate(false);
        break;
      case 'success':
        setShowSelectTemplate(!hasSelectedTemplate);
        break;
      case 'error':
        setShowSelectTemplate(false);
        break;
      default:
        setShowSelectTemplate(false);
        break;
    }
  }, [deploymentEmailTemplates, templateLoadingStatus]);

  const universalInitialValues = useMemo(
    () => getUniversalInitialValues(chosenModuleElemId, transformStylesStringToObject(chosenElemStyles)),
    // [isModuleChanged, chosenModuleElemId],
    [chosenElemStyles, chosenModuleElemId],
  );

  const sidebarRef = useRef<HTMLDivElement>(null);
  const contextValues = {
    chosenElemId,
    chosenModuleElemId,
    chosenExperienceId,
    experiencesIds,
    editableTreatmentId,
    showAddTreatmentPanel,
    showModuleEditor,
    chosenModuleInnerHtml,
    moduleIndex,
    defaultChosenTreats,
    chosenElemStyles,
    wysiwygContainerType,
    chosenLibrary,
    singleModulesWrapper,
    isTreatmentTouched,
    chosenModuleId,
    currentExperienceId,
    newExperienceCloned,
    deploymentsInfo,
    isModuleChanged,
    disclosure,
    isTargetContentShown,
    elemInputActive,
    cursorPosition,
    editMode,
    showRemoveExperienceModal,
    showRemoveTreatmentModal,
    showRemoveWithRevisions,
    lastEmailTemplateId,
    moduleDataVisualizationStation,
    DVItems,
    setDVItems,
    setModuleDataVisualizationStation,
    setLastEmailTemplateId,
    setShowRemoveWithRevisions,
    setShowRemoveTreatmentModal,
    setShowRemoveExperienceModal,
    setEditMode,
    setCursorPosition,
    setDisclosure,
    setDeploymentsInfo,
    setExperiencesIds,
    setNewExperienceCloned,
    setCurrentExperienceId,
    setChosenModuleId,
    setIsTreatmentTouched,
    setSingleModulesWrapper,
    setChosenElemId,
    setChosenModuleElemId,
    setChosenExperienceId,
    setEditableTreatmentId,
    setShowAddTreatmentPanel,
    setShowModuleEditor,
    setChosenModuleInnerHtml,
    setModuleIndex,
    setDefaultChosenTreats,
    setChosenElemStyles,
    setWysiwygContainerType,
    setChosenLibrary,
    setIsModuleChanged,
    setIsTargetContentShown,
    setElemInputActive,
  };
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      const { reason, type, source, destination } = result;

      // reorder mindsets
      if (
        reason === 'DROP' &&
        type === DEPLOYMENTS_MINDSET_DND_ID &&
        source.droppableId === DEPLOYMENTS_MINDSET_DND_ID &&
        destination?.droppableId === DEPLOYMENTS_MINDSET_DND_ID
      ) {
        await dispatch(experience.actions.moveMindset({ from: source.index, to: destination.index }));

        setIsTreatmentTouched(true);
      }
    },
    [dispatch],
  );

  //Displaying the currently selected template if the template id in the query was changed
  useEffect(() => {
    (async () => {
      if (depId && deploymentData?.id) {
        // const result = await dispatch(deployment.thunk.getInfoEmailTemplate(depId));

        // if (deployment.thunk.getInfoEmailTemplate.fulfilled.match(result) && result.payload) {
        const response = await network.get<InfoEmailTemplatePayload>({
          key: 'deployment_info_email_template',
          params: { deployment: depId },
        });
        const selectedTmplId = response?.deploymentEmailTemplates?.find((item) => item.isSelected)?.id;

        if (selectedTmplId && template !== selectedTmplId) {
          history.push('deployments', {
            enterprise,
            businessUnit: businessUnitId,
            deployment: depId,
            program,
            mailFile: deploymentData.mailFile?.id,
            template: selectedTmplId,
          });

          setTopBarCtx((prev) => ({
            ...prev,
            isTemplateListVisible: false,
          }));
        }
        // }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enterprise,
    businessUnitId,
    depId,
    program,
    mailFile,
    template,
    deploymentEmailTemplates,
    deploymentData,
    dispatch,
  ]);

  //removing items mindsets on template view loading
  useEffect(() => {
    dispatch(experience.actions.clearItemsMindsets());
  }, [dispatch, templateId]);

  // function for dragging Experience to the Template
  const handleDragExperience = (e: BaseSyntheticEvent) => {
    const experienceId = parse.integer(e.currentTarget.getAttribute('data-id'));
    const nativeEvent = e.nativeEvent as DragEvent;
    nativeEvent.dataTransfer?.setData('application/json', JSON.stringify({ cursor: 0, experienceId }));
  };

  // function for dragging Single Module to the Template
  const handleModuleDrag = useCallback(
    (e: BaseSyntheticEvent) => {
      const moduleId = parse.integer(e.currentTarget.getAttribute('data-module-id'));
      const nativeEvent = e.nativeEvent as DragEvent;
      nativeEvent.dataTransfer?.setData(
        'application/json',
        JSON.stringify({ cursor: 0, moduleId, chosenModuleId, experienceId: chosenExperienceId }),
      );
    },
    [chosenExperienceId, chosenModuleId],
  );

  // getting Template by the deployment ID
  useEffect(() => {
    const deploymentGetById = async () => {
      if (depId) {
        await dispatch(deployment.thunk.getById(depId));
      }
    };

    if (depId) {
      deploymentGetById();
      // dispatch(deployment.thunk.getById(depId));
    }
  }, [depId, templateId, dispatch]);

  const handleSSEMailFileLoadingConnect = useCallback(() => {
    const SSEUrl = process.env.REACT_APP_SSE_MAIL_FILE_LOADING?.replace('%d', mailFile?.toString() ?? '') ?? '';

    const mailFileLoadingChannel = new EventSource(SSEUrl);

    mailFileLoadingChannel.onmessage = (e: any) => {
      try {
        const data = JSON.parse(e.data);

        if (data.status === 'done') {
          dispatch(deployment.actions.changeDataMailLoadingStatus(false));
        }

        if (data.message === 'error') {
          dispatch(
            global.actions.enqueueNotification({
              message: content.SomethingWentWrongTryAgain,
              options: { variant: 'error' },
            }),
          );
        }

        mailFileLoadingChannel.close();
      } catch (err) {
        console.log(err);
      }
    };
  }, [mailFile, dispatch]);

  useEffect(() => {
    if (isMailFileUploading && depId && mailFile) {
      handleSSEMailFileLoadingConnect();
    }
  }, [mailFile, isMailFileUploading, depId, handleSSEMailFileLoadingConnect]);

  const handleChooseLibrary = useCallback(
    (libraryName: Libraries | null) => {
      setChosenLibrary(libraryName);
    },
    [setChosenLibrary],
  );

  const handleUniversalStyleChange = useCallback(
    (state: UniversalContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const changingElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      setChosenLibrary(Libraries.DATA_ATTRIBUTES);

      const redefinedWysiwygStyles = getRedefinedWysiwygStyles(state);

      redefinedWysiwygStyles.backgroundColor = redefinedWysiwygStyles.backgroundColor.toLowerCase();
      redefinedWysiwygStyles.color = redefinedWysiwygStyles.color.toLowerCase();

      const hyphenCasedStylesString = cotvertFromCamelToHyphenCase(
        convertWysiwygStylesToString(redefinedWysiwygStyles),
      );
      const uniqueStyles = getUniqueStyles(
        `${chosenElemStyles}; ${hyphenCasedStylesString} \nborder-color: ${variables.color.primary.mainPurple};`,
      );

      if (changingElem) {
        changingElem.setAttribute('style', uniqueStyles);

        if (redefinedWysiwygStyles.textTransform === 'sentence') {
          changingElem.innerHTML = getSentenceCasedText(changingElem?.innerHTML.trim());
        }
        if (redefinedWysiwygStyles.textTransform === 'unset') {
          changingElem.innerHTML = changingElem.getAttribute('originalInnerHTML') || changingElem.innerHTML;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenModuleElemId],
  );

  const handleImageStyleChange = useCallback(
    (state: ImageContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const imageElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (chosenModuleElemId !== state?.chosenModuleElemId) {
        return;
      }

      const filteredStyles = chosenElemStyles
        .split(';')
        .map((item) => item.replace('\n', ''))
        .excludeStrs(['width:', 'height:', 'background-color:', 'padding'])
        .join(';\n');

      if (imageElem && ([Libraries.IMAGES, Libraries.DATA_VISUAL].includes(chosenLibrary!) || !chosenLibrary)) {
        imageElem.setAttribute('src', state?.imageSrc || '');
        imageElem.setAttribute('style', filteredStyles);
      }

      if (imageElem) {
        imageElem.setAttribute('width', state?.width || '');
        imageElem.setAttribute('height', state?.height || '');
      }
    },
    [chosenElemStyles, chosenLibrary, chosenModuleElemId],
  );

  const handleLinkChange = useCallback(
    (linkValue: string) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const curElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (curElem.parentElement?.tagName !== 'A') {
        const curElemCopy = curElem.cloneNode() as HTMLElementWithOriginalInnerHTML;
        const linkElem = document.createElement('a');

        curElemCopy.innerHTML = curElem.innerHTML;
        linkElem.style.textDecoration = 'none';
        linkElem.setAttribute('temphref', linkValue);
        linkElem.appendChild(curElemCopy);
        curElem.replaceWith(linkElem);

        return;
      }

      curElem.parentElement.setAttribute('temphref', linkValue);
      curElem.parentElement.style.textDecoration = 'none';
    },
    [chosenModuleElemId],
  );

  const handleAltTextChange = useCallback(
    (altTextValue: string) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const curElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (curElem.parentElement?.tagName !== 'A') {
        const curElemCopy = curElem.cloneNode() as HTMLElementWithOriginalInnerHTML;
        const linkElem = document.createElement('a');

        curElemCopy.innerHTML = curElem.innerHTML;
        linkElem.style.textDecoration = 'none';
        linkElem.setAttribute('title', altTextValue);
        linkElem.appendChild(curElemCopy);
        curElem.replaceWith(linkElem);

        return;
      }

      curElem.parentElement.setAttribute('title', altTextValue);
      curElem.parentElement.style.textDecoration = 'none';
    },
    [chosenModuleElemId],
  );

  const handleDVStyleChange = useCallback(
    (state: { width: number; height: number }) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const imageElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (imageElem) {
        imageElem.setAttribute('width', state?.width.toString() || '');
        imageElem.setAttribute('height', state?.height.toString() || '');
      }
    },
    [chosenModuleElemId],
  );

  const handleDivStyleChange = useCallback(
    (state: DivContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const divElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;
      const divElemStyles = divElem?.getAttribute('style');
      const divOffsetWidth = divElem?.offsetWidth;
      const divOffsetHeight = divElem?.offsetHeight;

      divElem?.setAttribute(
        'style',
        getUniqueStyles(
          getUniqueStyles(`${divElemStyles} ${getBackgroundImageStyles(state, divOffsetWidth, divOffsetHeight)}`),
        ),
      );
    },
    [chosenModuleElemId],
  );

  const handleChooseImage = useCallback(
    (image: any) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const changingElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (image.thumbnailPath) {
        const ratio = image.width / image.height;
        const height = (250 / ratio).toString();

        const payload = {
          chosenModuleElemId: chosenModuleElemId,
          height,
          imageSrc: image.thumbnailPath,
          name: image.name,
          width: '250',
        };

        setChosenDV(image);
        setRightPanelElem(RightPanelElems.WYSIWYG_DATA_VISUAL);
        setChosenImage(payload as any);

        if (chosenModuleElemId.indexOf('img') !== -1) {
          handleImageStyleChange(payload);
        } else {
          handleDivStyleChange(payload);
        }

        changingElem.setAttribute('data-visualization-station', 'true');
        changingElem.setAttribute('dataVisualId', image.id);
        changingElem.setAttribute('dataVisualEmbdedCode', image.embedCode);
        changingElem.setAttribute('isMaxScaleEnabled', image.isMaxScaleEnabled);
      } else {
        setChosenImage(image);

        if (chosenModuleElemId.indexOf('img') !== -1) {
          handleImageStyleChange(image);
          setRightPanelElem(RightPanelElems.WYSIWYG_IMAGE);
        } else {
          handleDivStyleChange(image);
        }

        changingElem.removeAttribute('data-visualization-station');
        changingElem.removeAttribute('dataVisualId');
        changingElem.removeAttribute('dataVisualEmbdedCode');
        changingElem.removeAttribute('isMaxScaleEnabled');
        setModuleDataVisualizationStation(null);
      }
      setIsModuleChanged(true);
    },
    [
      chosenModuleElemId,
      handleDivStyleChange,
      handleImageStyleChange,
      setChosenImage,
      setChosenDV,
      setModuleDataVisualizationStation,
    ],
  );

  const onEditorChange = (value: string) => {
    setIsModuleChanged(true);
    setModuleHtml(value);
    setIsEditorChanged(true);
  };

  const handleTreatmentChange = useCallback<TreatmentCardProps['setChosenTreatmentId']>(
    (nextTreatmentId) => {
      if (nextTreatmentId !== chosenModuleId) {
        setChosenModuleId(nextTreatmentId);
      }
    },
    [setChosenModuleId, chosenModuleId],
  );

  const handleDimensionsChange = useCallback(
    ([width, height]: [number, number]) => {
      const payload = {
        height,
        width,
      };

      handleDVStyleChange(payload);
    },
    [handleDVStyleChange],
  );

  const selectTreatment = useCallback(
    (nextTreatment: ExperienceItemMindset) => {
      handleTreatmentChange(nextTreatment.id);

      setDefaultChosenTreats((prevState: any) => {
        const chosenTreatment = {
          id: nextTreatment.id,
          experienceId: chosenExperienceId,
        };

        return [...prevState.filter((item: any) => item.experienceId !== chosenExperienceId), chosenTreatment];
      });

      setChosenModuleInnerHtml(nextTreatment.moduleHtml || EMPTY_MODULE_NOTE);
    },
    [chosenExperienceId, setChosenModuleInnerHtml, handleTreatmentChange, setDefaultChosenTreats],
  );

  // set topBarCtx.contentModuleName to latest module name of chosenTreatment if they are not equal
  // and the right panel is not Deployment Settings or Treatments
  useEffect(() => {
    if (
      ![RightPanelElems.DEPLOYMENT_SETTINGS, RightPanelElems.TREATMENTS].includes(rightPanelElem) &&
      topBarCtx.contentModuleName !== topBarCtx.chosenTreatment?.moduleTemplateName
    ) {
      const contentModuleName = topBarCtx.chosenTreatment?.moduleTemplateName;
      setTopBarCtx((prev) => ({
        ...prev,
        contentModuleName,
        //contentModuleName: topBarCtx.chosenTreatment?.moduleTemplateName,
      }));
    }
  }, [rightPanelElem, setTopBarCtx, topBarCtx.chosenTreatment?.moduleTemplateName, topBarCtx.contentModuleName]);

  const getRightPanelElem = useCallback(
    () => {
      /*
      if (
        ![RightPanelElems.DEPLOYMENT_SETTINGS, RightPanelElems.TREATMENTS].includes(rightPanelElem) &&
        topBarCtx.contentModuleName !== topBarCtx.chosenTreatment?.moduleTemplateName
      ) {
        const contentModuleName = topBarCtx.chosenTreatment?.moduleTemplateName;
        setTopBarCtx((prev) => ({
          ...prev,
          contentModuleName,
          //contentModuleName: topBarCtx.chosenTreatment?.moduleTemplateName,
        }));
      }
        */

      if (editMode === 'html') {
        return (
          <HTMLEditor
            moduleHtml={moduleHtml}
            moduleName={topBarCtx.contentModuleName ?? ''}
            onEditorChange={onEditorChange}
          />
        );
      }

      switch (rightPanelElem) {
        case RightPanelElems.DEPLOYMENT_SETTINGS:
          if (topBarCtx.contentModuleName !== '') {
            setTopBarCtx((prev) => ({
              ...prev,
              contentModuleName: '',
            }));
          }

          return <DeploymentSettings />;
        case RightPanelElems.TREATMENTS:
          return (
            <Treatments
              setTreatmentPanelType={setTreatmentPanelType}
              templateId={templateId}
              setRightPanelElem={setRightPanelElem}
              setDeletedExperience={setDeletedExperience}
            />
          );
        case RightPanelElems.WYSIWYG_UNIVERSAL:
          return (
            <Wysiwyg.UniversalContainer
              isModuleChanged={isModuleChanged}
              setIsModuleChanged={setIsModuleChanged}
              initialValues={universalInitialValues}
              containerType={wysiwygContainerType}
              onChange={handleUniversalStyleChange}
              onLinkChange={handleLinkChange}
              onAltTextChange={handleAltTextChange}
            />
          );
        case RightPanelElems.WYSIWYG_IMAGE:
          return (
            <Wysiwyg.ImageContainer
              isModuleChanged={isModuleChanged}
              setIsModuleChanged={setIsModuleChanged}
              setChosenLibrary={setChosenLibrary}
              chosenImage={chosenImage}
              onChange={handleImageStyleChange}
              onLinkChange={handleLinkChange}
              onAltTextChange={handleAltTextChange}
            />
          );
        case RightPanelElems.WYSIWYG_DATA_VISUAL:
          return (
            <Wysiwyg.DVPanel
              chosenDV={chosenDV!}
              isModuleChanged={isModuleChanged}
              setIsModuleChanged={setIsModuleChanged}
              setChosenLibrary={setChosenLibrary}
              onImageChange={handleImageStyleChange}
              onDimensionsChange={handleDimensionsChange}
            />
          );
        case RightPanelElems.WYSIWYG_DIV:
          return (
            <Wysiwyg.DivContainer
              isModuleChanged={isModuleChanged}
              setIsModuleChanged={setIsModuleChanged}
              initialValues={getDivInitialValues(transformStylesStringToObject(chosenElemStyles))}
              chosenImage={chosenImage}
              onChange={handleDivStyleChange}
              setChosenLibrary={setChosenLibrary}
            />
          );
        case RightPanelElems.MODULE_SETTINGS:
          return (
            <ModuleSettings
              onEditorChange={onEditorChange}
              module={{ id: chosenModuleId, moduleHtml, name: topBarCtx.contentModuleName }}
            />
          );
      }
    },
    [
      chosenDV,
      chosenElemStyles,
      chosenImage,
      chosenModuleId,
      editMode,
      handleAltTextChange,
      handleDimensionsChange,
      handleDivStyleChange,
      handleImageStyleChange,
      handleLinkChange,
      handleUniversalStyleChange,
      isModuleChanged,
      moduleHtml,
      rightPanelElem,
      setTopBarCtx,
      templateId,
      topBarCtx.contentModuleName,
      universalInitialValues,
      wysiwygContainerType,
    ],
    /* [
    chosenElemStyles,
    chosenImage,
    chosenModuleElemId,
    handleDivStyleChange,
    handleImageStyleChange,
    handleUniversalStyleChange,
    isModuleChanged,
    rightPanelElem,
    templateId,
    editMode,
    wysiwygContainerType,
    topBarCtx.contentModuleName,
    topBarCtx.chosenTreatment,
  ] */
  );

  const handleTreatmentPanelClose = () => {
    setShowAddTreatmentPanel(false);
    setIsTargetContentShown(true);
  };

  const handleModuleHtmlChange = useCallback(
    (curModuleHtml: string, clearModuleHtml: string) => {
      if (
        [RightPanelElems.MODULE_SETTINGS, RightPanelElems.TREATMENTS].includes(rightPanelElem) &&
        moduleHtml !== clearModuleHtml &&
        clearModuleHtml !== '<body></body>' &&
        moduleHtml !== curModuleHtml
      ) {
        setModuleHtml(clearModuleHtml);
      }
    },
    [moduleHtml, rightPanelElem],
  );

  useEffect(() => {
    const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
    const imageElem = moduleElem?.contentDocument.getElementById(
      chosenModuleElemId,
    ) as HTMLElementWithOriginalInnerHTML;

    if (imageElem && chosenModuleElemId.indexOf('img') !== -1) {
      setTimeout(() => {
        const imageSrc = imageElem.getAttribute('src');
        const width = Math.round(imageElem.getBoundingClientRect().width - 4);
        const height = Math.round(imageElem.getBoundingClientRect().height - 4);
        const dataVisualId = Number(imageElem.getAttribute('dataVisualId'));
        const dataVisualEmbedCode = imageElem.getAttribute('dataVisualEmbdedCode');
        const isMaxScaleEnabled = imageElem.getAttribute('isMaxScaleEnabled') === 'false' ? false : true;
        const linkParent = imageElem.parentElement?.tagName === 'A' ? imageElem.parentElement : null;
        const linkValue = linkParent?.getAttribute('href') ?? linkParent?.getAttribute('temphref') ?? '';
        const altTextValue = linkParent?.getAttribute('title') ?? '';

        if (chosenDV?.id !== dataVisualId) {
          setChosenDV({
            id: dataVisualId ?? 0,
            embedCode: dataVisualEmbedCode,
            isMaxScaleEnabled,
            width,
            imageSrc: '',
            height,
          } as any);
        }

        setChosenImage({
          id: null,
          chosenModuleElemId,
          imageSrc,
          width: width ? +width : DEFAULT_IMAGE_WRAPPER_WIDTH,
          height: height ? +height : DEFAULT_IMAGE_WRAPPER_HEIGHT,
          name: null,
          link: linkValue,
          altText: altTextValue,
        });
      }, 150);
    } else {
      setChosenImage(DEFAULT_CHOSEN_IMAGE);
    }
  }, [chosenModuleElemId, chosenDV]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'section',
      sectionName: textTemplate(content.entityManager, { entity: content.deployment }),
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  return (
    <Box className={styles.templateView}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <TreatmentBuilderContext.Provider value={contextValues}>
          <Section className={styles.leftPanel} range={2} gap={0}>
            {!topBarCtx.isTemplateListVisible ? (
              <>
                <ModuleLibrary
                  ref={sidebarRef}
                  businessUnitId={businessUnitId}
                  onModuleDrag={handleModuleDrag}
                  onModulesDrag={handleDragExperience}
                  usedExperiences={usedExperiences}
                />
                {showLibraryPanel && (
                  <LibrariesPanelWrapper onChooseLibrary={handleChooseLibrary}>
                    {getLibraryPanelComponent(chosenLibrary, false, handleChooseImage, undefined, setChosenImage)}
                  </LibrariesPanelWrapper>
                )}
              </>
            ) : (
              <>
                <TemplateList
                  items={templates}
                  selected={selectedTemplate}
                  showSelectTemplate={showSelectTemplate}
                  onSelect={onTemplateSelect}
                  onUpload={onTemplateUpload}
                />
              </>
            )}
          </Section>
          <Section className={styles.centralPanel} range={6} gap={0}>
            {template && (
              <SampleTemplate
                templateId={templateId}
                sidebarRef={sidebarRef}
                deletedExperience={deletedExperience}
                changedModuleHtml={moduleHtml}
                isEditorChanged={isEditorChanged}
                setIsEditorChanged={setIsEditorChanged}
                setChangedModuleHtml={setModuleHtml}
                setDeletedExperience={setDeletedExperience}
                setUsedExperiences={setUsedExperiences}
                setShowLibraryPanel={setShowLibraryPanel}
                setRightPanelElem={setRightPanelElem}
                onModuleHtmlChange={handleModuleHtmlChange}
              />
            )}
            {showSelectTemplate && (
              <Box className={styles.deploymentsInfo}>
                <EmptyPlaceholder
                  title={content.selectATemplate}
                  icon={<Icon.Add fill={variables.color.primary.darkestGray} />}
                />
              </Box>
            )}
          </Section>
          {showAddTreatmentPanel && (
            <Section className={styles.treatmentLogic} range={6} gap={0}>
              <TreatmentPanel
                treatmentPanelType={treatmentPanelType}
                selectTreatment={selectTreatment}
                onClose={handleTreatmentPanelClose}
              />
            </Section>
          )}
          <Section className={styles.rightPanel} range={4} gap={0}>
            <RightPanelLayout>{getRightPanelElem()}</RightPanelLayout>
          </Section>
        </TreatmentBuilderContext.Provider>
      </DragDropContext>
    </Box>
  );
};
