import { Box, Tooltip } from '@material-ui/core';
import { memo, useCallback, useContext } from 'react';

import { Icon } from '@components';
import { content } from '@content';
import { format } from '@utils';

import { SignalBuilderContext } from '../SignalBuilder.const';

import { AttributeProps } from './Attribute.props';
import { useStyles, useTooltipStyles } from './Attribute.styles';
import { getStyle } from './Attribute.utils';

/**
 Attribute route component.
 @returns {JSX.Element}
 */

export const Attribute = memo(
  ({
    className,
    provided,
    item,
    style,
    isClickable,
    isCloudShown = false,
    isDragging,
    editMode,
    onClick,
    onDblClick,
  }: AttributeProps): JSX.Element => {
    const styles = useStyles();
    const tooltipStyles = useTooltipStyles();

    const { chosenAttributeId, onDoubleClick } = useContext(SignalBuilderContext);

    const getTooltipTitle = useCallback(() => {
      if (item.error) {
        return <span className={styles.errorText}>{content.error}</span>;
      }

      if (item.isUploading) {
        return <span className={styles.errorText}>{content.inProgress}</span>;
      }

      if (item.updatedAt) {
        if (item.signalType === 'foreign') {
          return format.date(item.updatedAt, 'MM/dd/yy');
        } else {
          return `${format.date(item.updatedAt, 'MM/dd/yy')} | ${item.type} | ${format.number(item.totalSubscribers)}`;
        }
      }
      return '';
    }, [
      item.error,
      item.isUploading,
      item.signalType,
      item.totalSubscribers,
      item.type,
      item.updatedAt,
      styles.errorText,
    ]);

    const handleSignalClick = () => {
      if (onClick) {
        onClick(item);
      }
    };

    return (
      <Tooltip title={getTooltipTitle()} placement="bottom" arrow classes={tooltipStyles}>
        <Box
          /* eslint-disable react/jsx-props-no-spreading */
          {...(provided ?? {}).draggableProps}
          {...(provided ?? {}).dragHandleProps}
          {...{ ref: (provided ?? {}).innerRef }}
          /* eslint-enable react/jsx-props-no-spreading */
          style={getStyle({ provided: provided ?? ({ draggableProps: { style: {} } } as any), style, isDragging })}
          className={`${className} ${styles.signalCapsule} signal-item`}
          data-chosen={item.signalType === 'composite' && item.id === chosenAttributeId}
          onDoubleClick={
            item.signalType === 'composite'
              ? async () => {
                  onDoubleClick(item.id);

                  if (onDblClick) {
                    onDblClick(item);
                  }
                }
              : async () => {
                  if (onDblClick) {
                    onDblClick(item);
                  }

                  return false;
                }
          }
          onClick={handleSignalClick}
        >
          <p className={styles.signalText}>
            {item.label}
            {((isCloudShown && !editMode) || !item.updatedAt) && (
              <Box className={styles.attributeIconContainer} component="span">
                <Icon.CloudOutline className={styles.attributeIcon} width={16} height={16} />
              </Box>
            )}
          </p>
        </Box>
      </Tooltip>
    );
  },
);

Attribute.displayName = 'Attribute';
