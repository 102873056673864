export const parseAndCleanHTML = (htmlString: string): string => {
  // Parse the string into an HTML document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Find all <personefy-token> elements
  const tokens = doc.querySelectorAll('personefy-token');

  tokens.forEach((token) => {
    // Remove the style attribute
    token.removeAttribute('style');
  });

  // Replace &amp; with & and &nbsp; with a space
  let cleanedHTML = doc.body.innerHTML.replaceAll('&amp;', '&').replaceAll('&nbsp;', ' ');

  // Trim extra whitespace
  cleanedHTML = cleanedHTML.trim();

  return cleanedHTML;
};
