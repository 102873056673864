import { useRef, useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { auth } from '@modules';
import { Form } from '@views';
import { useAppDispatch } from '@store';
import { useHistory, useLoader, useWebTitle, validate } from '@utils';

import { LOGIN_REDIRECTION_DELAY } from './PasswordReset.const';
import { useStyles } from './PasswordReset.styles';
import { CopyrightFooter } from '@components';

export const PasswordReset = (): JSX.Element => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  const meta = auth.usePwdResetConfirmMeta();

  const history = useHistory();

  useWebTitle(content.resetPassword);

  useLoader(meta);

  const redirectionHandle = useRef(0);

  const redirectToLogin = useCallback(() => {
    history.push('login');
  }, [history]);

  const stopRedirection = useCallback(() => {
    window.clearTimeout(redirectionHandle.current);
  }, []);

  const startRedirection = useCallback(() => {
    stopRedirection();
    redirectionHandle.current = window.setTimeout(redirectToLogin, LOGIN_REDIRECTION_DELAY);
  }, [stopRedirection, redirectToLogin]);

  useEffect(() => () => stopRedirection(), [stopRedirection]);

  const handleSubmit = useCallback(
    async ({ plainPassword }) => {
      const { token } = history.query;
      const emailToken = `${token || ''}`;

      if (emailToken) {
        const result = await dispatch(auth.thunk.resetPassword({ plainPassword, emailToken }));

        if (auth.thunk.resetPassword.fulfilled.match(result)) {
          startRedirection();
        }
      }
    },
    [dispatch, startRedirection, history],
  );

  const validatePwd = useCallback(
    (value, values) => validate.password(value) && values.plainPassword === values.copy,
    [],
  );

  return (
    <Box className={styles.pwdResetContainer}>
      <Box className={styles.pwdReset}>
        <Form
          className={styles.form}
          keepSucceedEnabled
          status={meta.status}
          requiredWarn={content.enterNewPasswordTwicePlease}
          invalidWarn={meta.error?.message || content.incorrectPassword}
          successMessage={content.yourPasswordWasSuccessfullyCreated}
          title={content.createYourNewPassword}
          fields={[
            {
              type: 'pwd',
              id: 'plainPassword',
              label: content.newPassword,
              required: true,
              validate: validatePwd,
              // placeholder: content.required,
            },
            {
              type: 'pwd',
              id: 'copy',
              label: content.verifyNewPassword,
              required: true,
              validate: validatePwd,
              // placeholder: content.required,
            },
          ]}
          buttonsClassName={styles.buttons}
          buttons={[{ isSubmit: true, label: content.resetPassword, color: 'primary' }]}
          onSubmit={handleSubmit}
        />
        <CopyrightFooter />
      </Box>
    </Box>
  );
};
