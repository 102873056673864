import { makeStyles, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() =>
  createStyles({
    openButton: {
      height: '2.4rem',
      marginLeft: '.5rem',
      minWidth: '2.4rem',
      padding: '0 0',
      width: '2.4rem',
    },
    menuList: {
      padding: '0 10px',
      marginLeft: '-10px',
      boxShadow: '1px 1px 7px #dadee2',
    },
    formatItem: {
      display: 'flex',
      minWidth: '240px',
      justifyContent: 'space-between',
    },
  }),
);
