import { useStyles } from './SLPInput.styles';
import { SLPInputProps } from './SLPInput.props';
import { getInnerHTMLLengthExcludingTokens } from './SLPInput.utils';
import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useRef } from 'react';
import Cursor from '@utils/cursor';
import DOMPurify from 'dompurify';

export function SLPInput({
  id,
  label,
  initialValue,
  isEditMode,
  onInput,
  onKeyDown,
  onDoubleClick,
  onCursorPositionChange,
  setValue,
}: SLPInputProps): JSX.Element {
  const styles = useStyles();

  const divElement = useRef<HTMLDivElement>(null);
  const lastHtml = useRef<string>('');

  // set the focus and cursor position when changing to edit mode
  useEffect(() => {
    if (isEditMode) {
      if (divElement.current) {
        const html = divElement.current.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `);

        const startPos = html.length > 0 ? html.length : 0;
        Cursor.setCursorPosition(divElement.current, startPos);
      }
    }
  }, [isEditMode]);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      if (isEditMode) {
        const cursorCurrentPosition = Cursor.getCursorPosition(event.currentTarget);

        if (divElement.current) {
          onCursorPositionChange(divElement.current, cursorCurrentPosition);
        }
      }
    },
    [isEditMode, onCursorPositionChange],
  );

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isEditMode) {
        const cursorCurrentPosition = Cursor.getCursorPosition(event.currentTarget);

        if (divElement.current) {
          onCursorPositionChange(divElement.current, cursorCurrentPosition);
        }
      }
    },
    [isEditMode, onCursorPositionChange],
  );

  const handleOnDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isEditMode) {
        onDoubleClick(event);
      } else {
        const cursorCurrentPosition = Cursor.getCursorPosition(event.currentTarget);

        if (divElement.current) {
          onCursorPositionChange(divElement.current, cursorCurrentPosition);
        }
      }
    },
    [isEditMode, onDoubleClick, onCursorPositionChange],
  );

  const handleOnInput = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      const position = Cursor.getCursorPosition(event.currentTarget);

      const sanitizedHtml = DOMPurify.sanitize(event.currentTarget.innerHTML, {
        ALLOWED_TAGS: ['personefy-token'],
        ALLOWED_ATTR: ['style', 'data-attribute-name', 'contenteditable', 'initialvalue'],
      });

      const curHtml = sanitizedHtml;
      if (curHtml !== lastHtml.current) {
        setValue(sanitizedHtml);
        onInput(event);

        setTimeout(() => {
          if (divElement.current) {
            Cursor.setCursorPosition(divElement.current, position);
          }
        }, 0);

        lastHtml.current = curHtml ?? '';
      }

      // divElement?.current?.focus();
    },
    [onInput, setValue],
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Allow Ctrl/Cmd + A, C, X keypresses
      if ((event.ctrlKey || event.metaKey) && (event.key === 'a' || event.key === 'c' || event.key === 'x')) {
        return;
      }

      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        return;
      }

      // prevent carriage returns
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      if (divElement.current) {
        const charCount = getInnerHTMLLengthExcludingTokens(divElement.current);

        if (charCount >= 100) {
          if (event.key !== 'Backspace' && event.key !== 'Delete') {
            event.preventDefault();
          }
        }
      }

      onKeyDown(event);
    },
    [onKeyDown],
  );

  const handleOnPaste = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    // Prevent the default paste action
    event.preventDefault();

    // Get the pasted data
    const pasteData = event.clipboardData.getData('text');

    // Sanitize the pasted data
    const sanitizedData = DOMPurify.sanitize(pasteData, {
      ALLOWED_TAGS: ['personefy-token'],
      ALLOWED_ATTR: ['style', 'data-attribute-name', 'contenteditable', 'initialvalue'],
    });

    if (divElement.current) {
      const charCount = getInnerHTMLLengthExcludingTokens(divElement.current);

      if (charCount + sanitizedData.length > 100) {
        return;
      }
    }

    // TODO: Replace execCommand with non-deprecated alternative
    // Insert the sanitized data at the current cursor position
    document.execCommand('insertHTML', false, sanitizedData);
  }, []);

  return (
    <>
      <Typography variant="body2" className={[styles.textContainer, styles.fieldLabel].join(' ')}>
        <b>{label}</b>
      </Typography>
      <div className={styles.inputContainer}>
        <div
          id={id}
          className={`${styles.input} ${isEditMode ? styles.textEdit : styles.textNoEdit}`}
          contentEditable={isEditMode}
          onBlur={handleOnBlur}
          onClick={handleOnClick}
          onDoubleClick={handleOnDoubleClick}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          dangerouslySetInnerHTML={{ __html: initialValue }}
          ref={divElement}
          data-name={id}
        ></div>
      </div>
    </>
  );
}
