import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
// import Bee from '@mailupinc/bee-plugin/dist';
// import { IBeeConfig } from '@mailupinc/bee-plugin/dist/types/bee';

import { useAppDispatch } from '@store';
import { func, searchQuery, useLoader } from '@utils';
import { mailer } from '@modules';
import { content } from '@content';

import { MailBuilderProps } from './MailBuilder.props';
import { BUILDER_NODE_ID } from './MailBuilder.const';
// import { isJsonEmpty, templateToJson } from './MailBuilder.utils';
import { useStyles } from './MailBuilder.styles';

export const MailBuilder = ({
  className = '',
  disabled = false,
  value = '',
  onChange,
}: MailBuilderProps): JSX.Element => {
  const styles = useStyles();

  const ref = useRef<HTMLDivElement>(null);

  // DISABLED SINCE BEE EDITOR IS NOT USED
  // const builder = useRef<Bee | null>(null);
  const prevValue = useRef(value);

  const dispatch = useAppDispatch();

  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');

  const mailerAuthMeta = mailer.useAuthMeta();
  const mailerAuthData = mailer.useAuthData();

  useLoader(mailerAuthMeta);

  const mailerAuthStatus = useRef(mailerAuthMeta.status);

  const [loaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => setLoaded(true), [setLoaded]);

  // DISABLED SINCE BEE EDITOR IS NOT USED
  // const handleSave = useCallback(
  //   (jsonTemplate: string, htmlTemplate: string) => {
  //     const json = templateToJson(jsonTemplate);
  //     const html = isJsonEmpty(json) ? '' : htmlTemplate;
  //     onChange(json, html);
  //   },
  //   [onChange],
  // ) as IBeeConfig['onSave'];

  // const config: IBeeConfig = useMemo(
  //   () => ({
  //     uid: content.companyBeePluginUniqueId,
  //     container: BUILDER_NODE_ID,
  //     language: 'en-US',
  //     onLoad: handleLoad,
  //     onSave: handleSave,
  //     onError: func.nop,
  //     customCss: `${window.location.origin}/styles.css`,
  //   }),
  //   [handleLoad, handleSave],
  // );

  const cleanup = useCallback(() => {
    dispatch(mailer.actions.resetAuth());

    const node = ref.current;

    if (node) {
      Array.from(node.childNodes).forEach((child) => node.removeChild(child));
    }

    setLoaded(false);
  }, [dispatch, setLoaded]);

  // DISABLED SINCE BEE EDITOR IS NOT USED
  // useEffect(() => {
  //   const mailerAuthJustSucceed = mailerAuthStatus.current === 'loading' && mailerAuthMeta.status === 'success';

  //   mailerAuthStatus.current = mailerAuthMeta.status;

  //   if (mailerAuthJustSucceed) {
  //     DISABLED SINCE BEE EDITOR IS NOT USED
  //     builder.current = new Bee(mailerAuthData as unknown as string);
  //     DISABLED SINCE BEE EDITOR IS NOT USED
  //     builder.current.start(config, templateToJson(value), '', {});
  //   }
  // }, [mailerAuthMeta.status, mailerAuthData, config, value]);

  useEffect(() => {
    dispatch(mailer.thunk.authenticate(businessUnitId));

    return () => cleanup();
  }, [dispatch, cleanup, businessUnitId]);

  useEffect(() => {
    if (value !== prevValue.current) {
      // DISABLED SINCE BEE EDITOR IS NOT USED
      // builder.current?.reload(templateToJson(value));
      prevValue.current = value;
    }
  }, [value]);

  return (
    <Box
      className={`${styles.mailBuilder} ${className}`}
      id={BUILDER_NODE_ID}
      data-disabled={disabled}
      data-loaded={loaded}
      {...{ ref }}
    />
  );
};
