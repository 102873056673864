import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Breadcrumbs, Button } from '@material-ui/core';

import { Accordion, Icon, Menu, OptionItem } from '@components';
import { content } from '@content';
import { textTemplate, useHistory } from '@utils';

import { TopBarProps } from './TopBar.props';
import { TopBarContext } from './TopBar.context';
import { useStyles } from './TopBar.styles';
import { MENU_OPTIONS, MenuKeys, TOP_BAR_CTX_VALUE } from './TopBar.const';
import { parseAndCleanHTML } from './TopBar.utils';
import { ViewportSizeContext, ViewportSize } from '../Viewport';
import { businessUnitDeployments, deployment, enterprise, template, userClients } from '@modules';
import { useParams } from '@utils/searchQuery/searchQuery';
import { useAppDispatch } from '@store';
import { ChangeTemplateModal } from './ChangeTemplateModal';
import { updateTemplateExperienceIds } from '@routes/Deployments/Deployments.helpers';
import { TreatmentBuilderContext } from '@routes';
import { ToggleButtons, ToggleButtonsProps } from '@components/ToggleButtons';
import { SubjectLinePreheader } from './SubjectLinePreheader';
import AddSignalDropdown from '@components/Wysiwyg/UniversalContainer/AddSignalDropdown';

/**
 * TopBar component
 * @param onDeploymentChange - action on change deployment in picker
 * @param onBackClick - action on click back button. 'breadcrumbs' view only
 * @param onClientChange - action on change businessUnit in picker
 * @returns {JSX.Element}
 */
export const TopBar = ({
  onDeploymentChange,
  onExperienceChange,
  onBackClick,
  onClientChange,
  onMailFileChange,
  onTemplateChange,
}: TopBarProps): JSX.Element => {
  const styles = useStyles();

  const {
    sectionName,
    moduleType,
    moduleName,
    chosenTreatment,
    contentModuleName,
    editableImageName,
    moduleDeployment,
    currentEmailTemplate,
    lastEmailTemplateId,
    isTemplateIdChanged,
    isTemplateListVisible,
  } = {
    ...TOP_BAR_CTX_VALUE,
    ...useContext(TopBarContext)[0],
  };
  const [, setTopBarCtx] = useContext(TopBarContext);
  const dispatch = useAppDispatch();
  const [curbusinessUnitId, curEnterpriseId] = useParams('businessUnit', 'enterprise');
  const history = useHistory();
  const enterprises = enterprise.useListData();
  const curEnterprise = enterprises.find((enterpriseItem) => enterpriseItem.id === Number(curEnterpriseId));
  const curDeployment = deployment.useDeploymentData();
  const deployments = businessUnitDeployments.useData();
  const businessUnit = curEnterprise?.businessUnits.find(
    (businessUnitItem) => businessUnitItem.id === Number(curbusinessUnitId),
  );

  const [currentSection, setCurrentSection] = useState<string | undefined>(sectionName);

  const [subjectLineValue, setSubjectLineValue] = useState(curDeployment?.subjectLine);
  const [preheaderValue, setPreheaderValue] = useState(curDeployment?.preheader);
  const [ptokenInputTarget, setPtokenInputTarget] = useState<HTMLDivElement | undefined>(undefined);

  // Personalization Token Accordion State
  const [personalizationExpanded, setPersonalizationExpanded] = useState(true);
  // Personalization Token Target
  const [personalizationTargetInputId, setPersonalizationTargetInputId] = useState<string | undefined>(undefined);
  // cursor position in subject line or preheader field
  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const [resetViewportSizeToggle, setResetViewportSizeToggle] = useState(true);

  const [isChangeTemplateVisible, setIsChangeTemplateVisible] = useState(false);
  const { deployment: deploymentId, mailFile: mailFileId, template: templateId } = history.query;
  const currentClientData = userClients.useEntityData();
  const {
    data: { deploymentEmailTemplates },
  } = deployment.useInfoEmailTemplate();
  const isSaveTemplateDisabled = lastEmailTemplateId === templateId && !isTemplateIdChanged;

  const [isSubjectLinePreheaderEditMode, setIsSubjectLinePreheaderEditMode] = useState(false);

  const { viewportSize, setViewportSize, isViewportSizeEnabled, setIsViewportSizeEnabled } =
    useContext(ViewportSizeContext);

  // used by the viewport size toggle to set the size on change
  const handleViewportSizeChange = useCallback(
    (size: string) => {
      setViewportSize(size as ViewportSize);
    },
    [setViewportSize],
  );

  // Properties for the viewport size toggle
  const viewportToggleButtonsProps: ToggleButtonsProps = {
    ariaLabel: 'Viewport Toggle',
    defaultValue: viewportSize,
    isEnabled: isViewportSizeEnabled,
    onChangeHandler: handleViewportSizeChange,
    reset: resetViewportSizeToggle,
    toggleButtons: [
      {
        value: ViewportSize.Desktop,
        altText: content.desktop,
        ariaLabel: content.desktop,
        iconSVG: <Icon.Desktop width="24px" height="24px" />,
      },
      {
        value: ViewportSize.Mobile,
        altText: content.mobile,
        ariaLabel: content.mobile,
        iconSVG: <Icon.Mobile width="24px" height="24px" />,
      },
    ],
  };

  // update the subject line and preheader if the current deployment changes
  useEffect(() => {
    setSubjectLineValue(curDeployment?.subjectLine);
    setPreheaderValue(curDeployment?.preheader);
  }, [curDeployment]);

  //const [viewportToggleButtonsProps, setViewportToggleButtonsProps] = useState(viewportToggleButtonsPropsDefaults);

  // Properties for the Preview toggle
  // const previewToggleButtonsProps: ToggleButtonsProps = {
  //   ariaLabel: 'Preview Toggle',
  //   defaultValue: 'editor',
  //   isEnabled: false,
  //   onChangeHandler: handleViewportSizeChange,
  //   toggleButtons: [
  //     {
  //       value: 'editor',
  //       altText: content.editor,
  //       ariaLabel: content.editor,
  //       iconSVG: <Icon.EditTemplate width="20px" height="20px" />,
  //     },
  //     {
  //       value: 'preview',
  //       altText: content.preview,
  //       ariaLabel: content.preview,
  //       iconSVG: <Icon.Preview width="24px" height="24px" />,
  //     },
  //   ],
  // };

  useEffect(() => {
    dispatch(businessUnitDeployments.thunk.getAll(Number(curbusinessUnitId)));
  }, [curbusinessUnitId, dispatch]);

  const onMenuClick = useCallback((option: OptionItem) => {
    const moduleModalClose = document.getElementById('module-modal-close');

    moduleModalClose?.click();

    switch (option.id) {
      case MenuKeys.CHANGE_EMAIL_TEMPLATE:
        setTopBarCtx((prev) => ({
          ...prev,
          isTemplateListVisible: true,
        }));
        break;
      case MenuKeys.MANAGE_DEPLOYMENT:
        setTopBarCtx((prev) => ({
          ...prev,
          isTemplateListVisible: false,
        }));
        break;
    }
  }, []);

  // let breadCrumbsArray: { label: string; value: string | undefined }[] = [];
  // let breadCrumbsArray = [
  //   {
  //     label: 'Enterprise',
  //     value: curEnterprise?.name,
  //   },
  //   {
  //     label: 'Business Unit',
  //     value: businessUnit?.name,
  //   },
  // ];

  // if (sectionName) {
  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Tool',
  //       value: sectionName,
  //     },
  //   ]);
  // }

  // if (curDeployment && sectionName === textTemplate(content.entityManager, { entity: content.deployment })) {
  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Program',
  //       value: curDeployment?.program.name,
  //     },
  //     {
  //       label: 'Deployment',
  //       value: curDeployment?.name,
  //     },
  //   ]);
  // }

  // if (moduleDeployment && sectionName === textTemplate(content.entityManager, { entity: content.content })) {
  //   const curModuleDeployment = deployments.find((dep) => dep.name === moduleDeployment);

  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Program',
  //       value: curModuleDeployment?.program.name,
  //     },
  //     {
  //       label: 'Deployment',
  //       value: moduleDeployment,
  //     },
  //   ]);
  // }

  // if (
  //   curDeployment &&
  //   sectionName === textTemplate(content.entityManager, { entity: content.deployment }) &&
  //   history.query.template
  // ) {
  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Template',
  //       value: curDeployment?.currentEmailTemplate?.name,
  //     },
  //   ]);
  // }

  // if (currentEmailTemplate && sectionName === textTemplate(content.entityManager, { entity: content.content })) {
  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Template',
  //       value: currentEmailTemplate,
  //     },
  //   ]);
  // }

  // if (moduleType && moduleType !== content.singleModule && moduleName) {
  //   breadCrumbsArray.push({
  //     label: `${moduleType}`,
  //     value: moduleName,
  //   });
  // }

  // if (editableImageName) {
  //   breadCrumbsArray = breadCrumbsArray.concat([
  //     {
  //       label: 'Edit image',
  //       value: editableImageName,
  //     },
  //   ]);
  // }

  // if (chosenTreatment) {
  //   breadCrumbsArray.push({
  //     label: 'Segment',
  //     value: chosenTreatment.name,
  //   });
  // }

  // if (contentModuleName) {
  //   breadCrumbsArray.push({
  //     label: 'Content Module',
  //     value: contentModuleName,
  //   });
  // }

  const handleShowChangeTemplate = () => {
    setIsChangeTemplateVisible(true);
  };

  const handleChangeTemplateClose = async () => {
    if (lastEmailTemplateId) {
      const firstMailFile = currentClientData?.mailFile[0];

      const selectionResult = await dispatch(template.thunk.selectTemplateToDeployment(lastEmailTemplateId));

      if (template.thunk.selectTemplateToDeployment.fulfilled.match(selectionResult)) {
        const tmplUpdated = await updateTemplateExperienceIds(lastEmailTemplateId, dispatch, deploymentEmailTemplates);

        if (tmplUpdated) {
          history.push('', { template: lastEmailTemplateId });
        }
      }

      if (deploymentId && !mailFileId && firstMailFile) {
        dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: firstMailFile.id }));
      }
      setIsChangeTemplateVisible(false);

      setTopBarCtx((prev) => ({
        ...prev,
        isTemplateIdChanged: false,
        isTemplateListVisible: false,
      }));
    }
  };

  const handleCancelChangeTemplate = () => {
    handleChangeTemplateClose();
  };

  const handleChangeTemplateConfirm = () => {
    setIsChangeTemplateVisible(false);
    setTopBarCtx((prev) => ({
      ...prev,
      isTemplateListVisible: false,
      isTemplateIdChanged: false,
    }));
  };

  // check if the section has changed. If so, reset the viewport size
  useEffect(() => {
    if (currentSection && currentSection !== sectionName) {
      setCurrentSection(sectionName);
      setResetViewportSizeToggle(true);
      handleViewportSizeChange(viewportToggleButtonsProps.defaultValue);
    }
  }, [currentSection, handleViewportSizeChange, sectionName, viewportToggleButtonsProps.defaultValue]);

  const onSubjectLinePreheaderSave = async (subject: string, preheader: string) => {
    // clean up the inputs
    const cleanSubject = parseAndCleanHTML(subject);
    const cleanPreheader = parseAndCleanHTML(preheader);
    setSubjectLineValue(cleanSubject);
    setPreheaderValue(cleanPreheader);

    // create a form data object anbd append fields
    const formData = new FormData();
    formData.append('subjectLine', cleanSubject);
    formData.append('preheader', cleanPreheader);

    // Save subject line and preheader
    if (deploymentId !== undefined && !isNaN(deploymentId)) {
      await dispatch(deployment.thunk.update({ deployment: deploymentId, body: formData }));
    }

    setIsSubjectLinePreheaderEditMode(false);
  };

  const onSubjectLinePreheaderDoubleClick = (id: string) => {
    setIsSubjectLinePreheaderEditMode(true);
    setPersonalizationTargetInputId(id);
  };

  const onSubjectLinePreheaderCancel = () => {
    setIsSubjectLinePreheaderEditMode(false);
    setPersonalizationTargetInputId(undefined);
    setSubjectLineValue(curDeployment?.subjectLine);
    setPreheaderValue(curDeployment?.preheader);
  };

  const onCursorPositionChange = (target: HTMLDivElement | undefined, position: number) => {
    setPtokenInputTarget(target);
    setCursorPosition(position);
  };

  const onSubjectLinePreheaderSignalInsert = useCallback(
    (chosenElem: HTMLTextAreaElement | HTMLDivElement, signalHtml: string) => {
      const id = chosenElem.id;

      //todo: may be necessary to remove all &nbsp; from the html string before splitting
      const html = [
        chosenElem.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `).slice(0, cursorPosition),
        signalHtml,
        chosenElem.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `).slice(cursorPosition),
      ].join('');

      switch (id) {
        case 'editor-subjectLine':
          setSubjectLineValue(html);
          break;
        case 'editor-preheader':
          setPreheaderValue(html);
          break;
        default:
          break;
      }
    },
    [cursorPosition],
  );

  return (
    <>
      <Box className={styles.topBar}>
        {/* {breadCrumbsArray.length > 0 && (
        <Breadcrumbs separator={<Icon.ChevronRight />} classes={{ root: styles.crumbs }}>
          {breadCrumbsArray.map((crumb, index) => {
            const last = index === breadCrumbsArray.length - 1;

            return (
              <Box key={`crumb-${index}`} className={styles.crumbsActive}>
                <Typography classes={{ root: styles.crumbsLabel }}>{crumb.label}</Typography>
                <Typography classes={{ root: styles.crumbsValue }}>{crumb.value}</Typography>
              </Box>
            );
          })}
        </Breadcrumbs>
      )} */}
        {sectionName === textTemplate(content.entityManager, { entity: content.deployment }) && (
          <Box className={styles.actions}>
            {!isTemplateListVisible && (
              <SubjectLinePreheader
                subject={subjectLineValue}
                preheader={preheaderValue}
                onSave={onSubjectLinePreheaderSave}
                onDoubleClick={onSubjectLinePreheaderDoubleClick}
                onCancel={onSubjectLinePreheaderCancel}
                onCursorPositionChange={onCursorPositionChange}
              />
            )}

            {!isTemplateListVisible && !isSubjectLinePreheaderEditMode && (
              <Box className={styles.buttonContainer}>
                <ToggleButtons {...viewportToggleButtonsProps} />
                <Menu buttonClassName={styles.menu} onClick={onMenuClick} options={MENU_OPTIONS} />
              </Box>
            )}

            {isTemplateListVisible && (
              // Save Selected Template button
              <Box className={styles.teamplteListButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSaveTemplateDisabled}
                  onClick={handleShowChangeTemplate}
                  className={styles.saveButton}
                >
                  {content.save}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCancelChangeTemplate}
                  className={styles.cancelButton}
                >
                  {content.cancel}
                </Button>
              </Box>
            )}

            {/* <ToggleButtons {...previewToggleButtonsProps} /> */}
          </Box>
        )}

        {isChangeTemplateVisible && (
          <ChangeTemplateModal
            title="Are you sure you want to change the template?"
            submitLabel="Confirm changes"
            cancelLabel="Discard changes"
            onClose={handleChangeTemplateClose}
            onSubmit={handleChangeTemplateConfirm}
          />
        )}
      </Box>
      {isSubjectLinePreheaderEditMode && (
        <>
          <Box className={styles.disableScreenOverlay} />
          <Box className={styles.addSignalRightPanel}>
            <Accordion
              expanded={personalizationExpanded}
              onChange={() => {
                setPersonalizationExpanded(!personalizationExpanded);
              }}
              title={content.personalization}
              uiType="quaternary"
            >
              <AddSignalDropdown
                targetInputFieldId={personalizationTargetInputId}
                onSignalInsert={onSubjectLinePreheaderSignalInsert}
              />
            </Accordion>
          </Box>
        </>
      )}
    </>
  );
};
