import { Icon } from '@components';
import { content } from '@content';
import { Box, Button, InputBase, Menu, debounce } from '@material-ui/core';
import { SignalsSearchItem, attributeLibrary } from '@modules';
import { Attribute } from '@routes/SignalBuilder/Attribute';
import { SEARCH_ATTRIBUTE_DELAY } from '@routes/SignalBuilder/DataAttributeLibrary/DataAttributeLibrary.const';
import { useAppDispatch } from '@store';
import { useHistory } from '@utils';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './DVAttributeDropdown.styles';
import { DVAttributeProps } from './DVAttributeDropdown.props';
import { variables } from '@styles';

export const DVAttributeDropdown: React.FC<DVAttributeProps> = ({ onChange }) => {
  const dispatch = useAppDispatch();
  const { businessUnit } = useHistory().query;
  const styles = useStyles();
  const { items: attributes } = attributeLibrary.useListData();
  const attributeMeta = attributeLibrary.useListMeta();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filteredAttributes = useMemo(
    () =>
      attributes.filter(
        (attribute) => ['textual', 'numeric'].includes(attribute.type) && attribute.signalType !== 'foreign',
      ),
    [attributes],
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runSearch = debounce(
    useCallback(
      (keyword: string) => {
        dispatch(
          attributeLibrary.thunk.search({
            businessUnit: businessUnit!,
            keyword,
          }),
        );
      },
      [dispatch, businessUnit],
    ),
    SEARCH_ATTRIBUTE_DELAY,
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      runSearch(event.target.value);
    },
    [runSearch],
  );

  const handleSignalClick = (signal: SignalsSearchItem) => {
    onChange(signal);
    handleClose();
  };

  useEffect(() => {
    if (attributeMeta.status === 'idle') {
      runSearch('');
    }
  }, [attributeMeta.status, runSearch]);

  return (
    <Box className={styles.DVAttribute}>
      <Button
        id="add-signal-btn"
        className={`${styles.addButton} ${anchorEl ? 'active' : ''}`}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        data-opened={Boolean(anchorEl)}
      >
        <Icon.Add fill={variables.color.primary.darkestGray} height="20" width="20" />
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: styles.menuList,
        }}
      >
        <InputBase
          className={styles.search}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        {filteredAttributes.map((attribute, index) => (
          <Attribute
            key={`attribute-${index}`}
            className={''}
            item={attribute}
            isClickable={true}
            onClick={handleSignalClick}
          />
        ))}
      </Menu>
    </Box>
  );
};
