import { SVGProps } from 'react';

export const ThreeDotsVertical = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 5 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="2.5" cy="17.749" r="2.5" transform="rotate(90 2.5 17.749)" fill={fill} />
    <circle cx="2.5" cy="10.3157" r="2.5" transform="rotate(90 2.5 10.3157)" fill={fill} />
    <circle cx="2.5" cy="2.5" r="2.5" transform="rotate(90 2.5 2.5)" fill={fill} />
  </svg>
);
