import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    pwdResetReqContainer: {
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F4F6F9',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: '100vh',
      width: '100vw',
    },
    pwdResetReq: {
      alignItems: 'venter',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '38.2rem',
    },
    form: {
      background: variables.color.primary.white,
      padding: theme.spacing(6),
      width: '42.2rem',
    },
    arrowIcon: {
      marginRight: theme.spacing(1),
    },
    buttons: {
      flex: 1,
    },
  }),
);
