import { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';

import { Attribute } from '../Attribute';

export const AttributeLibraryRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const item = data[index];
  const draggableId = JSON.stringify({
    id: item.id,
    label: item.label,
    type: item.type,
    signalType: item.signalType,
    isAttribute: true,
  });

  return (
    <Draggable draggableId={draggableId} index={index} key={item.id} isDragDisabled={item.signalType === 'composite'}>
      {(provided) => (
        <>
          {/* {item.type} */}
          <Attribute provided={provided} item={item} style={style} isCloudShown={item.signalType === 'foreign'} />
        </>
      )}
    </Draggable>
  );
}, areEqual);

AttributeLibraryRow.displayName = 'AttributeLibraryRow';
