import { Box, Button } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';

import { TextField, Typography } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { textTemplate } from '@utils';

import { signalLibrary } from '@modules';
import { Attribute } from '@routes/SignalBuilder/Attribute';
import { Controller, useForm } from 'react-hook-form';
import { useStyles } from './ForeignAttributes.styles';
import { SignalsImportProps, TESPData } from './ForeignAttributes.types';

/**
 ForeignAttributes component.
 @returns {JSX.Element}
 */

export const ForeignAttributes = memo(({ businessUnit, onClose, onSuccess }: SignalsImportProps): JSX.Element => {
  const styles = useStyles();
  const { items: signals } = signalLibrary.useListData();
  const [currentAttributes, setCurrentAttributes] = useState<any[]>([]);
  const [editMode, setEditMode] = useState({} as any);

  const dispatch = useAppDispatch();
  const { handleSubmit, reset, getValues, setValue, clearErrors, watch, setError, trigger, control } =
    useForm<TESPData>({});

  useEffect(() => {
    dispatch(
      signalLibrary.thunk.search({
        businessUnit,
        searchType: 'foreign',
      }),
    );
  }, [businessUnit, dispatch]);

  useEffect(() => {
    setCurrentAttributes(signals);
  }, [signals, setCurrentAttributes]);

  const handleImportClick = async () => {
    const { name, label, foreignDataSet, foreignKeyName } = getValues();

    clearErrors();

    if (!name) {
      setError(`name`, { type: 'required', message: 'Should not be blank.' });
    }

    if (!label) {
      setError(`label`, { type: 'required', message: 'Should not be blank.' });
    }

    if (!foreignDataSet) {
      setError(`foreignDataSet`, { type: 'required', message: 'Should not be blank.' });
    }

    if (!foreignKeyName) {
      setError(`foreignKeyName`, { type: 'required', message: 'Should not be blank.' });
    }

    const isOnEditMode = Object.keys(editMode).length;

    if (name && label && foreignDataSet && foreignKeyName && !isOnEditMode) {
      dispatch(
        signalLibrary.thunk.create({
          businessUnit: { id: businessUnit },
          foreignKeyName,
          foreignDataSet,
          label,
          name,
          signalType: 'foreign',
          type: 'textual',
        }),
      );
    } else if (name && label && foreignDataSet && foreignKeyName && isOnEditMode) {
      const curSignalIndex = Object.keys(editMode)[0];

      await dispatch(
        signalLibrary.thunk.update({
          id: currentAttributes[Number(curSignalIndex)].id,
          businessUnit: { id: businessUnit },
          foreignKeyName,
          foreignDataSet,
          label,
          name,
          signalType: 'foreign',
          type: 'textual',
        }),
      );
    }

    reset();
    setEditMode({});
  };

  const onSubmit = (e: any) => {};

  const handleAttributeDblClick = (index: number) => (item: any) => {
    setEditMode({ [index]: true });
    setValue('name', item.name);
    setValue('label', item.label);
    setValue('foreignDataSet', item.foreignDataSet);
    setValue('foreignKeyName', item.foreignKeyName);
  };

  return (
    <form>
      <Box className={styles.header}>
        <Box>
          <Typography.SmallCaption className={styles.topTitle}>{`${content.import} /`}</Typography.SmallCaption>
          <Typography.LargeTitle>{content.mapESPForeignDataAttributes}</Typography.LargeTitle>
        </Box>
      </Box>
      <form className={styles.espForm} onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.formContainer}>
          <Controller
            name="foreignDataSet"
            control={control}
            rules={{
              required: '',
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                required
                className={styles.textField}
                templated
                direction="horizontalV2"
                label={textTemplate(content.entityName, { entity: content.ESPDataExtension })}
                error={fieldState.invalid}
              />
            )}
          />
          <Controller
            name="foreignKeyName"
            control={control}
            rules={{
              required: '',
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                required
                className={styles.textField}
                templated
                direction="horizontalV2"
                label={textTemplate(content.entityName, { entity: content.ESPPrimaryKey })}
                error={fieldState.invalid}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{
              required: '',
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                required
                className={styles.textField}
                templated
                direction="horizontalV2"
                label={textTemplate(content.entityName, { entity: content.ESPDataField })}
                error={fieldState.invalid}
              />
            )}
          />
          <Controller
            name="label"
            control={control}
            rules={{
              required: '',
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                required
                className={styles.textField}
                templated
                direction="horizontalV2"
                label={textTemplate(content.entityName, { entity: content.ComposefaiDataAttribute })}
                error={fieldState.invalid}
              />
            )}
          />
        </Box>
      </form>
      <Box className={styles.body}>
        <Typography.Caption className={styles.description}>{content.foreignDataAttributesList}</Typography.Caption>
        <Box className={styles.attributesWrapper}>
          {currentAttributes.map((attribute, index) => (
            <Attribute
              key={`attribute-${index}`}
              className={''}
              item={attribute}
              isCloudShown={true}
              isClickable={true}
              editMode={!!editMode[index]}
              onDblClick={handleAttributeDblClick(index)}
            />
          ))}
        </Box>
      </Box>
      <Box className={styles.footer}>
        <Box className={styles.rightActionBtns}>
          <Button variant="outlined" color="primary" className={styles.cancelBtn} onClick={onClose}>
            {content.cancel}
          </Button>
          <Button variant="contained" color="primary" type="button" onClick={handleImportClick}>
            {Object.keys(editMode).length ? content.save : content.import}
          </Button>
        </Box>
      </Box>
    </form>
  );
});

ForeignAttributes.displayName = 'ForeignAttributes';
