import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

const cbx = {
  margin: '1.8rem 0 0',
  '& > *:nth-child(2)': { marginLeft: variables.gap.sm },
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    formOuterContainer: {
      ['@media screen and (min-height: 1200px)']: {
        padding: '24rem 0 0 0',
      },
      ['@media screen and (max-height: 1200px)']: {
        padding: '18rem 0 0 0',
      },
      ['@media screen and (max-height: 1000px)']: {
        padding: '14rem 0 0 0',
      },
      ['@media screen and (max-height: 900px)']: {
        padding: '10rem 0 0 0',
      },
      ['@media screen and (max-height: 736px)']: {
        padding: '6rem 0 0 0',
      },
      ['@media screen and (max-height: 670px)']: {
        padding: '4rem 0 0 0',
      },
      ['@media screen and (max-height: 610px)']: {
        padding: '2.8rem 0 0 0',
      },
    },
    formContainer: {
      background: '#FFFFFF !important',
      border: '1px solid #D8DDE6',
      borderRadius: '0.4rem',
      padding: '2rem 2rem',
      width: '38.2rem',
    },
    logo: {
      alignSelf: 'flex-start',
      height: '3.2rem',
      marginLeft: 'auto',
    },
    logoSalesforceAppExchange: {
      height: '11rem',
      marginLeft: '50%',
      paddingBottom: '2.5rem',
      transform: 'translateX(-50%)',
    },

    title: {
      color: '#16325c',
      fontSize: '2.4rem',
      fontWeight: 400,
      maxWidth: '100%',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      margin: '0 0 1.6rem 0', // theme.spacing(3, 0),
    },

    description: {
      color: '#16325c',
      fontSize: '1.3rem',
      fontWeight: 400,
      lineHeight: '2.4rem',
      marginBottom: theme.spacing(2),
    },

    link: {
      fontSize: '1.2rem', // variables.font.size.md,
      fontWeight: 400, //variables.font.weight.bold,
      lineHeight: '1.5rem', // variables.lineHeight.lg,
      color: '#0070D2', // variables.color.primary.darkestGray,
      textDecoration: 'none !important',
      letterSpacing: 0,
    },

    bottomDivider: {
      backgroundColor: '#F4F6F9',
      height: '.1rem',
      marginTop: '1.6rem',
      width: '100%',
    },

    bottomContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    label: {
      color: '#64698D',
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      // marginBottom: theme.spacing(1),
    },

    txt: {
      '&:not(:first-child)': {
        marginTop: '4rem',
      },
    },

    pwd: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },

    cbx,

    textfield: {
      '& .MuiInput-root': {
        border: '0 solid transparent',
        padding: '0 0',

        '& input': {
          backgroundColor: '#FFFFFF',
          border: '1px solid #D8DDE6',
          borderRadius: '0.4rem',
          color: '#16325C',
          fontSize: '1.3rem',
          fontWeight: 400,
          padding: '1.2rem 1.2rem',
          '&:focus': {
            outline: 0,
            borderColor: '#1589ee',
            backgroundColor: '#fff',
            boxShadow: '0 0 3px #0070D2',
          },
        },
      },
    },

    alert: {
      marginBottom: 0, //theme.spacing(2),
    },

    buttons: {
      display: 'flex',
      gap: '1.5rem',
      justifyContent: ' space-between',
      width: '100%',
    },

    button: {
      borderRadius: '.4rem',
      fontSize: '1.3rem',
      fontWeight: 400,
      padding: '12px 0',
      '&.MuiButton-text': {
        textTransform: 'none',
        fontSize: '1.3rem',
        fontWeight: variables.font.weight.regular,
        color: variables.color.primary.darkestGray,
        padding: theme.spacing(0.75, 0.375),
      },

      '&.MuiButton-contained': {
        backgroundColor: '#FFFFFF',
        border: '1px solid #D8DDE6',
        color: '#0070d2',
        '&:hover': {
          background: '#F4F6F9',
        },
      },
      '&.MuiButton-containedPrimary': {
        background: '#0070D2',
        color: '#FFFFFF',

        // padding: theme.spacing(1, 1.5),
        '&:hover': {
          background: '#005fb2 !important',
        },
      },
    },
  }),
);
