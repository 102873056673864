import { HTMLElementWithDocument } from '@routes';

export const getChosenElem = (id: string | undefined) => {
  if (id) {
    const selectedElement = document?.getElementById(id) as HTMLDivElement;
    return selectedElement;
  } else {
    const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
    const selectedElems = moduleElem?.contentDocument.querySelectorAll(
      '[style*="border: 2px solid rgb(107, 17, 201)"], [contenteditable]',
    );
    const elems: any = Array.from(selectedElems).filter((elem: any) => !elem.id.includes('journey'));
    return elems[0];
  }
};

export const rewriteChosenElementContent = (
  chosenElem: HTMLTextAreaElement | HTMLDivElement,
  cursorPosition: number,
  signalBody: string,
  chosenModuleElemId: string,
  onSignalInsert?: (chosenElem: HTMLTextAreaElement | HTMLDivElement, signalHtml: string) => void,
) => {
  if (onSignalInsert) {
    onSignalInsert(chosenElem, signalBody);
  } else {
    const html = [
      chosenElem.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `).slice(0, cursorPosition),
      signalBody,
      chosenElem.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `).slice(cursorPosition),
    ].join('');

    chosenElem.innerHTML = html;
  }

  chosenElem.dispatchEvent(new Event('input', { bubbles: true }));
};
