import { Box } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Accordion, Typography } from '@components';

import { EditTab } from '@routes/TemplateView/SampleTemplate/ModuleSettings/EditTab';
import { EditMode } from '../EditMode';
import { useStyles } from './HTMLEditor.styles';

/**
 ModuleSettings component
 @returns {JSX.Element}
 */

type TProps = {
  moduleHtml: string;
  moduleName: string;
  isTemplateEditor?: boolean;
  onEditorChange?: (value: string) => void;
};
export const HTMLEditor: React.FC<TProps> = ({
  moduleHtml,
  moduleName,
  isTemplateEditor,
  onEditorChange,
}): JSX.Element => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState<boolean>(true);
  const toggleAccordion = useCallback(() => setExpanded((prevState) => !prevState), [setExpanded]);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      setHeaderHeight(
        isTemplateEditor
          ? (headerRef.current?.getBoundingClientRect().height ?? 0) + 70
          : headerRef.current?.getBoundingClientRect().height,
      );
    }, 100);
  }, [headerRef.current?.getBoundingClientRect().height]);

  return (
    <Box className={styles.moduleSettings}>
      <div ref={headerRef} className={styles.header}>
        <Box className={styles.titleWrapper}>
          <Typography.Title className={styles.title}>{moduleName}</Typography.Title>
          <EditMode />
        </Box>
      </div>

      <Accordion expanded={expanded} onChange={toggleAccordion} title={'HTML editor'} uiType="quaternary">
        <Box className={styles.content}>
          <EditTab headerHeight={headerHeight} moduleHtml={moduleHtml} onEditorChange={onEditorChange} />
        </Box>
      </Accordion>
    </Box>
  );
};
