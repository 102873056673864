import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    login: {
      alignContent: 'center',
      alignItems: 'center', // 'flex-start',
      backgroundColor: '#F4F6F9',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: '100vh',
      // maxWidth: '87.4rem',
      // justifyContent: 'center',
      // position: 'relative',
      width: '50%',
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '38.2rem',
    },
    form: {
      width: '42.9rem',
      background: variables.color.primary.white,
      padding: theme.spacing(6),
    },

    newsCenter: {
      alignContent: 'center',
      background: variables.color.primary.white,
      fontSize: '1.2rem',
      height: '100vh',
      textAlign: 'center',
      width: '50%',
      '& h1': {
        color: '#737373',
        fontSize: '4rem',
        fontWeight: 800,
      },
      '& h2': {
        color: '#737373',
        fontSize: '4rem',
        fontWeight: 600,
      },
    },
    arrowIcon: {
      marginLeft: theme.spacing(1.25),
    },
  }),
);
