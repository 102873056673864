import { PositionVolumeDeploymentsDataSchema } from '@modules';
import { ComputedDatum } from '@nivo/bar';

import { Y_AXIS_MILESTONES, BAR_WIDTH, GAP_WIDTH, VISIBLE_BARS } from './BarStackedChart.const';

const convertHexToRGBA = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const generateColors = (count: number): string[] => {
  const mainColors = [
    '#143B59',
    '#3077C2',
    '#80C1FA',
    '#56BFDA',
    '#99FBC4',
    '#60D0B9',
    '#FFF192',
    '#6B30CC',
    '#796BF6',
    '#BE32B7',
    '#ED71B6',
    '#FAB0BD',
    '#F08C7A',
    '#F9B987',
  ];
  const step = 4;
  const array = [];
  for (let i = 1; i <= Math.ceil(count / mainColors.length); i += 1) {
    array.push(i);
  }
  return array.reduce((accumulator: string[], currentValue: number) => {
    if (currentValue % step === 1) {
      return [...accumulator, ...mainColors];
    }
    if (currentValue % step === 2) {
      return [...accumulator, ...mainColors.map((color) => convertHexToRGBA(color, 75))];
    }
    if (currentValue % step === 3) {
      return [...accumulator, ...mainColors.map((color) => convertHexToRGBA(color, 50))];
    }
    if (currentValue % step === 0) {
      return [...accumulator, ...mainColors.map((color) => convertHexToRGBA(color, 25))];
    }
    return [...accumulator];
  }, []);
};

export const calculatePadding = (data: ComputedDatum<PositionVolumeDeploymentsDataSchema>[]): number => {
  if (data && data.length < 7) {
    return 0.9 - 0.1 * data.length;
  }

  return 0.274;
};

export const calculateWidth = (
  data: ComputedDatum<PositionVolumeDeploymentsDataSchema>[],
  chartRef: React.RefObject<HTMLDivElement>,
  yAxisRef: React.RefObject<HTMLDivElement>,
): number => {
  const chart = chartRef.current;
  const yAxis = yAxisRef.current;
  const chartWidth = chart ? parseFloat(window.getComputedStyle(chart).width) : 0;
  const yAxisWidth = yAxis ? parseFloat(window.getComputedStyle(yAxis).width) : 0;

  return data.length > VISIBLE_BARS ? data.length * (BAR_WIDTH + GAP_WIDTH) : chartWidth - yAxisWidth;
};

export const getYAxisMilestones = (maxValue: number): number[] => {
  const stepRoundValue = 5;
  const maxMilestone = Math.ceil(maxValue / stepRoundValue) * stepRoundValue;
  const milestoneStep = Math.ceil(maxMilestone / Y_AXIS_MILESTONES / stepRoundValue) * stepRoundValue;
  const stepsCount = Math.ceil(maxMilestone / milestoneStep);

  const milestones = stepsCount > Y_AXIS_MILESTONES ? Y_AXIS_MILESTONES : stepsCount;

  const result = [];

  for (let i = 0; i <= milestones - 1; i++) {
    result.push(i * milestoneStep);
  }

  result.push(maxMilestone);

  return result;
};
