import { Box } from '@material-ui/core';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { content, image } from '@content';
import {
  DeploymentEntityData,
  DeploymentPosition,
  ExperienceDeployment,
  ExperienceItemMindset,
  ModuleDisclosureStation,
  ModuleInfoPayload,
  SingleModuleType,
  Template,
  deployment,
  experience,
  moduleDetails,
  modules,
  template,
} from '@modules';
import { useAppDispatch } from '@store';
import { variables } from '@styles';
import { doc, parse, textTemplate, useHistory, useWindowSize } from '@utils';

import { DEFAULT_DELETED_EXPERIENCE, RightPanelElems, TreatmentBuilderContext } from '../TemplateView.const';
import { ModuleHeader, ModuleHeaderProps } from './ModuleHeader';
import { ModuleModal, ModuleModalProps } from './ModuleModal';
import { RenameModuleModal, RenameModuleModalFormValues } from './RightPanel/Treatments/RenameModuleModal';

import { TopBarContext } from '@layouts';
import { ViewportSize, ViewportWidth, ViewportSizeContext } from '@layouts/Private/Viewport';
import { broadcaster } from '@utils/network/broadcast';
import { INITIAL_QUERY as INITIAL_QUERY_GROUP } from '../ModuleLibrary/ModuleGroups/ModuleGroups.const';
import { INITIAL_QUERY as INITIAL_QUERY_SINGLE } from '../ModuleLibrary/SingleModules/SingleModules.const';
import { getClearModuleHtmlExternally } from './ModuleModal/ModuleModal.hooks';
import { getExperienceElement, prepareRenamePayload } from './RightPanel';
import { RemoveExperienceModal } from './RightPanel/Treatments/RemoveExperienceModal';
import { TreatmentCardProps } from './RightPanel/Treatments/TreatmentCard';
import {
  DATA_DISCLOSURE_ATTRIBUTE,
  DATA_DISCLOSURE_CONTENT_ATTRIBUTE,
  DATA_EXPERIENCE_ID,
  DATA_FILLED_ATTRIBUTE,
  DATA_SPLITTER_ATTRIBUTE,
  DISCLOSURE_TITLE_CLASSNAME,
  DISCLOSURE_TITLE_STYLES,
  EMPTY_MODULE_NOTE,
  GLOBAL_STYLES_ID,
  IFRAME_STYLES,
  MODULE_REF_INIT,
  SPLITTER_ACTIVE_CLASSNAME,
  SPLITTER_DATASET_DISCLOSURE,
  SPLITTER_DATASET_EXPERIENCE_ID_KEY,
  SPLITTER_DATASET_ID_KEY,
  SPLITTER_FILLED_CLASSNAME,
  SPLITTER_LOADER_HTML,
  SPLITTER_LOADER_STYLES,
  SPLITTER_LOADER_STYLES_ID,
  TRASH_CAN_WIDTH,
} from './SampleTemplate.const';
import { useWrapHtmlElems } from './SampleTemplate.hooks';
import { useStyles } from './SampleTemplate.styles';
import {
  DisclosureTitle,
  EmailTemplateSplitter,
  HTMLElementWithDocument,
  Rows,
  SampleTemplateProps,
  SplitterInfos,
} from './SampleTemplate.types';
import {
  emptySplitter,
  fixFrameScale,
  getDisclosureSplitter,
  getLastSplitterKey,
  getNewRowsObject,
  getSplittersOrderArray,
  getSplittersPositionsArray,
  handleSidebarDragEnd,
  handleSidebarDragStart,
  handleSplitterDragEnter,
  handleSplitterDragLeave,
  handleSplitterDragOver,
  handleSplitterDragStart,
  removeElemInfo,
  resetPrevElem,
  restyleElemInfo,
  sortDisclosures,
  sortDisclosuresTitles,
} from './SampleTemplate.utils';
import { delay } from '@utils/func/func';

let splitters: { [splitterId: string]: EmailTemplateSplitter } = {};

let listeners: { [event: string]: EventListener } = {};

/**
 * SampleTemplate component
 * @returns {JSX.Element}
 */

export const SampleTemplate = memo(
  ({
    templateId,
    sidebarRef,
    deletedExperience,
    changedModuleHtml,
    isEditorChanged,
    setIsEditorChanged,
    setChangedModuleHtml,
    setUsedExperiences,
    setShowLibraryPanel,
    setRightPanelElem,
    setDeletedExperience,
    onModuleHtmlChange,
    onModuleModalClose,
  }: SampleTemplateProps): JSX.Element => {
    const styles = useStyles();
    const mindsetsData = experience.useMindsetsData();
    const { businessUnit: businessUnitId, deployment: deploymentId } = useHistory().query;
    const experienceData = experience.useItemData();
    const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);

    const dispatch = useAppDispatch();

    // useLoader(moduleDetails.useMeta());

    const handle = useRef(0);

    const {
      data: { deploymentEmailTemplates },
      status,
    } = deployment.useInfoEmailTemplate();

    const deploymentEntity = deployment.useDeploymentData();

    const emailTemplateDeployment = deploymentEmailTemplates?.find((item) => {
      return item.id === templateId;
    });

    const entityPositions = useMemo(() => deploymentEntity?.positions, [deploymentEntity?.positions]);
    const [loaded, setLoaded] = useState(false);
    const [rows, setRows] = useState<Rows>({});
    const [modalTopPosition, setModalTopPosition] = useState(0);
    const [moduleAction, setModuleAction] = useState(false);
    const [showRenameModuleModal, setShowRenameModuleModal] = useState(false);
    const [splittersOrder, setSplittersOrder] = useState<SplitterInfos>([]);
    const [templateStyles, setTemplateStyles] = useState<HTMLCollectionOf<HTMLStyleElement> | null>(null);

    const rowsCount = useRef(0);
    const frameWrapper = useRef<HTMLDivElement>(null);
    const frame = useRef<HTMLIFrameElement>(null);

    const disclosuresTitlesRef = useRef<DisclosureTitle[]>([]);
    const setDisclosuresTitles = useCallback((setterFunc: (prev: DisclosureTitle[]) => DisclosureTitle[]) => {
      disclosuresTitlesRef.current = setterFunc(disclosuresTitlesRef.current);
    }, []);

    const deploymentEntityRef = useRef<DeploymentEntityData>(deploymentEntity);
    const setDeploymentEntityRef = useCallback((setterFunc: (prev: DeploymentEntityData) => DeploymentEntityData) => {
      deploymentEntityRef.current = setterFunc(deploymentEntityRef.current);
    }, []);

    const disclosureRef = useRef<ModuleDisclosureStation | null>(null);
    const setDisclosureRef = useCallback(
      (setterFunc: (prev: ModuleDisclosureStation | null) => ModuleDisclosureStation | null) => {
        disclosureRef.current = setterFunc(disclosureRef.current);
      },
      [],
    );

    useEffect(() => {
      setDeploymentEntityRef(() => deploymentEntity);
    }, [setDeploymentEntityRef, deploymentEntity]);

    const htmlOld = useRef(emailTemplateDeployment?.html);

    const { redefinedDoc, chosenExperienceId, moduleHtmlElem, handleCloseWindow, handleElemClick } = useWrapHtmlElems(
      emailTemplateDeployment?.html,
      setShowLibraryPanel,
      setModalTopPosition,
      setRightPanelElem,
      setDeletedExperience,
      moduleAction,
    );

    const size = useWindowSize();

    const {
      chosenElemId,
      chosenModuleId,
      newExperienceCloned,
      isModuleChanged,
      chosenModuleId: chosenTreatmentId,
      chosenModuleElemId,
      disclosure: disclosureObj,
      showRemoveExperienceModal,
      showRemoveTreatmentModal,
      showRemoveWithRevisions,
      setChosenExperienceId,
      setShowRemoveWithRevisions,
      setShowRemoveTreatmentModal,
      setShowRemoveExperienceModal,
      setEditMode,
      setChosenModuleInnerHtml,
      setChosenElemId,
      setChosenLibrary,
      setDefaultChosenTreats,
      setNewExperienceCloned,
      setIsModuleChanged,
      setDisclosure,
      setChosenModuleId: setChosenTreatmentId,
      setExperiencesIds,
      moduleDataVisualizationStation,
      setModuleDataVisualizationStation,
    } = useContext(TreatmentBuilderContext);

    const { viewportSize, setViewportSize, setIsViewportSizeEnabled } = useContext(ViewportSizeContext);

    // enable/disable viewport toggle based on whether content is loaded
    useEffect(() => {
      setIsViewportSizeEnabled(loaded);

      if (!loaded) {
        setViewportSize(ViewportSize.Desktop);
      }
    }, [loaded, setIsViewportSizeEnabled, setViewportSize]);

    // Handles changes to the viewport size toggle
    useEffect(() => {
      if (frame.current) {
        if (viewportSize === ViewportSize.Mobile) {
          frame.current.style.width = ViewportWidth.Mobile;
        } else {
          frame.current.style.width = ViewportWidth.Desktop;
        }

        if (frameWrapper.current) {
          frameWrapper.current.style.height = `${frame.current.contentDocument?.documentElement.offsetHeight}px`;
        }
      }
    }, [viewportSize, frame]);

    // get the styles from the iframe template for the module iframe
    useEffect(() => {
      if (frame.current) {
        // const frameDoc = frame.current.contentWindow?.document;
        const frameDoc = frame.current.contentDocument?.documentElement;

        if (frameDoc) {
          setTemplateStyles(frameDoc.getElementsByTagName('style'));
        }
      }
    }, [frame.current?.contentDocument?.documentElement]);

    const experienceMindsets = experience.useMindsetsData();
    const mindsetsModule = experienceMindsets.find((item) => {
      if (chosenExperienceId) {
        return item.experienceId === +chosenExperienceId;
      }
    });
    const mindsets = useMemo<ExperienceItemMindset[]>(() => mindsetsModule?.modules || [], [mindsetsModule?.modules]);
    const groupedTreatments = mindsets.filter((item) => !item.isNullSubscriber);

    useEffect(() => {
      setDisclosureRef(() => disclosureObj);
    }, [setDisclosureRef, disclosureObj]);

    useEffect(() => {
      if (htmlOld.current !== emailTemplateDeployment?.html) {
        htmlOld.current = emailTemplateDeployment?.html;
        setLoaded(false);
      }
    }, [setLoaded, emailTemplateDeployment?.html]);

    useEffect(
      () => () => {
        dispatch(deployment.actions.resetPositions());
      },
      [dispatch],
    );

    // function for attaching events to splitters
    const attachSplitterEvents = (splitter: HTMLElement) => {
      Object.entries(listeners).forEach(([type, listener]) => {
        splitter.addEventListener(type, listener);
      });
    };

    // function for detaching events from splitters
    const detachSplitterEvents = useCallback((splitter: HTMLElement) => {
      Object.entries(listeners).forEach(([type, listener]) => {
        splitter.removeEventListener(type, listener);
      });
    }, []);

    const getLastSplitterId = () => parse.integer(Object.keys(splitters).pop() || '0');

    // function for creating a new empty splitter
    const appendSplitter = useCallback((splitter: EmailTemplateSplitter, isPreviousSplitter: boolean) => {
      let insertedElement;
      if (frame.current?.contentDocument) {
        const hasSplitterAttribute = (node: Element | null | undefined) => node?.hasAttribute(DATA_SPLITTER_ATTRIBUTE);
        const hasTemplateAttribute = (node: Element | null | undefined) => node?.hasAttribute(DATA_FILLED_ATTRIBUTE);
        const hasDisclosureAttribute = (node: Element | null | undefined) =>
          node?.hasAttribute(DATA_DISCLOSURE_ATTRIBUTE);

        const insertedSplitter = frame.current.contentDocument.createElement(splitter.tagName);
        const insertedSplitterId = getLastSplitterId() + 1;
        insertedSplitter.dataset[SPLITTER_DATASET_ID_KEY] = insertedSplitterId.toString();

        const splitterPrevNode = splitter.previousElementSibling;
        const splitterNextNode = splitter.nextElementSibling;

        // if isPreviousSplitter AND
        // the previous node does not have the attribute data-sample-template-splitter
        // OR isPreviousSplitter AND
        // it has the attribute data-sample-template-journey
        // insert the created insertedSplitter before the current splitter
        if (
          (isPreviousSplitter && !hasSplitterAttribute(splitterPrevNode)) ||
          (isPreviousSplitter && hasTemplateAttribute(splitterPrevNode))
        ) {
          insertedElement = splitter.parentNode?.insertBefore(insertedSplitter, splitter) as EmailTemplateSplitter;
          // Otherwise, if the splitterNextNode does not have the attribute data-sample-template-splitter
          // OR splitterNextNode has the attribute data-sample-template-journey
          // OR splitterNextNode has the attribute data-sample-template-disclosure
          // insert the splitter after the current splitter
        } else if (
          !hasSplitterAttribute(splitterNextNode) ||
          hasTemplateAttribute(splitterNextNode) ||
          hasDisclosureAttribute(splitterNextNode)
        ) {
          insertedElement = splitter.parentNode?.insertBefore(
            insertedSplitter,
            splitter.nextSibling,
          ) as EmailTemplateSplitter;
        }

        if (insertedElement) {
          attachSplitterEvents(insertedElement);
          splitters[insertedSplitterId] = insertedElement as EmailTemplateSplitter;
        }
      }

      return insertedElement as EmailTemplateSplitter;
    }, []);

    const getIframeDocument = () => {
      return frame.current?.contentWindow?.document;
    };

    const appendEmptySplitter = (node: HTMLElement, splitterId: number, insertBeforeNode = false) => {
      // get the iframe and create an empty splitter as a DIV
      const frameDoc = getIframeDocument();
      const insertedSplitter = frameDoc?.createElement('div');
      if (insertedSplitter) {
        insertedSplitter.dataset[SPLITTER_DATASET_ID_KEY] = splitterId.toString();

        if (insertBeforeNode) {
          node.parentNode?.insertBefore(insertedSplitter, node);
        } else {
          node.parentNode?.insertBefore(insertedSplitter, node.nextSibling);
        }
        attachSplitterEvents(insertedSplitter);
      }
    };

    const removeSplitter = (node: HTMLElement) => {
      // get the splitter ID
      const splitterId = node.dataset[DATA_SPLITTER_ATTRIBUTE];

      // remove the splitter events
      detachSplitterEvents(node);

      // remove the splitter
      node.remove();

      // return ID of removed splitter
      return splitterId;
    };

    const getNextSplitterID = (deletedSplitterIds: number[] = []) => {
      const frameDoc = getIframeDocument();
      const splitterIds: number[] = [];

      if (frameDoc) {
        // get all splitters
        const allSplitters = frameDoc.querySelectorAll<EmailTemplateSplitter>(`[${DATA_SPLITTER_ATTRIBUTE}]`);
        allSplitters.forEach((splitter) => {
          const splitterId = splitter?.dataset[DATA_SPLITTER_ATTRIBUTE];
          if (splitterId !== undefined) {
            splitterIds.push(+splitterId);
          }
        });
      }

      const allSplitterIds: number[] = splitterIds.concat(deletedSplitterIds);

      // sort by data-sample-template-splitter ID value
      allSplitterIds.sort((a, b) => a - b);

      // get highest ID value
      // return highest ID value + 1
      return allSplitterIds[allSplitterIds.length - 1] + 1;
    };

    // clean up the splitters so that there are no excess splitters or
    // incorrect order of splitters.
    // Each splitter with the attribute data-sample-template-journey should be
    // preceeded and followed by an empty splitter.
    // An empty splitter should not be preceeded or followed by another empty splitter.
    const cleanupSplitters = useCallback(() => {
      const deletedSplitterIds: number[] = [];
      const frameDoc = getIframeDocument();

      const allSplitters = frameDoc?.querySelectorAll<EmailTemplateSplitter>(`[${DATA_SPLITTER_ATTRIBUTE}]`);
      const allSplittersArray = Array.from(allSplitters || []);

      if (allSplittersArray.length > 0) {
        allSplittersArray.forEach((splitter) => {
          const splitterId = Number(splitter.dataset[DATA_SPLITTER_ATTRIBUTE]);

          // if the splitter has already been removed, skip
          if (deletedSplitterIds.includes(splitterId)) {
            return;
          }

          const splitterPrevNode = splitter.previousElementSibling;
          const splitterNextNode = splitter.nextElementSibling;

          // check if the splitter contains a module
          if (splitter.hasAttribute(DATA_FILLED_ATTRIBUTE)) {
            // check if the previous node is an empty splitter
            // if it is not either not a module (DATA_FILLED_ATTRIBUTE) and a splitter (DATA_SPLITTER_ATTRIBUTE)
            // or if it is module (DATA_FILLED_ATTRIBUTE)
            if (
              (!splitterPrevNode?.hasAttribute(DATA_FILLED_ATTRIBUTE) &&
                !splitterPrevNode?.hasAttribute(DATA_SPLITTER_ATTRIBUTE)) ||
              splitterPrevNode?.hasAttribute(DATA_FILLED_ATTRIBUTE)
            ) {
              // add an empty splitter before the current splitter
              appendEmptySplitter(splitter, getNextSplitterID(deletedSplitterIds), true);
            } else if (
              splitterNextNode?.hasAttribute(DATA_FILLED_ATTRIBUTE) ||
              (!splitterNextNode?.hasAttribute(DATA_FILLED_ATTRIBUTE) &&
                !splitterNextNode?.hasAttribute(DATA_SPLITTER_ATTRIBUTE))
            ) {
              appendEmptySplitter(splitter, getNextSplitterID(deletedSplitterIds), false);
            }
          }
          // otherwise, splitter should be an empty splitter
          // check if the next sibling is an empty splitter.
          // if it is, remove the next splitter
          else if (
            splitterNextNode?.hasAttribute(DATA_SPLITTER_ATTRIBUTE) &&
            !splitterNextNode?.hasAttribute(DATA_FILLED_ATTRIBUTE)
          ) {
            // remove the next splitter
            const deletedSplitterId = removeSplitter(splitterNextNode as HTMLElement);

            if (deletedSplitterId) {
              deletedSplitterIds.push(+deletedSplitterId);
            }
          }
        });
      }
    }, [frame]);

    // function for removing excess splitters
    const handleExcessSplittersRemove = () => {
      const frameDoc = frame.current?.contentWindow?.document;
      const splittersList = frameDoc?.querySelectorAll<EmailTemplateSplitter>(`[${DATA_SPLITTER_ATTRIBUTE}]`);
      const splittersArray = Array.from(splittersList || []);
      // removing first empty splitters
      // const firstExperienceSplitterIndex = splittersArray.findIndex((splitter) =>
      //   splitter.hasAttribute(DATA_FILLED_ATTRIBUTE),
      // );

      // for (let index = 0; index < firstExperienceSplitterIndex; index++) {
      //   const splitter = splittersArray[index];

      //   if (index > 0) {
      //     splitter.remove();
      //   }
      // }

      /*** Original block - to be removed after update 
       * ================================================
      splittersArray.reverse();

      // removing last empty splitters
      const lastExperienceSplitterIndex = splittersArray.findIndex((splitter) =>
        splitter.hasAttribute(DATA_FILLED_ATTRIBUTE),
      );

      for (let index = 0; index < lastExperienceSplitterIndex; index++) {
        const splitter = splittersArray[index];

        if (index > 1) {
          splitter.remove();
        }
      }
        * ================================================
        */
    };
    // function for getting Sample Template HTML
    const getTemplateHtml = useCallback((): Promise<string> => {
      return new Promise((resolve) => {
        if (!frame.current?.contentDocument) {
          resolve('');
        } else {
          const parser = new DOMParser();
          const parsedDoc = parser.parseFromString(emailTemplateDeployment?.html ?? '', 'text/html');

          parsedDoc.body.innerHTML = frame.current.contentDocument.body.innerHTML;

          const elemInfo = parsedDoc.querySelectorAll('[id^="elem-info-journey"]');
          const trashCan = parsedDoc.querySelectorAll('[id^="trash-can-journey"]');

          if (elemInfo) {
            elemInfo.forEach((elemInfoItem) => {
              elemInfoItem.remove();
            });
          }

          if (trashCan) {
            trashCan.forEach((trashCanItem) => {
              trashCanItem.remove();
            });
          }

          if (parsedDoc) {
            parsedDoc.head.querySelector(`style#${GLOBAL_STYLES_ID}`)?.remove();
            const splitterDivs = parsedDoc.querySelectorAll<HTMLDivElement>(`*[${DATA_SPLITTER_ATTRIBUTE}]`);

            splitterDivs.forEach((div) => {
              div.style.borderColor = 'transparent';
              div.style.borderWidth = '0';
              div.style.border = 'none';

              if (div.hasAttribute(DATA_DISCLOSURE_ATTRIBUTE)) {
                const disclosureList = div.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);

                if (disclosureList) {
                  disclosureList.innerHTML = '';
                }
              } else {
                div.innerHTML = '';
              }
            });

            // removeElemInfo('', newFrame.id);

            resolve(`${doc.getDoctype(parsedDoc)}${parsedDoc.documentElement.outerHTML}`);
          } else {
            resolve('');
          }

          // TODO: remove or apply footer functionality after clarification with the businessUnit
          // const existingTemplateFooter = frame.current?.contentWindow?.document.querySelector(
          //   `[${DATA_FOOTER_ATTRIBUTE}]`,
          // );
          //
          // if (!existingTemplateFooter) {
          //   const parser = new DOMParser();
          //   const parsedTemplateFooter = parser.parseFromString(TEMPLATE_FOOTER, 'text/html').body.firstChild;
          //
          //   if (parsedTemplateFooter) {
          //     frame.current.contentDocument.body.appendChild(parsedTemplateFooter);
          //   }
          // }
        }
      });
    }, [emailTemplateDeployment?.html]);

    // function for removing source splitter
    const handleSourceSplitterRemove = (sourceSplitterId: string) => {
      if (sourceSplitterId) {
        const sourceSplitter = splitters[sourceSplitterId];

        if (
          sourceSplitter.nextElementSibling?.hasAttribute(DATA_SPLITTER_ATTRIBUTE) ||
          sourceSplitter.previousElementSibling?.hasAttribute(DATA_SPLITTER_ATTRIBUTE)
        ) {
          sourceSplitter.remove();
        } else {
          emptySplitter(sourceSplitter);
        }
      }
    };

    const createSplittersObject = useCallback((frameDoc: Document) => {
      const placeholders = frameDoc.querySelectorAll<HTMLElement>(`*[${DATA_SPLITTER_ATTRIBUTE}]`);

      placeholders.forEach((placeholder) => {
        const id = parse.integer(placeholder.dataset[SPLITTER_DATASET_ID_KEY] || '');

        if (id) {
          splitters[id] = placeholder as EmailTemplateSplitter;

          attachSplitterEvents(placeholder);
        }
      });
    }, []);

    // functions for removing disclosure
    const handleDisclosureClean = useCallback(
      (disclosureSplitter: EmailTemplateSplitter, experienceId: number) => {
        if (disclosureSplitter) {
          disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}='${experienceId}']`).forEach((section) => {
            return section.remove();
          });

          setDisclosuresTitles((prev) => prev.filter((item) => item.experienceId !== experienceId));
        }
      },
      [setDisclosuresTitles],
    );

    const handleDisclosureRemove = useCallback(
      (disclosureSplitter: EmailTemplateSplitter, experienceId: number) => {
        handleDisclosureClean(disclosureSplitter, experienceId);

        // if (disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}]`).length === 0) {
        //   appendSplitter(disclosureSplitter, true);
        //   disclosureSplitter.remove();
        // }
      },
      // [appendSplitter, handleDisclosureClean],
      [handleDisclosureClean],
    );

    const handleTemplateUpdate = useCallback(
      async (splittersArray?: EmailTemplateSplitter[]) => {
        const html = await getTemplateHtml();

        if (html && emailTemplateDeployment?.id) {
          const { name } = emailTemplateDeployment;

          const queryParams = new URLSearchParams(window.location.search);
          const curTemplateId = Number(queryParams.get('template'));

          const result = await dispatch(template.thunk.update({ id: curTemplateId, name, html }));

          if (template.thunk.update.fulfilled.match(result)) {
            setModuleAction((prevState) => !prevState);

            if (splittersArray) {
              const experiencesIds = splittersArray
                .map((splitter: EmailTemplateSplitter) => Number(splitter.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY]))
                .filter((item) => item);

              if (experiencesIds && experiencesIds.length > 0) {
                setExperiencesIds(experiencesIds);

                return await dispatch(experience.thunk.getItemsMindsetsBatch(experiencesIds));
              }
            }

            return result;
          }
        }
      },
      [dispatch, emailTemplateDeployment, getTemplateHtml, setExperiencesIds],
    );

    // function for saving the Template to 'update_email_template_deployment' api route
    const saveTemplate = useCallback(
      async (emailTemplates: Template[] | null) => {
        const currentEmailTemplateDeployment = emailTemplates?.find((item) => {
          return item.id == templateId;
        });

        removeElemInfo('-journey');

        if (currentEmailTemplateDeployment?.id) {
          const frameDoc = frame.current?.contentWindow?.document;

          const splittersList = frameDoc?.querySelectorAll<EmailTemplateSplitter>(`[${DATA_SPLITTER_ATTRIBUTE}]`);
          const splittersArray = Array.from(splittersList || []);
          const splittersInfos = getSplittersPositionsArray(splittersArray);

          const positions = splittersInfos.map<DeploymentPosition>((info) => {
            const experienceId = parse.integer(info.experienceId || '');

            const oldPosition = deploymentEntityRef.current?.positions.find(
              (position) => position.experience?.id === experienceId,
            );

            let id: number | string = '';

            if (oldPosition) {
              id = oldPosition.id;
            }

            return { id, experience: { id: experienceId }, order: info.order };
          });

          const result = await dispatch(
            deployment.thunk.attachJourney(
              deploymentEntityRef.current
                ? {
                    ...deploymentEntityRef.current,
                    positions,
                  }
                : null,
            ),
          );

          if (deployment.thunk.attachJourney.fulfilled.match(result)) {
            const nextPositions = result.payload?.positions || [];

            splittersArray.forEach((splitter: EmailTemplateSplitter, index) => {
              const currentPosition = nextPositions.find((position) => position.index === index + 1);

              if (currentPosition) {
                const expId = currentPosition.experience?.id;

                splitter.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY] = expId?.toString();
              }
            });

            await handleTemplateUpdate(splittersArray);

            let prevExpId: number | undefined = undefined;

            const revisionedExpId = nextPositions.find((position) => {
              const oldPos = positions.find((item) => item.order === position.index);
              const isPosNew = oldPos && !oldPos.id && !!position.id;
              const oldPosExpId = oldPos?.experience?.id;
              const newPosExpId = position.experience?.id;
              const isExpRevisioned = !!oldPosExpId && !!newPosExpId && oldPosExpId !== newPosExpId;
              const filterMatch = isPosNew && isExpRevisioned;

              if (filterMatch) {
                prevExpId = oldPosExpId;
              }

              return filterMatch;
            })?.experience?.id;

            if (revisionedExpId && prevExpId) {
              const mindsetsResult = await dispatch(experience.thunk.getItemMindsets(revisionedExpId));

              if (experience.thunk.getItemMindsets.fulfilled.match(mindsetsResult) && mindsetsResult.payload) {
                const [defaultMindset] = mindsetsResult.payload;

                setExperiencesIds((prev) => [...prev.filter((item) => item !== prevExpId), revisionedExpId]);

                setDefaultChosenTreats((prev) => [
                  ...prev.filter((item) => item.experienceId !== prevExpId),
                  { id: defaultMindset.id, experienceId: revisionedExpId },
                ]);
              }
            }
          } else {
            //remove splitter with modules if an error is received from the back-end
            const experienceToRemove = positions.find((position) => !position.id)?.experience?.id;
            const splitterToRemove = splittersInfos.find(
              (info) => Number(info.experienceId) === experienceToRemove,
            )?.splitterId;

            if (positions.length > 0 && splitterToRemove) {
              const disclosureSplitter = getDisclosureSplitter(frame) as EmailTemplateSplitter;

              handleDisclosureClean(disclosureSplitter, Number(experienceToRemove));
              handleSourceSplitterRemove(splitterToRemove);
              setModuleAction((prevState) => !prevState);
            }
          }
        }
      },
      [dispatch, handleDisclosureClean, handleTemplateUpdate, setDefaultChosenTreats, setExperiencesIds, templateId],
    );

    // function for setting rows with new values
    const handleSetRowsWithNewValues = (newRows: Rows) => {
      // setRows(() =>
      //   Object.keys(newRows).reduce(
      //     (acc, key) => ({
      //       ...acc,
      //       [key]: {
      //         experienceId: newRows[key].experienceId || null,
      //       },
      //     }),
      //     {},
      //   ),
      // );

      setRows((prev) => {
        // Compare newRows with current rows
        const hasChanges = Object.keys(newRows).some((key) => newRows[key].experienceId !== prev[key]?.experienceId);

        if (hasChanges) {
          return Object.keys(newRows).reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                experienceId: newRows[key].experienceId || null,
              },
            }),
            {},
          );
        } else {
          return prev;
        }
      });
    };

    // function for setting rows after splitter moving
    const setRowsAfterSplitterDrop = (
      splitter: EmailTemplateSplitter,
      sourceSplitterId: string,
      destSplitterId: string,
      experienceId: number,
    ) => {
      setRows(({ [sourceSplitterId]: remove, ...nextState }) =>
        Object.keys(nextState).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              ...nextState[key],
            },
          }),
          {
            [destSplitterId]: {
              experienceId,
            },
          },
        ),
      );
    };

    // The following function should not be necessary and could be considered
    // for removal in the future. It is used to set the TR state with the
    // correct values after the splitter is moved.
    const setDisclosureFontStyles = (lastSplitter: EmailTemplateSplitter) => {
      const disclosureTr = lastSplitter?.parentElement?.parentElement;
      disclosureTr?.setAttribute('id', 'disclosure-tr');

      const trs =
        lastSplitter.parentElement?.parentElement?.parentElement?.querySelectorAll('tr:not(#disclosure-tr)') ?? [];
      const firstLevelTrs = Array.from(trs).filter((tr) => tr.parentNode === disclosureTr?.parentNode);
      let fontFamily = '';
      let fontSize = '';
      let color = '';

      firstLevelTrs.forEach((tr) => {
        if (tr.getAttribute('style')?.includes('font-family')) {
          fontFamily = window.getComputedStyle(tr, null).getPropertyValue('font-family');
        }
        if (tr.getAttribute('style')?.includes('font-size')) {
          fontSize = window.getComputedStyle(tr, null).getPropertyValue('font-size');
        }
        if (tr.getAttribute('style')?.includes('color')) {
          color = window.getComputedStyle(tr, null).getPropertyValue('color');
        }

        const tds = tr.querySelectorAll('td');

        tds.forEach((td) => {
          if (td.getAttribute('style')?.includes('font-family')) {
            fontFamily = window.getComputedStyle(td, null).getPropertyValue('font-family');
          }
          if (td.getAttribute('style')?.includes('font-size')) {
            fontSize = window.getComputedStyle(td, null).getPropertyValue('font-size');
          }
          if (td.getAttribute('style')?.includes('color')) {
            color = window.getComputedStyle(td, null).getPropertyValue('color');
          }
        });
      });

      if (disclosureTr) {
        disclosureTr.style.fontFamily = fontFamily;
        disclosureTr.style.fontSize = fontSize;
        disclosureTr.style.color = color;
        disclosureTr?.removeAttribute('id');
      }
    };

    // adding disclosure splitter section
    const handleAddDisclosureSplitter = useCallback(
      (lastSplitterKey: string, experienceId: number, currentModule: ExperienceItemMindset) => {
        const lastSplitter = splitters[lastSplitterKey];
        const frameDoc = frame.current?.contentDocument;
        let disclosureList: any = frameDoc?.querySelector(`[${DATA_DISCLOSURE_ATTRIBUTE}]`);

        if (!disclosureList) {
          disclosureList = lastSplitter;
        }

        const disclosureContent = disclosureList.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);

        // setDisclosureFontStyles(lastSplitter);

        disclosureList.setAttribute('draggable', 'true');
        disclosureList.setAttribute(DATA_DISCLOSURE_ATTRIBUTE, '1');
        disclosureList.classList.add(SPLITTER_FILLED_CLASSNAME);

        if (disclosureContent) {
          disclosureContent.innerHTML = `
            <div ${DATA_EXPERIENCE_ID}="${experienceId}">
              ${currentModule.moduleDisclosureStation?.disclosureHtml}
            </div>
          `;
        } else {
          disclosureList.innerHTML = `
            <div ${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}="1">
              <div ${DATA_EXPERIENCE_ID}="${experienceId}">
                ${currentModule.moduleDisclosureStation?.disclosureHtml}
              </div>
            </div>
          `;
        }

        appendSplitter(disclosureList, true);
        appendSplitter(disclosureList, false);

        setDisclosuresTitles(() => [
          {
            experienceId: experienceId,
            name: currentModule.name,
          },
        ]);
      },
      [appendSplitter, setDisclosuresTitles],
    );

    // adding disclosure html to the disclosure splitter section
    const handleAddDisclosureHtml = useCallback(
      async (disclosureSplitter: Element, experienceId: number, currentModule: ExperienceItemMindset) => {
        if (disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}='${experienceId}']`).length === 0) {
          const disclosureList = disclosureSplitter.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);

          if (disclosureList) {
            disclosureList.innerHTML += `
              <div ${DATA_EXPERIENCE_ID}="${experienceId}">
                ${currentModule.moduleDisclosureStation?.disclosureHtml}
              </div>
            `;
          }

          setDisclosuresTitles((prevState) => [
            ...prevState,
            {
              experienceId: experienceId,
              name: currentModule.name,
            },
          ]);
        }

        const filledSplittersList = frame.current?.contentWindow?.document.querySelectorAll<EmailTemplateSplitter>(
          `[${DATA_FILLED_ATTRIBUTE}]`,
        );

        // getting order of filled splitter attributes and reordering disclosures
        if (filledSplittersList) {
          const splittersOrderArray = getSplittersOrderArray(Array.from(filledSplittersList));
          setSplittersOrder(splittersOrderArray);

          const sortedDisclosures = sortDisclosures(
            splittersOrderArray,
            Array.from(disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}]`)),
          );

          const disclosuresList = disclosureSplitter.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);

          if (disclosuresList) {
            disclosuresList.innerHTML = sortedDisclosures.reduce((acc, disclosure) => acc + disclosure.outerHTML, '');
          }

          const sortedDisclosuresTitles = sortDisclosuresTitles(splittersOrderArray, disclosuresTitlesRef.current);

          setDisclosuresTitles(() => sortedDisclosuresTitles);
        }
      },
      [setDisclosuresTitles],
    );

    const handleTreatmentChange = useCallback<TreatmentCardProps['setChosenTreatmentId']>(
      (nextTreatmentId) => {
        if (nextTreatmentId !== chosenTreatmentId) {
          setChosenTreatmentId(nextTreatmentId);
        }
      },
      [setChosenTreatmentId, chosenTreatmentId],
    );

    const selectTreatment = useCallback(
      (nextTreatment: ExperienceItemMindset) => {
        handleTreatmentChange(nextTreatment.id);

        setDefaultChosenTreats((prevState: any) => {
          const chosenTreatment = {
            id: nextTreatment.id,
            experienceId: chosenExperienceId,
          };

          return [...prevState.filter((item: any) => item.experienceId !== chosenExperienceId), chosenTreatment];
        });

        setChosenModuleInnerHtml(nextTreatment.moduleHtml || EMPTY_MODULE_NOTE);
      },
      [chosenExperienceId, setChosenModuleInnerHtml, handleTreatmentChange, setDefaultChosenTreats],
    );

    const handleSingleModuleDrop = async (data: any, dataFilledAttribute: number) => {
      const parsedData = JSON.parse(data || '{}');
      const originModule = Number(parsedData.moduleId);

      if (originModule && parsedData.chosenModuleId && parsedData.experienceId === dataFilledAttribute) {
        await dispatch(
          modules.thunk.clone({
            recipientModule: parsedData.chosenModuleId,
            originModule,
          }),
        ).then(async (cloneResult) => {
          if (modules.thunk.clone.fulfilled.match(cloneResult) && parsedData.experienceId) {
            const moduleInfo = await dispatch(modules.thunk.getModuleInfo({ module: cloneResult.payload?.id ?? 0 }));

            if (moduleInfo.payload) {
              const module = moduleInfo.payload as unknown as ExperienceItemMindset;

              await dispatch(experience.actions.updateModule(module));

              selectTreatment(module);

              broadcaster.postMessage('refresh');
            }
          }
        });
      }
    };

    // function for drop Experience/Disclosure section to the Splitter
    const handleSplitterDrop = (event: DragEvent) => {
      const data = event.dataTransfer?.getData('application/json');
      let splitter = event.currentTarget as EmailTemplateSplitter;
      const dataFilledAttribute = Number(splitter.getAttribute(DATA_FILLED_ATTRIBUTE));

      if (dataFilledAttribute) {
        handleSingleModuleDrop(data, dataFilledAttribute);
      } else {
        (async (emailTemplates) => {
          const { cursor, experienceId, sourceSplitterId, disclosureHtml, moduleId } = JSON.parse(data || '{}');
          let nextExperienceId = experienceId;
          const destSplitterId = splitter.dataset[SPLITTER_DATASET_ID_KEY] as string;

          if (moduleId && deploymentId && businessUnitId) {
            const singleModuleType: SingleModuleType = 'module';
            const payload = {
              businessUnit: {
                id: businessUnitId,
              },
              singleModule: {
                id: moduleId,
                type: singleModuleType,
              },
              deployment: {
                id: deploymentId,
              },
              index: parse.integer(destSplitterId),
            };

            const result = await dispatch(experience.thunk.create(payload));

            if (experience.thunk.create.fulfilled.match(result) && result.payload) {
              nextExperienceId = result.payload.id;
            }
          }

          if (disclosureHtml) {
            // skip if drop in same position
            if (destSplitterId === sourceSplitterId) return;

            splitter?.classList.remove(SPLITTER_ACTIVE_CLASSNAME);
            splitter?.classList.add(SPLITTER_FILLED_CLASSNAME);
            splitter.innerHTML = SPLITTER_LOADER_HTML;

            splitter.setAttribute(DATA_DISCLOSURE_ATTRIBUTE, '1');
            splitter.setAttribute('draggable', 'true');
            splitter.innerHTML = disclosureHtml;

            handleSourceSplitterRemove(sourceSplitterId);

            appendSplitter(splitter, true);
            appendSplitter(splitter, false);

            await saveTemplate(emailTemplates);

            setRowsAfterSplitterDrop(splitter, sourceSplitterId, destSplitterId, nextExperienceId);
          } else if (sourceSplitterId || nextExperienceId) {
            // skip if drop in same position
            if (destSplitterId === sourceSplitterId) return;

            if (splitter.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY]) {
              splitter = appendSplitter(splitter, false);
            }

            splitter?.classList.remove(SPLITTER_ACTIVE_CLASSNAME);
            splitter?.classList.add(SPLITTER_FILLED_CLASSNAME);
            splitter.innerHTML = SPLITTER_LOADER_HTML;

            const result = await dispatch(experience.thunk.getItemMindsets(nextExperienceId));

            if (experience.thunk.getItemMindsets.fulfilled.match(result)) {
              appendSplitter(splitter, true);
              appendSplitter(splitter, false);

              const curModules = result.payload;

              if (curModules?.length) {
                setDefaultChosenTreats((prevState) => {
                  const chosenTreatment = {
                    id: curModules[0].id,
                    experienceId: nextExperienceId,
                  };

                  return [...prevState.filter((item) => item.experienceId !== nextExperienceId), chosenTreatment];
                });

                if (splitter) {
                  splitter.innerHTML = curModules[cursor].moduleHtml ?? EMPTY_MODULE_NOTE;
                  splitter.setAttribute('draggable', 'true');
                  splitter.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY] = nextExperienceId;
                  splitter.experienceData = {
                    modules: curModules,
                    cursor,
                  };

                  handleSourceSplitterRemove(sourceSplitterId);

                  // adding disclosure on drop if it exists in Module
                  const lastSplitterKey = getLastSplitterKey(frame);
                  const disclosureSplitter = getDisclosureSplitter(frame);

                  if (disclosureSplitter && curModules[cursor].moduleDisclosureStation?.disclosureHtml) {
                    await handleAddDisclosureHtml(disclosureSplitter, nextExperienceId, curModules[cursor]);
                    await saveTemplate(emailTemplates);

                    setRowsAfterSplitterDrop(splitter, sourceSplitterId, destSplitterId, nextExperienceId);
                  } else if (
                    !disclosureSplitter &&
                    lastSplitterKey &&
                    curModules[cursor].moduleDisclosureStation?.disclosureHtml
                  ) {
                    handleAddDisclosureSplitter(lastSplitterKey, nextExperienceId, curModules[cursor]);

                    await saveTemplate(emailTemplates);

                    const newRows = {
                      ...rows,
                      [destSplitterId]: {
                        experienceId: nextExperienceId,
                      },
                      [lastSplitterKey]: {
                        experienceId: null,
                      },
                    };

                    handleSetRowsWithNewValues(newRows);
                  } else {
                    await saveTemplate(emailTemplates);

                    setRowsAfterSplitterDrop(splitter, sourceSplitterId, destSplitterId, nextExperienceId);
                  }
                }
              }

              const iframe = document.getElementById('template') as HTMLIFrameElement;
              const elem = iframe?.contentDocument?.querySelector(
                `[data-sample-template-splitter="${destSplitterId}"]`,
              ) as HTMLDivElement;

              if (elem) {
                handleElemClick({ target: elem, currentTarget: elem }, elem.id, true);
                const type = elem.id.split('-')[1];

                if (type === 'single') {
                  await dispatch(modules.thunk.search({ ...INITIAL_QUERY_SINGLE, businessUnitId: businessUnitId! }));
                } else {
                  await dispatch(
                    modules.thunk.search({
                      ...INITIAL_QUERY_GROUP,
                      businessUnitId: businessUnitId!,
                      keyword: '',
                      searchType: 'module',
                    }),
                  );
                }

                elem.style.border = `0.2rem solid ${variables.color.primary.mainPurple}`;
              }
            }
          }

          setTimeout(() => {
            broadcaster.postMessage('refresh');
          }, 500);
        })(deploymentEmailTemplates);
      }
    };

    // adding event listeners with the functions to the variable

    useEffect(() => {
      listeners = {
        dragover: handleSplitterDragOver as EventListener,
        dragstart: handleSplitterDragStart as EventListener,
        drop: handleSplitterDrop as EventListener,
        dragenter: handleSplitterDragEnter as EventListener,
        dragleave: handleSplitterDragLeave as EventListener,
      };
    }, [deploymentEmailTemplates, templateId]);

    const handleLoad = useCallback(() => {
      setLoaded(true);
    }, [setLoaded]);

    // attaching event listeners to splitters
    useEffect(() => {
      const frameDoc = frame.current?.contentDocument;
      const frameBody = frameDoc?.body;
      const noStylesYet = !frameDoc?.querySelector(`#${GLOBAL_STYLES_ID}`);

      if (loaded && frameDoc && frameBody && noStylesYet) {
        const wrapper = frameWrapper.current;

        // append the global styles to the iframe
        const globalStyles = frameDoc.createElement('style');
        globalStyles.setAttribute('type', 'text/css');
        globalStyles.id = GLOBAL_STYLES_ID;
        globalStyles.innerHTML = IFRAME_STYLES;

        frameDoc.head.appendChild(globalStyles);

        // attach splitter loading animation styles to iframe
        const loaderStyles = frameDoc.createElement('style');
        loaderStyles.setAttribute('type', 'text/css');
        loaderStyles.id = SPLITTER_LOADER_STYLES_ID;
        loaderStyles.innerHTML = SPLITTER_LOADER_STYLES;

        frameDoc.head.appendChild(loaderStyles);

        if (wrapper) {
          frameWrapper.current.style.height = `${frameDoc.documentElement.offsetHeight}px`;
        }

        if (frameBody.children) {
          createSplittersObject(frameDoc);

          const disclosure = frameDoc.querySelectorAll<HTMLElement>(`[${DATA_DISCLOSURE_ATTRIBUTE}]`);

          if (disclosure.length) {
            attachSplitterEvents(disclosure[0]);
          }
        }
      }
    }, [loaded, emailTemplateDeployment?.html, createSplittersObject]);

    // detaching event listeners from splitters
    useEffect(
      () => () => {
        Object.values(splitters).forEach((splitter) => {
          detachSplitterEvents(splitter);
        });
        splitters = {};
      },
      [detachSplitterEvents, loaded],
    );

    const handleSetFrameHeight = useCallback(() => {
      if (frame.current?.contentDocument) {
        const windowHeight = window.innerHeight;

        const scale = fixFrameScale(frame.current);
        let frameHeight = frame.current.contentDocument.documentElement?.offsetHeight * scale;

        // set the frame's height to the window's height if the frame's height is less than the window's height
        if (windowHeight > frameHeight) {
          frameHeight = windowHeight;
        }

        frameWrapper.current?.style.setProperty('height', `${frameHeight}px`); // recalculate iframe wrapper height on drop
      }
    }, []);

    useEffect(() => {
      handleSetFrameHeight();
    }, [rows, loaded, size, handleSetFrameHeight]);

    // Adding events listeners to the sidebar
    useEffect(() => {
      const sidebar = sidebarRef.current;

      if (sidebar) {
        sidebar.addEventListener('dragstart', handleSidebarDragStart);
        sidebar.addEventListener('dragend', handleSidebarDragEnd);
      }

      return () => {
        if (sidebar) {
          sidebar.removeEventListener('dragstart', handleSidebarDragStart);
          sidebar.removeEventListener('dragend', handleSidebarDragEnd);
        }

        window.clearTimeout(handle.current);
        localStorage.setItem('transformedElems', '[]');
      };
    }, [sidebarRef]);

    // updating used experiences list for sidebar filter
    useEffect(() => {
      const currentPositionsCount = Object.keys(rows).length;

      if (currentPositionsCount !== rowsCount.current) {
        const usedExperiences = Object.values(rows).map((row) => row.experienceId);

        setUsedExperiences(usedExperiences);
      }

      rowsCount.current = Object.keys(rows).length;
    }, [rows, setUsedExperiences]);

    const cutModuleScrollBar = () => {
      const frameDoc = frame.current?.contentDocument;
      const blockGrids = frameDoc?.querySelectorAll('.block-grid') as NodeListOf<HTMLElement>;
      // const transformedElems: number[] = JSON.parse(localStorage.getItem('transformedElems') ?? '[]');

      Array.from(blockGrids ?? [])
        .filter((blockGrid) => {
          // const elemId: any =
          //   blockGrid.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getAttribute(
          //     'data-sample-template-splitter',
          //   );

          return !blockGrid.hasAttribute('transformed');
          // && !transformedElems.includes(Number(elemId));
        })
        .forEach((blockGrid: HTMLElement) => {
          const child = blockGrid.children[0];
          const blockGridWidth = blockGrid.getBoundingClientRect().width;
          const childWidth = child.getBoundingClientRect().width;

          if (blockGridWidth !== childWidth) {
            // dispatch(
            //   global.actions.enqueueNotification({
            //     message:
            //       'The width of the content module is greater than the width of the email template. This may result in an incorrect display of content in the email box.',
            //     options: { variant: 'warning' },
            //   }),
            // );
            blockGrid.style.display = 'flex';
            blockGrid.style.justifyContent = 'center';
            blockGrid.style.overflow = 'hidden';
            // const elemId: any =
            //   blockGrid.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getAttribute(
            //     'data-sample-template-splitter',
            //   );
            // localStorage.setItem('transformedElems', JSON.stringify(transformedElems.concat(Number(elemId))));
            blockGrid.setAttribute('transformed', 'true');
          }
        });
    };

    // filling the Sample Template with pre-saved splitter values
    useEffect(() => {
      const frameDoc = frame.current?.contentDocument;

      if (status === 'success' && loaded && frameDoc) {
        const splittersList = frameDoc?.querySelectorAll<EmailTemplateSplitter>(`[${DATA_SPLITTER_ATTRIBUTE}]`);
        const splittersArray = Array.from(splittersList || []);

        setTimeout(() => {
          cutModuleScrollBar();
        }, 1500);

        if (entityPositions && entityPositions.length > 0) {
          const experiencesSplitters = splittersArray.filter((splitter: EmailTemplateSplitter) =>
            splitter.hasAttribute(DATA_FILLED_ATTRIBUTE),
          );

          if (experiencesSplitters.length) {
            setSplittersOrder(getSplittersOrderArray(experiencesSplitters));
          }

          const newRows: Rows = {};

          const disclosureSplitter = getDisclosureSplitter(frame);
          const disclosuresList = disclosureSplitter?.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);

          if (disclosuresList) {
            disclosuresList.innerHTML = '';
          }

          splittersArray.forEach((splitter: EmailTemplateSplitter, index) => {
            if (!splitter.hasAttribute(DATA_DISCLOSURE_ATTRIBUTE)) {
              emptySplitter(splitter);
            }

            const currentPosition = entityPositions.find((position) => position.index === index + 1);

            if (currentPosition) {
              const experienceId = currentPosition.experience?.id;
              const splitterId = splitter.dataset[SPLITTER_DATASET_ID_KEY];

              if (splitterId && experienceId) {
                newRows[splitterId] = {
                  experienceId,
                };
              }

              if (!splitter.dataset[SPLITTER_DATASET_DISCLOSURE]) {
                splitter.classList.add(SPLITTER_FILLED_CLASSNAME);

                // attach preloader HTML and update size of the iframe container
                splitter.innerHTML = SPLITTER_LOADER_HTML;

                const wrapper = frameWrapper.current;

                if (wrapper) {
                  frameWrapper.current.style.height = `${frameDoc.documentElement.offsetHeight}px`;
                }
              }
            }
          });

          const experiencesIds = deploymentEntity?.positions
            .map((position) => position.experience?.id)
            .filter((id) => id) as number[];

          (async () => {
            if (experiencesIds) {
              setExperiencesIds(experiencesIds);

              const results = await dispatch(experience.thunk.getItemsMindsetsBatch(experiencesIds));

              if (experience.thunk.getItemsMindsetsBatch.fulfilled.match(results)) {
                const modulesArr = results.payload.reduce((acc: ExperienceItemMindset[][], item) => {
                  if (item.modules) {
                    acc.push(item.modules);
                  }

                  return acc;
                }, []);

                setDefaultChosenTreats(
                  modulesArr?.map(
                    (treatment, index) =>
                      treatment.map((item) => ({
                        id: item.id,
                        experienceId: experiencesIds[index],
                      }))[treatment.length - 1],
                  ),
                );

                splittersArray.forEach((splitter: EmailTemplateSplitter, index) => {
                  const currentPosition = entityPositions.find((position) => position.index === index + 1);

                  if (currentPosition) {
                    const experienceId = currentPosition.experience?.id;

                    const curModules = results.payload.find((item) => item.expId === experienceId)?.modules;

                    if (curModules?.length && splitter) {
                      const module = curModules[curModules.length - 1];
                      const moduleDisclosure = module.moduleDisclosureStation;

                      splitter.innerHTML = module.moduleHtml ?? EMPTY_MODULE_NOTE;
                      splitter.experienceData = {
                        modules: curModules,
                        cursor: 0,
                      };

                      splitter.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY] = experienceId?.toString();

                      appendSplitter(splitter, true);
                      appendSplitter(splitter, false);

                      // pre-filling disclosures
                      if (disclosureSplitter && !moduleDisclosure?.disclosureHtml) {
                        if (experienceId) {
                          handleDisclosureClean(disclosureSplitter as EmailTemplateSplitter, experienceId);
                          handleSetFrameHeight();
                        }
                      } else if (disclosureSplitter && moduleDisclosure?.disclosureHtml) {
                        if (disclosuresList) {
                          disclosuresList.innerHTML += `
                                      <div ${DATA_EXPERIENCE_ID}="${experienceId}">
                                        ${moduleDisclosure?.disclosureHtml}
                                      </div>
                                    `;

                          const splittersOrderArray = getSplittersOrderArray(experiencesSplitters);

                          const sortedDisclosures = sortDisclosures(
                            splittersOrderArray,
                            Array.from(disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}]`)),
                          );

                          disclosuresList.innerHTML = sortedDisclosures.reduce(
                            (acc, disclosure) => acc + disclosure.outerHTML,
                            '',
                          );

                          if (experienceId) {
                            setDisclosuresTitles((prevState) => [
                              ...prevState,
                              {
                                experienceId,
                                name: module.name,
                              },
                            ]);
                          }

                          const sortedDisclosuresTitles = sortDisclosuresTitles(
                            splittersOrderArray,
                            disclosuresTitlesRef.current,
                          );

                          setDisclosuresTitles(() => sortedDisclosuresTitles);

                          handleSetFrameHeight();
                        }
                      } else if (!disclosureSplitter && moduleDisclosure?.disclosureHtml) {
                        const lastSplitterKey = getLastSplitterKey(frame);

                        if (lastSplitterKey && experienceId && module.moduleDisclosureStation?.disclosureCopy) {
                          handleAddDisclosureSplitter(lastSplitterKey, experienceId, module);

                          setDisclosuresTitles((prevState) => [
                            ...prevState,
                            {
                              experienceId,
                              name: module.name,
                            },
                          ]);

                          handleSetFrameHeight();
                        }
                      }
                    }

                    handle.current = window.setTimeout(() => {
                      handleSetRowsWithNewValues(newRows);
                    }, 500);
                  }
                });
              }
            }
          })();
        } else if (entityPositions && entityPositions.length === 0) {
          splittersArray.forEach((splitter: EmailTemplateSplitter) => {
            emptySplitter(splitter);
          });

          cleanupSplitters();
        }
      }
    }, [status, loaded, entityPositions]); // eslint-disable-line react-hooks/exhaustive-deps

    // removing the excess empty splitter
    useEffect(() => {
      cleanupSplitters();
    }, [rows, cleanupSplitters]);

    // function for removing the Experience data from the splitter
    const handleExperienceRemove = useCallback(
      async (splitterId: string, experienceId: number) => {
        await dispatch(experience.thunk.remove());

        setDeletedExperience(DEFAULT_DELETED_EXPERIENCE);
        setRightPanelElem(RightPanelElems.DEPLOYMENT_SETTINGS);

        const curPositionIndex = deploymentEntityRef.current?.positions.findIndex(
          (position) => position.experience?.id === experienceId,
        );

        dispatch(experience.actions.removeItemMindset(experienceId));

        const splitter = splitters[splitterId];

        if (splitter) {
          const info = splitter.nextElementSibling;

          if (info?.id?.startsWith('elem-info-')) {
            info.remove();
          }

          splitter.previousSibling?.remove();
          // splitter.remove();
          // delete splitters[splitterId];

          // removing disclosure section when removing corresponding Experience
          const disclosureSplitter = getDisclosureSplitter(frame) as EmailTemplateSplitter;

          if (disclosureSplitter) {
            handleDisclosureRemove(disclosureSplitter, experienceId);
          }

          resetPrevElem('', 'template', chosenElemId, setChosenElemId);

          // await delay(1000);

          await handleTemplateUpdate();

          dispatch(deployment.actions.removePosition(curPositionIndex!));

          setTimeout(() => {
            broadcaster.postMessage('refresh');
          }, 500);
        }
      },
      [
        dispatch,
        setDeletedExperience,
        setRightPanelElem,
        chosenElemId,
        // splitters,
        // deploymentId,
        setChosenElemId,
        handleTemplateUpdate,
        handleDisclosureRemove,
      ],
    );

    // Deleting experience from the Template
    useEffect(() => {
      // const { isDeleted, splitterId } = deletedExperience;
      const isDeleted = deletedExperience.isDeleted;
      const splitterId = deletedExperience.splitterId;

      if (isDeleted && splitterId && chosenExperienceId && rows[splitterId]) {
        handleExperienceRemove(splitterId, Number(chosenExperienceId));

        setRows((prevState) => {
          const state = { ...prevState };
          const result = Object.keys(state).reduce((acc, key) => {
            return {
              ...acc,
              [key]: {
                experienceId: state[key].experienceId || null,
              },
            };
          }, {}) as any;

          delete result[splitterId];

          return result;
        });
      }

      (async () => {
        const documentElemId = 'template';
        const templateDoc = document.getElementById(documentElemId) as HTMLElementWithDocument;
        const target = templateDoc?.contentDocument.getElementById(chosenElemId);

        // await delay(100);

        if (target) {
          target.style.border = `0.2rem solid ${variables.color.primary.mainPurple}`;
        }
      })();
    }, [
      chosenElemId,
      chosenExperienceId,
      deletedExperience.isDeleted,
      deletedExperience.splitterId,
      handleExperienceRemove,
      rows,
      setRows,
    ]);

    const module = useRef(MODULE_REF_INIT);

    const handleModuleClose = useCallback(async () => {
      const scrollingArea = document.getElementById('sample-template');

      module.current = MODULE_REF_INIT;
      handleCloseWindow();
      setEditMode('no-code');
      setDisclosure(null);

      if (scrollingArea) {
        scrollingArea.style.overflow = 'auto';
      }

      setTimeout(() => {
        broadcaster.postMessage('refresh');
      }, 500);

      const documentElemId = 'template';
      const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
      const target = contentDocument.getElementById(chosenElemId);

      if (target) {
        target.style.border = `0.2rem solid ${variables.color.primary.mainPurple}`;
      }

      await restyleElemInfo(target, chosenElemId, documentElemId, true, setShowRemoveExperienceModal);

      // reset the trashCan x position
      const trashCan = contentDocument.getElementById(`trash-can-${chosenElemId}`);

      if (trashCan) {
        let curElemWidth = parseInt(ViewportWidth.Desktop);
        if (viewportSize == ViewportSize.Mobile) {
          curElemWidth = parseInt(ViewportWidth.Mobile);
        }

        trashCan.setAttribute(
          'style',
          `position: absolute; z-index: 1; margin-left: ${curElemWidth - TRASH_CAN_WIDTH}px; cursor: pointer;`,
        );
      }
    }, [chosenElemId, handleCloseWindow, setDisclosure, setEditMode, setShowRemoveExperienceModal, viewportSize]);

    const handleRemoveExperienceModalClose = useCallback(() => {
      setShowRemoveExperienceModal(false);
    }, [setShowRemoveExperienceModal]);

    const handleRemoveExperienceConfirm = useCallback(async () => {
      setDeletedExperience((prevState) => ({ ...prevState, isDeleted: true }));
      handleRemoveExperienceModalClose();
      module.current = MODULE_REF_INIT;
      handleCloseWindow();
    }, [handleCloseWindow, handleRemoveExperienceModalClose, setDeletedExperience]);

    const handleModuleChange = useCallback<ModuleModalProps['onChange']>((html, dirty, getClearModuleHtml) => {
      module.current = { html, dirty, getClearModuleHtml };
    }, []);

    const handleRenameModuleModalClose = useCallback(() => {
      setShowRenameModuleModal(false);
    }, [setShowRenameModuleModal]);

    const chosenModule = useMemo(() => {
      return mindsetsData
        .find((data) => data.experienceId === Number(chosenExperienceId))
        ?.modules.find((mod) => mod.id === chosenModuleId);
    }, [chosenExperienceId, chosenModuleId, mindsetsData]);

    const mindsetList = useMemo(
      () =>
        mindsetsData.reduce((acc: ExperienceItemMindset[], data) => {
          return acc.concat(data.modules);
        }, []),
      [mindsetsData],
    );

    const chosenModuleDeps = useMemo(() => {
      if (chosenModule) {
        const { deployments: deps } = chosenModule;

        return Array.isArray(deps) ? deps : Array.from<ExperienceDeployment>(Object.values(deps || {}));
      }

      return [];
    }, [chosenModule]);

    // Setting information about module deployments / Setting chosen module disclosure
    const renderDisclosureUpdates = useCallback(
      async (updatedModule?: ModuleInfoPayload) => {
        const experienceIdNumeric = Number(chosenExperienceId);
        const currentModule = (updatedModule || chosenModule) as typeof chosenModule;

        // changing disclosure regard to the active module
        const disclosureSplitter = getDisclosureSplitter(frame) as EmailTemplateSplitter;

        if (disclosureSplitter && !currentModule?.moduleDisclosureStation) {
          handleDisclosureClean(disclosureSplitter, experienceIdNumeric);
          handleSetFrameHeight();
        } else if (disclosureSplitter && currentModule?.moduleDisclosureStation) {
          handleDisclosureClean(disclosureSplitter, experienceIdNumeric);

          await handleAddDisclosureHtml(disclosureSplitter, experienceIdNumeric, currentModule);

          const sortedDisclosures = sortDisclosures(
            splittersOrder,
            Array.from(disclosureSplitter.querySelectorAll(`[${DATA_EXPERIENCE_ID}]`)),
          );

          const disclosuresList = disclosureSplitter.querySelector(`[${DATA_DISCLOSURE_CONTENT_ATTRIBUTE}]`);
          const lastSplitterKey = getLastSplitterKey(frame);

          if (disclosuresList) {
            disclosuresList.innerHTML = sortedDisclosures.reduce((acc, disclosure) => acc + disclosure.outerHTML, '');
          } else if (lastSplitterKey) {
            handleAddDisclosureSplitter(lastSplitterKey, experienceIdNumeric, currentModule);
          }

          const sortedDisclosuresTitles = sortDisclosuresTitles(splittersOrder, disclosuresTitlesRef.current);
          setDisclosuresTitles(() => sortedDisclosuresTitles);

          handleSetFrameHeight();
        } else if (!disclosureSplitter && currentModule?.moduleDisclosureStation) {
          const lastSplitterKey = getLastSplitterKey(frame);

          if (lastSplitterKey && currentModule.moduleDisclosureStation?.disclosureCopy) {
            handleAddDisclosureSplitter(lastSplitterKey, experienceIdNumeric, currentModule);

            setDisclosuresTitles((prevState) => [
              ...prevState,
              {
                experienceId: experienceIdNumeric,
                name: currentModule.name,
              },
            ]);

            handleSetFrameHeight();
          }
        }
      },
      [
        chosenExperienceId,
        chosenModule,
        handleDisclosureClean,
        handleSetFrameHeight,
        handleAddDisclosureHtml,
        splittersOrder,
        setDisclosuresTitles,
        handleAddDisclosureSplitter,
      ],
    );

    const handleUpdateModule = useCallback(
      async (templateName?: string) => {
        // Check if a module is selected
        if (chosenModuleId) {
          // Get the current module's HTML and filled attribute value
          const { filledAttrValue } = module.current.getClearModuleHtml();
          const curModuleHtml = getClearModuleHtmlExternally('module-modal', chosenModuleElemId);
          const dcs = disclosureRef.current;

          // Remove element info with '-journey' suffix
          removeElemInfo('-journey');

          // Fetch the current module's information from the server
          const curModuleInfo = await dispatch(modules.thunk.getModuleInfo({ module: chosenModuleId }));
          const curModulePayload = curModuleInfo.payload as unknown as ExperienceItemMindset;

          // Initialize data visualization station for the current module
          let curModuleDataVisualizationStation = null as any;
          if (moduleDataVisualizationStation?.[chosenModuleId]) {
            curModuleDataVisualizationStation = {
              ...moduleDataVisualizationStation[chosenModuleId],
              id: (curModulePayload as any).moduleDataVisualizationStation?.id,
            };
          }

          // Clean up the module's HTML content
          const moduleHtml = curModuleHtml.moduleHtml
            .replaceAll(/\n/gm, '')
            .replaceAll('temphref', 'href')
            .replace(/\s+/g, ' ')
            .replace(
              `margin: 0px; border: 0.2rem solid ${variables.color.primary.mainPurple};`,
              'margin: 0px; border: initial;',
            );

          // Save the module's details
          let result: any = await dispatch(
            moduleDetails.thunk.save({
              details: {
                templateName,
                id: chosenModuleId,
                moduleHtml: moduleHtml,
              },
              isDirty: true,
            }),
          );

          // Handle module disclosure station if disclosure copy exists
          if (dcs?.disclosureCopy) {
            result = await dispatch(
              modules.thunk.replaceModuleDisclosureStation({
                module: chosenModuleId!,
                payload: {
                  moduleDisclosureStation: {
                    ...(result.payload.moduleDisclosureStation ?? curModulePayload.moduleDisclosureStation ?? {}),
                    ...dcs,
                    id:
                      result.payload.moduleDisclosureStation?.id ??
                      curModulePayload.moduleDisclosureStation?.id ??
                      dcs?.id,
                  },
                },
              }),
            );
          } else if (result.payload.moduleDisclosureStation && !dcs?.disclosureCopy) {
            // Remove module disclosure station if no disclosure copy exists
            result = await dispatch(
              modules.thunk.replaceModuleDisclosureStation({
                module: chosenModuleId!,
                payload: {
                  moduleDisclosureStation: null,
                },
              }),
            );

            // Handle disclosure removal
            const disclosureSplitter = getDisclosureSplitter(frame) as EmailTemplateSplitter;
            if (disclosureSplitter && chosenExperienceId) {
              handleDisclosureRemove(disclosureSplitter, chosenExperienceId);
            }
          }

          // Update the module in the frame document
          const updatedModule = result.payload;
          const frameDoc = frame.current?.contentDocument;

          if (frameDoc) {
            const freshNode = parse.node(curModuleHtml.moduleHtml, frameDoc);

            if (freshNode) {
              const originalNodeQuery = `*[${DATA_FILLED_ATTRIBUTE}='${filledAttrValue}']`;
              const originalNode = frameDoc.querySelector(originalNodeQuery);

              freshNode.setAttribute('draggable', 'true');

              originalNode?.firstElementChild?.replaceWith(freshNode);
            }
          }

          // Render disclosure updates
          await renderDisclosureUpdates(updatedModule);

          // Reset state variables and close the module editor
          setRightPanelElem(RightPanelElems.DEPLOYMENT_SETTINGS);
          setChosenLibrary(null);
          setIsModuleChanged(false);
          handleModuleClose();

          // Update the module in the Redux store
          dispatch(experience.actions.updateModule(updatedModule as unknown as ExperienceItemMindset));

          // Handle data visualization station updates
          if (curModuleDataVisualizationStation) {
            let settings =
              curModuleDataVisualizationStation.settings ?? updatedModule.moduleDataVisualizationStation?.settings;

            if (templateName && curModuleDataVisualizationStation.settings) {
              settings = updatedModule.moduleDataVisualizationStation?.settings.filter((setting: any) =>
                curModuleDataVisualizationStation.settings.find(
                  (settingItem: any) => settingItem.label === setting.label,
                ),
              ) as any[];

              settings = curModuleDataVisualizationStation.settings
                ? (settings ?? []).concat(
                    curModuleDataVisualizationStation.settings.filter((setting: any) => !setting.id),
                  )
                : settings ?? [];
            }

            await dispatch(
              modules.thunk.replaceModuleDataVisualizationStation({
                module: chosenModuleId,
                payload: {
                  moduleDataVisualizationStation: {
                    ...curModuleDataVisualizationStation,
                    id: updatedModule.moduleDataVisualizationStation?.id,
                    settings,
                  },
                },
              }),
            );
          } else if (!moduleDataVisualizationStation) {
            await dispatch(
              modules.thunk.replaceModuleDataVisualizationStation({
                module: chosenModuleId,
                payload: {
                  moduleDataVisualizationStation: null,
                },
              }),
            );
          }

          // Select the updated treatment and reset module HTML
          selectTreatment(updatedModule as unknown as ExperienceItemMindset);
          setChangedModuleHtml('');

          // Fetch the latest mindsets and update the template
          await dispatch(experience.thunk.getItemMindsets(Number(chosenExperienceId)));
          await handleTemplateUpdate();
          broadcaster.postMessage('refresh');
        }
      },
      // [
      //   chosenModuleId,
      //   chosenExperienceId,
      //   chosenModuleElemId,
      //   moduleDataVisualizationStation,
      //   dispatch,
      //   setRightPanelElem,
      //   setChosenLibrary,
      //   setIsModuleChanged,
      //   handleModuleClose,
      //   handleTemplateUpdate,
      //   renderDisclosureUpdates,
      // ]
      [
        chosenModuleId,
        chosenModuleElemId,
        dispatch,
        moduleDataVisualizationStation,
        renderDisclosureUpdates,
        setRightPanelElem,
        setChosenLibrary,
        setIsModuleChanged,
        handleModuleClose,
        selectTreatment,
        setChangedModuleHtml,
        chosenExperienceId,
        handleTemplateUpdate,
        handleDisclosureRemove,
      ],
    );

    const handleConfirmRenameModuleOnRemove = useCallback(
      async (data: RenameModuleModalFormValues, isDeleteTreatment) => {
        const treatments: ExperienceItemMindset[] = JSON.parse(JSON.stringify(groupedTreatments));
        const renamedTreatment = treatments.find((treatment) => treatment.id === chosenTreatmentId);

        handleRenameModuleModalClose();

        if (chosenTreatmentId && deploymentId && experienceData && typeof businessUnitId === 'number') {
          if (experienceData?.deployments && experienceData?.deployments.length < 2) {
            await dispatch(
              experience.thunk.update([
                prepareRenamePayload(
                  businessUnitId,
                  experienceData,
                  treatments,
                  {
                    id: deploymentId,
                  },
                  undefined,
                  data.name,
                ),
                chosenExperienceId ?? 0,
              ]),
            );
          } else {
            let newGroupedTreatments = treatments;
            if (isDeleteTreatment) {
              newGroupedTreatments = treatments.filter((treatment) => treatment.id !== chosenTreatmentId);
            }

            const createExperienceResult = await dispatch(
              experience.thunk.create(
                prepareRenamePayload(
                  businessUnitId,
                  experienceData,
                  newGroupedTreatments,
                  {
                    id: deploymentId,
                  },
                  { id: experienceData.id },
                  data.name,
                ),
              ),
            );

            if (experience.thunk.create.fulfilled.match(createExperienceResult)) {
              const payload = createExperienceResult.payload;
              const newExperienceId = payload?.id;

              if (newExperienceId) {
                const newMindsets = await dispatch(experience.thunk.getItemMindsets(newExperienceId));

                const splitterId = getExperienceElement(payload, String(chosenExperienceId));

                setNewExperienceCloned({
                  cloned: true,
                  splitterId: splitterId ?? null,
                  experienceId: newExperienceId,
                });

                setChosenExperienceId(newExperienceId);

                if (experience.thunk.getItemMindsets.fulfilled.match(newMindsets) && newMindsets.payload) {
                  const newModuleId = newMindsets.payload[0]?.id;

                  if (newModuleId) {
                    setChosenTreatmentId(newModuleId);
                  }
                }
              }
            }

            setShowRemoveWithRevisions(false);
          }
        }
      },
      [
        chosenTreatmentId,
        businessUnitId,
        deploymentId,
        dispatch,
        experienceData,
        chosenExperienceId,
        groupedTreatments,
        handleRenameModuleModalClose,
        setNewExperienceCloned,
        setChosenExperienceId,
        setChosenTreatmentId,
        setShowRemoveWithRevisions,
      ],
    );

    useEffect(() => {
      renderDisclosureUpdates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      mindsetsData,
      chosenModuleId,
      chosenExperienceId,
      handleAddDisclosureSplitter,
      handleDisclosureRemove,
      handleSetFrameHeight,
      handleAddDisclosureHtml,
      handleDisclosureClean,
    ]);

    const handleModuleSave = useCallback<ModuleHeaderProps['onSave']>(() => {
      if (chosenModuleDeps.length > 1 || chosenModule?.hasBeenUsedAroundTemplates) {
        setShowRenameModuleModal(true);
      } else {
        handleUpdateModule();
      }
    }, [chosenModule, chosenModuleDeps, handleUpdateModule, setShowRenameModuleModal]);

    const handleConfirmRenameModule = useCallback(
      async (nameData: RenameModuleModalFormValues) => {
        handleUpdateModule(nameData.name);
        handleRenameModuleModalClose();
      },
      [handleUpdateModule, handleRenameModuleModalClose],
    );

    useEffect(() => {
      if (newExperienceCloned.cloned && newExperienceCloned.splitterId && newExperienceCloned.experienceId) {
        // Deleting disclosure of cloned experience
        if (Number(chosenExperienceId) !== newExperienceCloned.experienceId) {
          const disclosureSplitter = getDisclosureSplitter(frame);

          if (disclosureSplitter) {
            handleDisclosureClean(disclosureSplitter as EmailTemplateSplitter, Number(chosenExperienceId));
          }
        }

        setRows((prevState) => {
          const keyToChange = Object.keys(prevState).find((key) => key === newExperienceCloned.splitterId) as string;

          return getNewRowsObject(prevState, keyToChange, newExperienceCloned.experienceId);
        });

        saveTemplate(deploymentEmailTemplates);
        setNewExperienceCloned({
          cloned: false,
          splitterId: null,
          experienceId: null,
        });
      }
    }, [
      splittersOrder,
      newExperienceCloned,
      deploymentEmailTemplates,
      setNewExperienceCloned,
      saveTemplate,
      chosenExperienceId,
      handleDisclosureClean,
    ]);

    const handleRemoveTreatment = useCallback(async () => {
      const cloneResult = await dispatch(modules.thunk.resetModuleTemplateContent(chosenTreatmentId!));

      if (modules.thunk.resetModuleTemplateContent.fulfilled.match(cloneResult)) {
        const moduleInfo = await dispatch(modules.thunk.getModuleInfo({ module: cloneResult.payload ?? 0 }));

        if (moduleInfo.payload) {
          const curModule = moduleInfo.payload as unknown as ExperienceItemMindset;

          dispatch(experience.actions.updateModule(curModule));

          selectTreatment(curModule);
          handleModuleClose();
        }
      }
    }, [chosenTreatmentId]);

    const handleRemoveTreatmentModalClose = useCallback(() => {
      if (
        chosenModuleDeps &&
        chosenModuleDeps.length > 1 &&
        experienceData?.type === 'revision' &&
        setShowRemoveWithRevisions
      ) {
        setShowRemoveWithRevisions(false);
      } else {
        setShowRemoveTreatmentModal(false);
      }
    }, [chosenModuleDeps, experienceData?.type, setShowRemoveTreatmentModal, setShowRemoveWithRevisions]);

    useEffect(
      () => {
        if (onModuleHtmlChange) {
          onModuleHtmlChange(module.current.html, module.current.getClearModuleHtml().moduleHtml);
        }
      },
      //[moduleHtmlElem, module.current]
      [moduleHtmlElem, onModuleHtmlChange, module.current.html, module.current.getClearModuleHtml],
    );

    useEffect(() => {
      cutModuleScrollBar();
    }, [chosenModuleId]);

    useEffect(() => {
      setTimeout(() => {
        cutModuleScrollBar();
      }, 100);
    }, [mindsetsData]);

    // const moduleDetailsMeta = memo(() => moduleDetails.useMeta(), []);

    // calculate iframe height
    // This appears to be a workaround for the iframe height calculation that doesn't work correctly.
    // The timeout is only triggered if the iframe contentDocument is available.
    // However, the contentDocument may only be available after this runs.
    // Using moduleDetails.useMeta() in the dependency array results in this code running
    // more times than necessary. The height seems to get calculated elsewhere without issue.
    /*
    useEffect(() => {
      const iframe = document.getElementById('template') as any;

      if (iframe?.contentDocument) {
        setTimeout(() => {
          iframe.style.height = `calc(${iframe.contentDocument?.body.getBoundingClientRect().height}px + 20px)`;
          //  iframe.style.height = '100%';
        }, 1000);
      }
    }, [
      //moduleDetails.useMeta(),
      //moduleDetailsMeta
    ]);

    */

    return (
      <Box id="sample-template" className={styles.sampleTemplate}>
        {!!moduleHtmlElem && <ModuleHeader dirty={isModuleChanged} onSave={handleModuleSave} />}
        <Box className={styles.content}>
          {status === 'success' && (
            <Box {...{ ref: frameWrapper }} className={styles.iframeWrapper} data-loaded={loaded}>
              <iframe
                id="template"
                srcDoc={redefinedDoc}
                onLoad={handleLoad}
                ref={frame}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                title="SampleTemplate"
                className={styles.iframe}
              />
            </Box>
          )}
        </Box>
        {!!moduleHtmlElem && (
          <ModuleModal
            modalTopPosition={modalTopPosition}
            moduleHtmlElem={changedModuleHtml ? changedModuleHtml : moduleHtmlElem}
            isEditorChanged={isEditorChanged}
            setIsEditorChanged={setIsEditorChanged}
            setRightPanelElem={setRightPanelElem}
            templateStyles={templateStyles}
            onChange={handleModuleChange}
            onClose={handleModuleClose}
            onRemove={handleRemoveTreatment}
          />
        )}
        {showRemoveExperienceModal && (
          <RemoveExperienceModal
            label={content.removeFromEmailTemplate}
            onClose={handleRemoveExperienceModalClose}
            onSubmit={handleRemoveExperienceConfirm}
          />
        )}
        {showRemoveWithRevisions && (
          <RenameModuleModal
            title={content.removeTreatment}
            alertMessage={content.thisModuleGroupUsedByAnotherDeployment}
            moduleData={{
              moduleTemplateName: `${topBarCtx.contentModuleName}`,
              deployments: chosenModuleDeps || [],
              isRenameRequired: experienceData?.type === 'revision',
            }}
            submitLabel={content.yesDelete}
            onClose={handleRemoveTreatmentModalClose}
            onSubmit={handleConfirmRenameModuleOnRemove}
            isDeleteTreatment
          />
        )}
        {showRenameModuleModal && (
          <RenameModuleModal
            title={textTemplate(content.renameValue, { value: content.contentModule })}
            alertMessage={content.itsNotPossibleRenameContentModule}
            moduleData={{
              moduleTemplateName: `${topBarCtx.contentModuleName}`,
              deployments: chosenModuleDeps,
              hasBeenUsedAroundTemplates: chosenModule?.hasBeenUsedAroundTemplates,
              isRenameRequired: experienceData?.type === 'revision',
            }}
            type="module"
            onClose={handleRenameModuleModalClose}
            onSubmit={handleConfirmRenameModule}
          />
        )}
      </Box>
    );
  },
);

SampleTemplate.displayName = 'SampleTemplate';
