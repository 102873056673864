import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    copyright: {
      // bottom: '0',
      // height: '2.4rem',
      // position: 'absolute',
      fontSize: '1.2rem',
      marginTop: 'auto',
      paddingTop: '1.2rem',
      textAlign: 'center',
      width: '100%',
    },
  }),
);
