import { FC, ReactElement, MouseEvent } from 'react';

import { format } from '@utils';
import { variables } from '@styles';
import { BarChartTooltip } from '@components';
import { BarSingleChartComponentProps } from './BarSingleChartComponent.props';

export const BarSingleChartComponent: FC<
  BarSingleChartComponentProps & {
    className?: string;
    highlightItem?: () => void;
    grayOutItem?: () => void;
  }
> = ({
  height,
  data,
  x,
  y,
  color,
  className = '',
  highlightItem,
  grayOutItem,
  onMouseEnter,
  showTooltip,
  onMouseLeave,
  hideTooltip,
}): ReactElement => {
  const tooltip = <BarChartTooltip indexValue={data.indexValue} value={data.value} unit="%" />;
  const handleTooltip = (event: MouseEvent<SVGElement>) => {
    if (highlightItem) {
      highlightItem();
    }
    showTooltip(tooltip, event);
  };
  const handleMouseEnter = (event: MouseEvent<SVGRectElement>) => {
    if (onMouseEnter) {
      onMouseEnter(data, event);
      if (highlightItem) {
        highlightItem();
      }
      showTooltip(tooltip, event);
    }
  };
  const handleMouseLeave = (e: MouseEvent<SVGRectElement>) => {
    if (onMouseLeave) {
      onMouseLeave(data, e);
      if (grayOutItem) {
        grayOutItem();
      }
      hideTooltip();
    }
  };

  const value = data.value ?? 0;

  return (
    <g transform={'translate('.concat(x.toString(), ', ').concat(y.toString(), ')')}>
      <rect
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        id={String(data.data.elementId)}
        className={className}
        width={value > 35 ? 400 : 11 * value}
        height={height}
        rx="4"
        ry="4"
        fill={color}
        strokeWidth="0"
        stroke={color}
        values={String(data.data.elementId)}
      />
      <text
        x={value > 35 ? 375 : 11 * value - 20}
        y="10.5"
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{
          fontSize: variables.font.size.xs,
          fontWeight: variables.font.weight.bold,
          lineHeight: variables.lineHeight.sm,
          letterSpacing: variables.letterSpacing.lg,
          pointerEvents: 'none',
        }}
      >
        {value > 35 && '> > >'}
        {value > 3 && value < 35 ? format.percent(value) : ''}
      </text>
    </g>
  );
};
