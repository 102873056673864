import { FC, ReactElement } from 'react';
import { Typography } from '@components';
import { format } from '@utils';

import { useTooltipStyle } from './BarChart.styles';
import { BarChartTooltipProps } from './BarChart.props';

export const BarChartTooltip: FC<BarChartTooltipProps> = ({ indexValue, value, unit }): ReactElement => {
  const styles = useTooltipStyle();

  const tooltipValue = value ?? undefined;
  return (
    <div className={styles.barChartTooltip}>
      <Typography.Caption className={styles.tooltipCaption}>{indexValue}</Typography.Caption>
      {unit === '%' ? format.percent(tooltipValue) : `${format.number(tooltipValue)} ${unit || ''}`}
    </div>
  );
};
