import { BaseSchema, Client, Program, Experience, Module, Template } from '@modules';

export enum DeploymentStatus {
  preDeployment = 'pre-deployment',
  inMarket = 'in-market',
  archived = 'archived',
  new = 'new',
  cloning = 'deployment_cloning',
  publishing = 'deployment_publishing',
  updating = 'deployment_updating',
}

export enum DeploymentMailfileStatus {
  uploading = 'mailfile_uploading',
  uploaded = 'mailfile_uploaded',
  uploadFailed = 'mailfile_upload_failed',
}

export enum DeploymentSubscribersStatus {
  loading = 'subscribers_loading',
  loaded = 'subscribers_loaded',
  loadFailed = 'subscribers_load_failed',
}

export enum SmartContentFileStatus {
  init = 'smart_content_file_status_init',
  generating = 'smart_content_file_status_generating',
  pending = 'smart_content_file_status_pending',
  processing = 'smart_content_file_status_processing',
  checking = 'smart_content_file_status_checking',
  completed = 'smart_content_file_status_completed',
  failed = 'smart_content_file_status_failed',
}

export enum DataVizGenerationStatus {
  init = 'not_generated',
  processing = 'generating',
  completed = 'generating_success',
  failed = 'generating_failed',
}

export type Deployment = DeploymentEntity & {
  isTechnical: boolean;
  createdAt: string;
  updatedAt: string;
  waveDate: string;
  smartContentFilePublishedBy: string | null;
  smartContentFilePath: string | null;
  mailShellFilePublishedBy: string | null;
  populationSize: number;
  appleMailReaderPercent: number;
  commonOpenRate: number;
  refinedOpenRate: number;
};

export type DeploymentFieldsMappingValue = {
  id?: number;
  value: string;
  description: string;
};

export type PublishStates = 'success' | 'ongoing' | 'error';

export type PublishSummary = {
  mailTemplatePublish: PublishStates;
  modulesPublish: PublishStates;
  smartFilePublish: PublishStates;
};

export type PublishedTemplate = {
  id: number;
  name: string;
};

export type PublishActions = {
  id: number;
  hasErrors: boolean;
  isCompleted: boolean;
  publishSummary: PublishSummary;
  createdAt: string;
  updatedAt: string;
  publishedTemplate: PublishedTemplate;
};

export type DeploymentPosition = {
  id: number | string;
  experience: Partial<Pick<Experience, 'id' | 'name'>> | null;
  order?: number;
  index?: number;
};

export type DeploymentPositions = DeploymentPosition[];

export type DeploymentEntity = {
  id: number;
  businessUnit: Pick<
    Client,
    | 'id'
    | 'espType'
    | 'isESPConfigured'
    | 'enableContentPublishing'
    | 'mailPlanTemplateFilePath'
    | 'mailPlanTemplateFileUploadedAt'
  >;
  status: DeploymentStatus;
  remoteDir?: string | null;
  smartContentFileOriginalName?: string | null;
  smartContentFileStatus: SmartContentFileStatus | null;
  smartContentFileProcessed: number;
  smartContentFileRowsCount: number;
  smartContentFilePublishedAt?: string | null;
  mailShellFilePublishedAt?: string | null;
  downloadFilesRequests: Array<any>;
  positions: DeploymentPositions;
  isMailFileUploading: boolean;
  marking:
    | ({
        [key in DeploymentMailfileStatus]?: number;
      } & {
        [key in DeploymentSubscribersStatus]?: number;
      })
    | null;
  program: Program;
  name: string;
  mailFile: {
    id: number;
    name: string;
  } | null;
  currentEmailTemplate: {
    id: number;
    name: string;
    isSelected: boolean;
    previewImage: string;
  };
  lastPublishDetails: PublishActions;
  subjectLine: string;
  preheader: string;
};

export type DeploymentEntityData = DeploymentEntity | null;

export type DataVizGenerationStatusPayload = {
  status: DataVizGenerationStatus;
  failedModules: string[];
};

export type PublishModulePayload = Pick<Module, 'id' | 'status' | 'tag' | 'name' | 'position'> & {
  cohort: string;
  product: string;
  moduleTemplateName: string;
  createdAt: number;
  publishedAt: number;
  publishedBy: string;
  updatedAt: number;
  updatedBy: string;
  experience: Pick<Experience, 'id' | 'name' | 'position'>;
  contentStatus: 'none' | 'html';
};

export type PublishModule = Omit<PublishModulePayload, 'tag'> & {
  tag: string;
  index: string;
};

export type Position = {
  experience: {
    id: number;
    modules: {
      id: number;
      moduleHtml: string;
    }[];
  };
};

export type InfoEmailTemplatePayload = {
  deploymentEmailTemplates: Template[] | null;
  positions: Position[];
};

export type DeploymentSearchParams = {
  businessUnit: string;
  name: string;
  paginated?: boolean;
};

export type AttachMailFileParams = {
  deploymentId: number;
  mailFileId: number;
};

export type SearchDeployment = {
  id: number;
  name: string;
  businessUnitId: number;
};

export type SearchDeploymentPayload = SearchDeployment[] | null;

export type DeploymentSchema = {
  entity: BaseSchema<DeploymentEntityData>;
  dataVizGenerationStatus: BaseSchema<DataVizGenerationStatusPayload>;
  modulesList: BaseSchema<PublishModule[]>;
  infoEmailTemplate: BaseSchema<InfoEmailTemplatePayload>;
  deploymentsList: BaseSchema<SearchDeploymentPayload>;
};
