import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Attribute component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    attribute: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: variables.color.primary.white,
      borderRadius: '1rem 0.4rem',
      padding: theme.spacing(1, 1.375),
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray} !important`,
      width: '24.4rem !important',
      height: '4.9rem !important',
      cursor: 'pointer',

      '&:hover': {
        borderColor: `${variables.color.primary.mainPurple} !important`,
        background: `${variables.color.primary.lighterPurple} !important`,
        color: `${variables.color.primary.mainPurple} !important`,
      },
      // '&:hover $text': {
      //   color: `${variables.color.primary.mainPurple} !important`,
      // },

      '&[data-chosen=true]': {
        border: `${variables.borderWidth.lg} solid ${variables.color.primary.mainPurple} !important`,
      },
    },
    leftContent: {
      display: 'flex',
      // alignItems: 'center',
      width: '100%',
    },
    content: {
      marginLeft: theme.spacing(1.375),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    contentWrapper: {
      width: '100%',
    },
    name: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 0 0.1rem',
      fontSize: variables.font.size.md,
      lineHeight: variables.lineHeight.md,
    },
    nameInput: {
      border: 'none',
      padding: 0,
      fontWeight: 'bold',
      background: 'none',
      outline: 'none',
    },
    saveBtn: {
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    imageContainer: {
      width: '3.1rem',
      height: '3.1rem',
      background: variables.color.primary.gray,
      borderRadius: '0.4rem',
      marginLeft: '1rem',
    },
    attributeIconContainer: {
      display: 'inline-block',
      paddingLeft: '1.0rem',
      verticalAlign: 'middle',
    },
    attributeIcon: {
      height: '16px',
      width: '16px',
    },
    pencilIcon: {
      width: '16px',
      height: '16px',
    },
    row: {
      display: 'flex',
    },
    inProgress: {
      '&&': {
        color: variables.color.primary.red,
        marginRight: variables.gap.xs,
      },
    },
    errorText: {
      color: variables.color.primary.red,
    },
    signalCapsule: {
      alignItems: 'center',
      backgroundColor: variables.color.primary.capsulePurple, // d8b5ff;
      border: '0 none',
      borderRadius: '2rem',
      color: variables.color.primary.black,
      cursor: 'default',
      display: 'flex',
      height: '2.4rem',
      // margin: 'auto 0',
      marginTop: '1rem',
      maxWidth: '27rem',
      minHeight: '2.4rem',
      overflow: 'hidden',
      padding: '0 1.5rem', //'.3rem 1.5rem',
      // pointerEvents: 'none',
      // width: '18rem',
      width: 'fit-content',
    },
    signalText: {
      margin: 'auto 0',
      overflow: 'hidden',
      // padding: '0 1.5rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      // '&:hover': {
      //   whiteSpace: 'normal',
      // },
    },
  }),
);

export const useTooltipStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: variables.color.primary.halfMediumGray,
    },
    tooltip: {
      backgroundColor: variables.color.primary.white,
      boxShadow: theme.shadows[8],
    },
    tooltipArrow: {
      backgroundColor: variables.color.primary.white,
      border: `0.1rem solid ${variables.color.primary.halfMediumGray}`,
      color: variables.color.primary.mediumGray,
      fontSize: '1.1rem',
    },
    tooltipPlacementBottom: {
      margin: '4px 0 0 0',
    },
  }),
);
