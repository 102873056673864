import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      // maxWidth: 'calc(100% - 23rem)',
      width: '100%',
    },
    inputRow: {
      display: 'flex',
      // alignItems: 'left',
      justifyContent: 'left',
      background: variables.color.primary.white,
      padding: '0 0 0 0',
    },
    form: {
      display: 'flex',
      gap: theme.spacing(2),
    },

    buttonContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-start',
      minWidth: '43.6rem',
      width: '43.6rem',
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        minWidth: '25.8rem',
        width: '25.8rem',
      },
    },
    button: {
      height: '3.3rem',
      margin: 'auto 0',
      minHeight: '3rem',
      minWidth: '10.5rem',
      padding: theme.spacing(1, 1),
      width: '10.5rem',
      // width: '4.8rem',
    },
    saveButton: {
      '&:disabled': {
        backgroundColor: variables.color.primary.halfMediumGray + ' !important',
        border: `0.1rem solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.lightGrayDarker,
      },
    },
    cancelButton: {
      backgroundColor: variables.color.primary.white,
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      color: variables.color.primary.darkestGray,

      '&:hover': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `1px solid ${variables.color.primary.mainPurple}`,
        color: variables.color.primary.mainPurple,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },
  }),
);
