import { Pie, ComputedDatum } from '@nivo/pie';

import { format } from '@utils';
import { variables } from '@styles';
import { generateColors } from '@components';

import {
  PieChartItem,
  PieChartProps,
  SymbolShapeProps,
  ChartPieChartData,
  ChartPieChartDataItem,
} from './PieChart.props';
import { PieChartTooltip } from './PieChartTooltip';

const CenteredMetric = ({
  centerX,
  centerY,
  total,
  centerText,
}: {
  centerX: number;
  centerY: number;
  total: number;
  centerText: string;
}) => (
  <g>
    <text
      x={centerX}
      y={centerY - 17}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '1.2rem',
        fontWeight: variables.font.weight.regular,
        fill: variables.color.secondary.gray,
        lineHeight: variables.lineHeight.sm,
      }}
    >
      {centerText}
    </text>
    <text
      x={centerX}
      y={centerY + 13}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '2.4rem',
        fill: variables.color.primary.gray,
        fontWeight: variables.font.weight.bold,
      }}
    >
      {format.number(total)}
    </text>
  </g>
);

const highlightItem = (dataEvent: { color: string; id: number | string }) => {
  const nodePieChart = document.getElementsByClassName('PieChart');
  if (nodePieChart) {
    Array.prototype.forEach.call(nodePieChart, (el) => {
      const nodes = el.getElementsByTagName('path');
      for (let item = 0; item < nodes.length; item += 1) {
        const node = nodes[item];
        if (node) {
          const fill = node.getAttribute('fill');
          if (dataEvent.color !== fill) {
            node.style.opacity = '0.5';
          }
        }
      }
    });
  }
  const nodeBarChart = document.getElementsByClassName('BarGroupedChart');
  if (nodeBarChart) {
    Array.prototype.forEach.call(nodeBarChart, (el) => {
      const nodes = el.getElementsByTagName('rect');
      for (let item = 0; item < nodes.length; item += 1) {
        const node = nodes[item];
        if (node) {
          const id = node.getAttribute('id');
          if (dataEvent.id !== id) {
            node.style.opacity = '0.5';
          }
        }
      }
    });
  }
};
const grayOutItem = () => {
  const nodePieChart = document.getElementsByClassName('PieChart');
  if (nodePieChart) {
    Array.prototype.forEach.call(nodePieChart, (el) => {
      const nodes = el.getElementsByTagName('path');
      for (let item = 0; item < nodes.length; item += 1) {
        const node = nodes[item];
        if (node) {
          node.style.opacity = '1';
        }
      }
    });
  }
  const nodeBarChart = document.getElementsByClassName('BarGroupedChart');
  Array.prototype.forEach.call(nodeBarChart, (el) => {
    const nodes = el.getElementsByTagName('rect');
    for (let item = 0; item < nodes.length; item += 1) {
      const node = nodes[item];
      if (node) {
        node.style.opacity = '1';
      }
    }
  });
};

const Title = ({ centerX, title }: { centerX: number; title: string }) => (
  <text
    x={centerX / 2.5}
    y={-25}
    style={{
      fontSize: variables.font.size.lg,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      letterSpacing: variables.letterSpacing.sm,
      fill: variables.color.primary.gray,
    }}
  >
    {title}
  </text>
);

const CustomSymbolShape = ({ x, y, fill, borderWidth, borderColor }: SymbolShapeProps) => (
  <rect
    x={x}
    y={y + 6}
    fill={fill}
    strokeWidth={borderWidth}
    stroke={borderColor}
    width={18}
    height={8}
    style={{ pointerEvents: 'none', borderRadius: 5 }}
    rx="4"
    ry="4"
  />
);

const assignDefaultValues = (item: PieChartItem): ChartPieChartDataItem => {
  const result: PieChartItem = { ...item };
  Object.keys(result).forEach((key) => {
    const typedKey = key as keyof PieChartItem;
    if (result[typedKey] == null || result[typedKey] == undefined) {
      if (typeof result[typedKey] === 'string') {
        result[typedKey] = '' as any;
      } else if (typeof result[typedKey] === 'number') {
        result[typedKey] = 0 as any;
      }
    }
  });
  return result as ChartPieChartDataItem;
};

export const PieChart = ({
  id,
  data,
  itemsWithColors,
  Tooltip,
  onClickInPosition,
  hasLegends,
  centerText,
  title,
  width,
  margin,
}: PieChartProps): JSX.Element => {
  const colors = generateColors(data.items.length);

  const getColor = (item: PieChartItem, index: number) => {
    if (itemsWithColors) {
      return itemsWithColors.find(({ elementId }) => elementId === item.elementId)?.color;
    }
    return colors[index];
  };

  const newData: ChartPieChartDataItem[] = data.items
    .filter((item) => item.value)
    .map((item, index) => ({
      ...assignDefaultValues(item),
      // data: { ...item },
      // ...item,
      color: getColor(item, index) ?? '',
    }));

  const newDataObj: ChartPieChartData = { data: newData as unknown as ComputedDatum<ChartPieChartDataItem>[] };

  return (
    <div className="PieChart">
      <Pie
        id={id || 'elementId'}
        width={width || 436}
        height={436}
        margin={
          margin || {
            top: 60,
            right: 30,
            bottom: 60,
            left: 30,
          }
        }
        data={newDataObj.data}
        innerRadius={0.7}
        padAngle={1}
        cornerRadius={3}
        enableArcLinkLabels={false}
        arcLabel={(d) => format.percent((d.value / data.total) * 100)}
        arcLabelsSkipAngle={10}
        sortByValue
        colors={{ datum: 'data.color' }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tooltip={(Tooltip as any) || PieChartTooltip}
        onMouseEnter={highlightItem}
        onMouseLeave={grayOutItem}
        onClick={(datum) => (onClickInPosition ? onClickInPosition(datum.label) : {})}
        theme={{
          labels: {
            text: {
              fontSize: variables.font.size.xs,
              fill: variables.color.primary.white,
              fontWeight: variables.font.weight.bold,
              lineHeight: variables.lineHeight.sm,
              letterSpacing: variables.letterSpacing.lg,
            },
          },
          tooltip: {
            container: {
              background: 'transparent',
              border: 0,
              boxShadow: 'none',
            },
          },
          legends: {
            text: {
              fontSize: variables.font.size.sm,
              fill: variables.color.primary.gray,
              fontWeight: variables.font.weight.regular,
              lineHeight: variables.lineHeight.sm,
              letterSpacing: variables.letterSpacing.sm,
            },
          },
        }}
        activeInnerRadiusOffset={16}
        layers={[
          'arcs',
          'arcLabels',
          'arcLinkLabels',
          'legends',
          (props) => <CenteredMetric {...props} total={data.total} centerText={centerText} />,
          (props) => (title ? <Title {...props} title={title} /> : null),
        ]}
        {...(hasLegends
          ? {
              legends: [
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 115,
                  translateY: 10,
                  itemsSpacing: 0,
                  itemWidth: 115,
                  itemHeight: 28,
                  itemOpacity: 1,
                  symbolSize: 21,
                  symbolShape: CustomSymbolShape as never,
                },
              ],
            }
          : {})}
      />
    </div>
  );
};
