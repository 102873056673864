import { useCallback, useState } from 'react';
import { SelectDataFormatProps } from './SelectDataFormat.props';
import { useStyles } from './SelectDataFormat.styles';
import { FORMAT_OPTIONS } from '../AddSignalDropdown.const';
import { Icon } from '@components';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { TPersonalizationToken } from '../AddSignalDropdown.props';

function SelectDataFormat({ signal, onClick }: SelectDataFormatProps): JSX.Element {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      const option = FORMAT_OPTIONS[index];
      const updatedSignal = { ...signal, format: option.id as unknown as TPersonalizationToken['format'] };
      setSelectedIndex(index);
      setAnchorEl(null);
      onClick(updatedSignal);
    },
    [onClick, signal],
  );

  const handleShowOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHideOptions = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleShowOptions} className={styles.openButton}>
        <Icon.ThreeDotsVertical />
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleHideOptions}
        getContentAnchorEl={null}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        PaperProps={{
          className: styles.menuList,
        }}
      >
        <MenuItem disabled={true}>Select a Format:</MenuItem>
        {FORMAT_OPTIONS.map((option, index) => (
          <MenuItem
            key={option.id as unknown as string}
            value={option.id as unknown as string}
            selected={index === selectedIndex}
            style={{
              borderBottom: option.hasBorder ? '1px solid #ccc' : 'none',
              borderRadius: option.hasBorder ? '0' : '0.6rem',
            }}
            // onClick={handleFormatChange(signal)}
            onClick={(event) => handleOnClick(event, index)}
          >
            {signal.format !== option.id ? (
              <Box className={styles.formatItem}>
                <span>{option.type}</span>
                <span>{option.output}</span>
              </Box>
            ) : (
              option.label
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default SelectDataFormat;
