import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DVAttribute component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    DVAttribute: {
      maxWidth: '245px',
    },
    addButton: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      marginLeft: '0.5rem',
      minWidth: '3.8rem',
      padding: theme.spacing(1, 1),
      width: '3.8rem',

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },

      '&:hover': {
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },

      '& svg path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:hover svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },
    },
    selectedAttribute: {
      textTransform: 'uppercase',
      color: variables.color.primary.darkestGray,
      textAlign: 'left',
      paddingLeft: '3px',
      fontSize: '12px',
    },
    menuList: {
      boxShadow: '1px 1px 7px #dadee2',
      marginLeft: '-10px',
      padding: '0 10px',
      width: '30rem',
    },
    search: {
      width: '100%',
      marginBottom: '10px',
    },
    signalsWrapper: {
      display: 'flex',
      gap: '5px',
    },
    signalItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '5px 20px 5px 10px',
      border: '1px solid #dadee2',
      borderRadius: '7px',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: 0,
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& svg': {
        stroke: variables.color.primary.darkestGray,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },
  }),
);
