import { memo, useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';

import { Link, Modal, Typography, WarningMessage } from '@components';
import { useAppDispatch } from '@store';
import { moduleDetails, articleLibrary, Article } from '@modules';
import { content } from '@content';
import { useStyles } from './ArticleLibrary.styles';
// import { createArticleRow } from './ArticleLibrary.utils';
import { ArticleLibraryProps } from './ArticleLibrary.types';

export const ArticleLibrary = memo(({ disabled }: ArticleLibraryProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const items = articleLibrary.useListData();
  const { status, error } = articleLibrary.useListMeta();

  const dispatch = useAppDispatch();

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => setOpen(false);

  const handleAddArticle = (article: Article) => () => {
    // DISABLED SINCE BEE EDITOR IS NOT USED
    // const articleRow = createArticleRow(article);

    // dispatch(moduleDetails.slice.actions.addArticle(articleRow));
    handleCloseModal();
  };

  useEffect(() => {
    dispatch(articleLibrary.thunk.getList());
  }, [dispatch]);

  return (
    <Box className={styles.articleLibrary}>
      <Button
        variant="contained"
        color="secondary"
        disabled={status === 'loading' || disabled}
        onClick={handleOpenModal}
      >
        {content.addArticle}
      </Button>
      <Modal open={open} onClose={handleCloseModal}>
        <Box className={styles.articleLibraryModal}>
          {error?.message && <WarningMessage message={error.message} />}
          <Box className={styles.articleLibraryList}>
            {items?.map((article) => (
              <Box key={article.id} className={styles.articleLibraryItem}>
                <img className={styles.articleLibraryItemImage} src={article.image_url} alt={article.title} />
                <Typography.Body className={styles.articleLibraryItemText}>
                  <Link to={article.article_url} isExternal target="blank">
                    {article.title}
                  </Link>
                </Typography.Body>
                <Box className={styles.articleLibraryItemActions}>
                  <Button variant="outlined" color="secondary" onClick={handleAddArticle(article)}>
                    {content.select}
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
});

ArticleLibrary.displayName = 'ArticleLibrary';
