/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState, SetStateAction } from 'react';

import { DATA_SPLITTER_ATTRIBUTE, RightPanelElems, TreatmentBuilderContext } from '@routes';
import { func } from '@utils';

import { DATA_FILLED_ATTRIBUTE, DOC_REDEFINE_DELAY, SPLITTER_LOADER_STYLES_ID } from '../SampleTemplate.const';
import { CurrentTarget, HTMLElementWithDocument, WrapperTagNames } from '../SampleTemplate.types';
import {
  elemMouseLeave,
  elemMouseEnter,
  elemClick,
  initializeElemStyleId,
  initializeLinkStyleId,
  getUniqueStyles,
  initializeDivStyleId,
  resetPrevElem,
  removeElemInfo,
  addElemInfo,
} from '../SampleTemplate.utils';

import { HighlightedElement, IEHTMLTextAreaElement, UseWrappModalHtmlElemsHook } from './ModuleModal.types';
import { Cursor, getHighlightedElems, pasteTextIntoTextarea, setCaretPosition, addWidths } from './ModuleModal.utils';
import { DEFAULT_HIGHLIGHTED_ELEMS, HIGHLIGHTED_ELEM_BORDER_WIDTH } from './ModuleModal.const';
import { ModuleModalClearModuleHtmlGetter, ModuleModalProps } from './ModuleModal.props';
import { ViewportSize, ViewportSizeContext, ViewportWidth } from '@layouts';
import { delay } from '@utils/func/func';

/**
 * ModuleModal hooks
 * @returns {Function}
 */

export const useWrappModalHtmlElems = (
  originalInnerHtml: string | undefined,
  isEditorChanged: boolean,
  documentElemId: string,
  templateStyles: HTMLCollectionOf<HTMLStyleElement> | null | undefined,
  setRightPanelElem: (value: SetStateAction<RightPanelElems>) => void,
  onChange: ModuleModalProps['onChange'],
): UseWrappModalHtmlElemsHook => {
  const [redefinedDoc, setRedefinedDoc] = useState('');
  const [highlightedElems, setHighlightedElems] = useState<HighlightedElement[]>(DEFAULT_HIGHLIGHTED_ELEMS);
  const [currentAnchorOffset, setCurrentAnchorOffset] = useState<number | null>(null);

  const wrapperRef = useRef(0);

  const {
    chosenElemId,
    chosenModuleElemId,
    cursorPosition,
    setCursorPosition,
    setChosenModuleElemId,
    setChosenElemStyles,
    setWysiwygContainerType,
    setIsModuleChanged,
    setElemInputActive,
  } = useContext(TreatmentBuilderContext);

  const { viewportSize } = useContext(ViewportSizeContext);

  // The following function appears to always return a string that matches the
  // input innerHTML string, so it is unclear what the purpose of this function is.
  const redefineHtml = useCallback(
    (innerHtml: string | undefined) => {
      // Create a new DOMParser instance
      const parser = new DOMParser();

      // Check if innerHtml is defined
      if (innerHtml) {
        // Parse the innerHtml string into a document
        const parsedDoc = parser.parseFromString(`<div class="initial-wrapper">${innerHtml}</div>`, 'text/html');

        // Get the contentDocument of the element with the specified documentElemId
        const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;

        // Check if the contentDocument has a body element
        if (contentDocument.body) {
          // Set the innerHTML of the contentDocument's body to the parsed innerHTML
          contentDocument.body.innerHTML =
            parsedDoc.documentElement.getElementsByClassName('initial-wrapper')[0].innerHTML;

          // Return the updated innerHTML of the contentDocument's body
          return contentDocument.body.innerHTML;
        }
      }

      // Return an empty string if innerHtml is not defined or if contentDocument's body is not found
      return '';
    },
    [documentElemId],
  );

  const handleElemMouseEnter = useCallback(
    (e: MouseEvent, elemId: string) => {
      if (chosenModuleElemId !== elemId) {
        // redefineElements();

        const prevHighlightedElem = highlightedElems[1];
        const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
        const chosenElem = contentDocument.getElementById(chosenModuleElemId);
        const redundantElems = Array.from(contentDocument.querySelectorAll(`div`)).filter(
          (item) =>
            item.id.includes('elem-info') &&
            item.id !== `elem-info-${chosenElem?.id}` &&
            item.id !== `elem-info-${chosenElem?.id}__content`,
        );

        redundantElems.forEach((elem) => elem.remove());
        const hoveredElems = contentDocument.querySelectorAll('[hovered="true"]');
        hoveredElems.forEach((element: any) => {
          if (element.id !== chosenModuleElemId) {
            element.style.borderColor = 'transparent';
            element.removeAttribute('hovered');
          }
        });
        elemMouseEnter(e, elemId, documentElemId, false);

        if (prevHighlightedElem?.id !== elemId) {
          setHighlightedElems((prevState) => getHighlightedElems(e, elemId, prevState));
        }
      }
    },
    [chosenModuleElemId, documentElemId, highlightedElems],
  );

  const handleElemMouseLeave = useCallback(
    (e: MouseEvent, elemId: string) => {
      if (chosenModuleElemId !== elemId) {
        elemMouseLeave(e);
      }
    },
    [chosenModuleElemId],
  );

  const handleElemClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      let id = (e.target as any).id;
      const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
      let element = contentDocument.getElementById(id);

      if ((e.target as any)?.tagName === 'TD') {
        element = e.target as HTMLElement;
        do {
          element = element?.parentElement ?? null;
        } while (!['DIV', 'BODY'].includes(element?.tagName ?? ''));

        id = element?.id;
        element?.setAttribute('hovered', 'true');
      }

      const selectedElems = contentDocument.querySelectorAll('[style*="border-color: #6B11C9"]');
      const selectedBorderElems = contentDocument.querySelectorAll('[style*="border: 2px solid rgb(107, 17, 201)"]');
      const elems: any = Array.from(selectedElems)
        .concat(Array.from(selectedBorderElems))
        .filter((elem: any) => elem.id !== id && !elem.id.includes('journey'));
      const prevElemId = elems[0]?.id ?? highlightedElems[1]?.id ?? '';

      // const target = e.currentTarget as any;

      // addElemInfo(target, id, documentElemId);

      if (element?.getAttribute('hovered') === 'true' && e) {
        const styles = element?.getAttribute('style');
        const elemStyles = getUniqueStyles(styles ?? '');
        const selection = contentDocument.getSelection();

        setChosenElemStyles(elemStyles);
        elemClick(e, id, documentElemId, prevElemId, false, setChosenModuleElemId, undefined);
        setCurrentAnchorOffset(selection?.anchorOffset !== undefined ? selection?.anchorOffset : null);

        if (id.includes('button')) {
          setRightPanelElem(RightPanelElems.WYSIWYG_UNIVERSAL);
          setWysiwygContainerType('button');
        }

        if (['p-', 'h1-', 'span-', 'strong-'].some((el) => id.includes(el))) {
          setRightPanelElem(RightPanelElems.WYSIWYG_UNIVERSAL);
          setWysiwygContainerType('text');
        }
        if (id.includes('img-') && element?.getAttribute('data-visualization-station') === 'true') {
          setRightPanelElem(RightPanelElems.WYSIWYG_DATA_VISUAL);
        } else if (id.includes('img-')) {
          setRightPanelElem(RightPanelElems.WYSIWYG_IMAGE);
        }
        if (id.includes('div-')) {
          setRightPanelElem(RightPanelElems.WYSIWYG_DIV);
        }
      }
    },
    [
      documentElemId,
      highlightedElems,
      setChosenElemStyles,
      setChosenModuleElemId,
      setRightPanelElem,
      setWysiwygContainerType,
    ],
  );

  //  TODO: finish logic for DIV wrapper
  const redefineDivElemMethods = useCallback(
    (contentDocument: Document) => {
      const divs = Array.from(contentDocument.querySelectorAll('div'));

      divs
        .filter((item) => item.id.includes('div'))
        .forEach((elem, index) => {
          elem.onmouseover = (e) => handleElemMouseEnter(e, `div-${index}`);
          elem.onmouseout = (e) => handleElemMouseLeave(e, `div-${index}`);
          elem.onclick = (e) => handleElemClick(e);
        });
    },
    [handleElemClick, handleElemMouseEnter, handleElemMouseLeave],
  );

  const redefineLinkElemMethods = useCallback(
    (contentDocument: Document) => {
      const elems = contentDocument.querySelectorAll('button');

      // Array.from(elems)
      //   .filter((item) => item.id.includes('a'))
      //   .forEach((elem, index) => {
      //     elem.onmouseover = (e) => handleElemMouseEnter(e, `a-${index}`);
      //     elem.onmouseout = (e) => handleElemMouseLeave(e, `a-${index}`);
      //     elem.onclick = (e) => handleElemClick(e);
      //   });

      Array.from(elems)
        .filter((item) => item.id.includes('button'))
        .forEach((elem, index) => {
          elem.onmouseover = (e) => handleElemMouseEnter(e, `button-${index}`);
          elem.onmouseout = (e) => handleElemMouseLeave(e, `button-${index}`);
          elem.onclick = (e) => handleElemClick(e);
        });
    },
    [handleElemClick, handleElemMouseEnter, handleElemMouseLeave],
  );

  const handleElemMouseMove = useCallback(
    (e: MouseEvent, elemId: string) => {
      if ((e.target as CurrentTarget).id === elemId) {
        handleElemMouseEnter(e, elemId);
      }
    },
    [handleElemMouseEnter],
  );

  const getClearModuleHtml = useCallback<ModuleModalClearModuleHtmlGetter>(() => {
    resetPrevElem('', documentElemId, chosenModuleElemId, func.nop); // clear selection
    removeElemInfo('', documentElemId); // clear hover

    const moduleFrame = document.getElementById(documentElemId) as HTMLIFrameElement | null;
    const innerDoc = moduleFrame?.contentDocument || moduleFrame?.contentWindow?.document;
    const moduleElement = innerDoc?.querySelector(`[${DATA_SPLITTER_ATTRIBUTE}]`);

    return {
      moduleHtml: moduleElement?.innerHTML || innerDoc?.body?.innerHTML || '',
      filledAttrValue: moduleElement?.getAttribute(DATA_FILLED_ATTRIBUTE) || '',
    };
  }, [documentElemId, chosenModuleElemId]);

  const handleElemDoubleCLick = useCallback(
    (e: MouseEvent, elemId: string, contentDocument: Document) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
      const textElem = contentDocument.getElementById(elemId);

      const handleBlur = async (event: FocusEvent) => {
        const parser = new DOMParser();
        const textAreaElemTarget = event.target as any;
        const originalElem = textAreaElemTarget.getAttribute('original-element');
        const parsedOriginalElem = parser.parseFromString(String(originalElem), 'text/html').getElementById(elemId);
        const elemInfos = Array.from(contentDocument.querySelectorAll(`div`)).filter((item) =>
          item.id.includes('elem-info'),
        );

        if (checkIfSignalBtnClicked(textAreaElemTarget)) {
          return;
        }

        const currentElemInfo = elemInfos.find((item) => item.id === `elem-info-${chosenModuleElemId}`);

        if (currentElemInfo) {
          parsedOriginalElem?.setAttribute('elem-info', currentElemInfo.outerHTML);
        }

        if (parsedOriginalElem) {
          elemInfos.forEach((elem) => elem.remove());
          parsedOriginalElem.innerHTML =
            textAreaElemTarget?.innerHTML.replaceAll('color: transparent;', 'color: #000;') ?? '';
          parsedOriginalElem.setAttribute('originalinnerhtml', textAreaElemTarget.innerHTML);
          textAreaElemTarget.replaceWith(parsedOriginalElem);
          parsedOriginalElem.onmouseover = (ev) => handleElemMouseMove(ev, elemId);
          parsedOriginalElem.onmouseout = (ev) => handleElemMouseLeave(ev, elemId);
          parsedOriginalElem.ondblclick = (ev) => handleElemDoubleCLick(ev, elemId, contentDocument);
          parsedOriginalElem.onclick = (ev) => handleElemClick(ev);
          parsedOriginalElem.style.whiteSpace = 'normal';
          parsedOriginalElem.style.borderColor = 'transparent';
          parsedOriginalElem.removeAttribute('hovered');
          const tokens = parsedOriginalElem.querySelectorAll('personefy-token');
          tokens.forEach((token) => {
            token.removeAttribute('initialvalue');
          });
          //TODO: remove elemInfo from contentDocument.body.innerHTML
          setElemInputActive('completed');
        }

        onChange(contentDocument.body.innerHTML, initRedefinedDocRef.current !== redefinedDoc, getClearModuleHtml);
      };

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          const input = event.target as HTMLInputElement;

          if (event.shiftKey && event.type == 'keydown') {
            pasteTextIntoTextarea(input, '\n');
          }

          setIsModuleChanged(true);
          input.blur();
        }
      };

      if (textElem) {
        const textArea = contentDocument.createElement('pre');
        const textElemStyles = textElem?.getAttribute('style')?.split(';').excludeStrs(['cursor']).join(';\n');
        textArea.innerHTML = textElem.innerHTML.replaceAll(/\n/gm, '').replace(/\s+/g, ' ') || '';
        textArea.setAttribute(
          'style',
          getUniqueStyles(`
          ${textElemStyles}
          outline: 1px solid black;
          line-height: normal;
          overflow: auto;
          resize: none;
          z-index: 100;
          white-space: pre-wrap;
        `),
        );

        setCursorPosition(0);
        setElemInputActive('enabled');
        setChosenModuleElemId(elemId);

        const parentElement = textElem.parentElement;

        let fontSize = textElem.style.fontSize;
        let fontFamily = textElem.style.fontFamily;
        let fontWeight = textElem.style.fontWeight;
        let color = textElem.style.color;
        let lineHeight = textElem.style.lineHeight;
        let textTransform = textElem.style.textTransform;

        // get parent element styles if set and element has no value
        if (parentElement) {
          fontSize = fontSize || parentElement.style.fontSize;
          fontFamily = fontFamily || parentElement.style.fontFamily;
          fontWeight = fontWeight || parentElement.style.fontWeight;
          color = color || parentElement.style.color;
          lineHeight = lineHeight || parentElement.style.lineHeight;
          textTransform = textTransform || parentElement.style.textTransform;
        }

        textArea.setAttribute('original-element', textElem.outerHTML);
        textArea.setAttribute('id', elemId);
        textArea.setAttribute('contenteditable', 'true');
        textArea.style.background = 'transparent';
        textArea.style.fontSize = fontSize || 'inherit';
        textArea.style.fontFamily = fontFamily || 'inherit';
        textArea.style.fontWeight = fontWeight || 'inherit';
        textArea.style.color = color || 'inherit';
        textArea.style.lineHeight = lineHeight || 'inherit';
        textArea.style.textTransform = textTransform || 'inherit';
        textArea.onblur = (event) => handleBlur(event);
        textArea.onkeydown = (event) => handleKeyDown(event);
        textArea.onkeyup = (event) => {
          setCursorPosition(Cursor.getCurrentCursorPosition(event.target as any));
        };
        textArea.oninput = (event) => {
          setIsModuleChanged(true);
          setCursorPosition(Cursor.getCurrentCursorPosition(event.target as any));
        };
        textArea.onclick = (event) => {
          event.preventDefault();
          event.stopPropagation();
          setCursorPosition(Cursor.getCurrentCursorPosition(event.target as any));
        };

        // highlightsContainer.appendChild(textArea);

        textElem.replaceWith(textArea);

        const tokens = textArea.querySelectorAll('personefy-token');
        Array.from(tokens).forEach((token: any) => {
          token.setAttribute('initialvalue', token.innerText);
          token.addEventListener(
            'DOMSubtreeModified',
            (event: any) => {
              if (event.target.parentElement && event.target.parentElement.getAttribute('initialValue')) {
                event.target.parentElement.innerHTML = event.target.parentElement.getAttribute('initialValue');
              }
            },
            false,
          );
        });
        setCaretPosition(textArea as any, currentAnchorOffset);
        textArea.focus();
      }
    },
    [
      chosenModuleElemId,
      currentAnchorOffset,
      getClearModuleHtml,
      handleElemClick,
      handleElemMouseLeave,
      handleElemMouseMove,
      onChange,
      redefinedDoc,
      setChosenModuleElemId,
      setCursorPosition,
      setElemInputActive,
      setIsModuleChanged,
    ],
  );

  const handleSelectionChange = useCallback((e: Event, contentDocument: Document) => {
    return { e, contentDocument };
    //TODO: finished logic for handleSelectionChange;
    // const selection = contentDocument.getSelection();
    // const anchorElemId = selection?.anchorNode?.parentElement?.id;
    // const focusElemId = selection?.focusNode?.parentElement?.id;
    // const selectedElem = contentDocument.getElementById(String(anchorElemId));
    // const { anchorOffset, focusOffset } = selection as Selection;
    // if (selectedElem) {
    //   const trimmedElemInnerHTML = selectedElem?.innerHTML.replaceAll('\n', '').trim();
    //   const selectedText = trimmedElemInnerHTML.slice(anchorOffset, focusOffset);
    //   const textBefore = trimmedElemInnerHTML.slice(0, anchorOffset);
    //   const textAfter = trimmedElemInnerHTML.slice(
    //     focusOffset,
    //     (selection?.anchorNode as unknown as string).length,
    //   );
    //   selectedElem.innerHTML = `${textBefore}<span>${selectedText}</span>${textAfter}`;
    // }
    // if (selectedElem) {
    //   const range = new Range();
    //   range.setStart(contentDocument, anchorOffset);
    //   range.setEnd(contentDocument, focusOffset);
    // }
  }, []);

  const redefineElemMethods = useCallback(
    (contentDocument: Document, tagName: WrapperTagNames) => {
      const elems = contentDocument.querySelectorAll(tagName);

      Array.from(elems)
        // .filter((item) => item.id.includes(tagName))
        .forEach((elem, index) => {
          elem.onmouseover = (e) => handleElemMouseEnter(e, `${tagName}-${index}`);
          elem.onmouseout = (e) => handleElemMouseLeave(e, `${tagName}-${index}`);
          elem.onclick = (e) => handleElemClick(e);

          if (tagName === 'p' || tagName === 'h1' || tagName === 'span' || tagName === 'strong') {
            elem.ondblclick = (e) => handleElemDoubleCLick(e, `${tagName}-${index}`, contentDocument);
            contentDocument.onselectionchange = (e) => handleSelectionChange(e, contentDocument);

            const elemInfo = elem.getAttribute('elem-info');

            if (elemInfo) {
              const parser = new DOMParser();
              const parsedElemInfo = parser.parseFromString(elemInfo, 'text/html').getElementsByTagName('div')[0];

              elem.after(parsedElemInfo);
              elem.removeAttribute('elem-info');
            }
          }
        });
    },
    [handleElemClick, handleElemDoubleCLick, handleElemMouseEnter, handleElemMouseLeave, handleSelectionChange],
  );

  const redefineElements = useCallback(() => {
    const iframe = document.getElementById(documentElemId) as HTMLElementWithDocument;
    const contentDocument = iframe.contentDocument;

    // Redefine methods for various elements within the iframe's content document
    redefineElemMethods(contentDocument, 'h1');
    redefineElemMethods(contentDocument, 'img');
    redefineElemMethods(contentDocument, 'strong');
    redefineElemMethods(contentDocument, 'span');
    redefineElemMethods(contentDocument, 'p');
    redefineLinkElemMethods(contentDocument);
    redefineDivElemMethods(contentDocument);

    // Set the iframe height to match the height of its body content
    if (iframe.style.height !== `${iframe.contentDocument.body.getBoundingClientRect().height}px`) {
      iframe.style.height = `${iframe.contentDocument.body.getBoundingClientRect().height}px`;
    }
  }, [documentElemId, redefineDivElemMethods, redefineElemMethods, redefineLinkElemMethods]);

  useEffect(() => {
    const prevHighlightedElem = highlightedElems[1];

    if (prevHighlightedElem?.event) {
      handleElemMouseLeave(prevHighlightedElem.event, prevHighlightedElem.id);
    }
  }, [handleElemMouseLeave, highlightedElems]);

  const checkIfSignalBtnClicked = (textAreaElemTarget: any) => {
    const addSignalBtn = document.getElementById('add-signal-btn');
    const signalItemsCollection = document.getElementsByClassName('signal-item');

    if (addSignalBtn) {
      const rects = addSignalBtn.getBoundingClientRect();
      const mouseX = (window as any).currentMouseX;
      const mouseY = (window as any).currentMouseY;
      const signalItems = Array.from(signalItemsCollection);
      const condition = signalItems.reduce((acc, item) => {
        const itemRects = item.getBoundingClientRect();

        return (
          acc ||
          (mouseX >= itemRects.left &&
            mouseY <= itemRects.right &&
            mouseY >= itemRects.top &&
            mouseY <= itemRects.bottom)
        );
      }, false);

      if (
        condition ||
        (mouseX >= rects.left && mouseY <= rects.right && mouseY >= rects.top && mouseY <= rects.bottom)
      ) {
        textAreaElemTarget.focus();

        return true;
      }
    }

    return false;
  };

  // useEffect hook to monitor changes in originalInnerHtml, redefinedDoc, isEditorChanged, and redefineHtml
  useEffect(() => {
    // Redefine the HTML by replacing certain characters and attributes
    const redefinedHtml = redefineHtml(originalInnerHtml).replaceAll('&amp;', `&`).replaceAll('href', 'temphref');

    // Check if redefinedDoc is not set or if it has changed and the editor has been modified
    if (!redefinedDoc || (redefinedDoc !== redefinedHtml && isEditorChanged)) {
      // Update the redefinedDoc state with the new redefined HTML
      setRedefinedDoc(redefinedHtml);
    }
  }, [originalInnerHtml, redefinedDoc, isEditorChanged, redefineHtml]);

  const onIframeLoad = useCallback(
    (cb: () => void, elemId: string) => {
      const iframe = document.getElementById('module-modal') as HTMLElementWithDocument;

      iframe.onload = async () => {
        const blockGrid = iframe.contentDocument.querySelector('.block-grid') as HTMLElement;
        const iframeBody = iframe.contentDocument.body;

        const iframeHead = iframe.contentDocument.head;
        if (templateStyles && iframeHead) {
          // clone and append nodes to maintain the styles in the iframe
          Array.from(templateStyles).forEach((style: HTMLStyleElement) => {
            if (style.id !== SPLITTER_LOADER_STYLES_ID) {
              iframeHead.appendChild(style.cloneNode(true));
            }
          });
        }

        iframe.style.height = `${iframe.contentDocument.body.getBoundingClientRect().height}px`;

        if (blockGrid) {
          blockGrid.style.display = 'block';
          blockGrid.style.overflow = 'inherit';
          blockGrid.removeAttribute('transformed');

          if (iframe.parentElement && blockGrid.parentElement) {
            // iframe.parentElement.style.width = `${blockGrid.children[0].getBoundingClientRect().width + 100}px`;
            blockGrid.parentElement.style.overflow = 'hidden';
          }

          const blockGrid1 = iframe.contentDocument.querySelector('.block-grid') as HTMLElement;

          if (blockGrid1) {
            blockGrid1.style.maxWidth = `${blockGrid1.getBoundingClientRect().width + 50}px`;
          }
        }

        if (iframeBody) {
          (iframeBody as HTMLBodyElement).style.boxSizing = 'border-box';
          iframeBody.style.margin = '0px';
          iframeBody.style.border = '0.2rem solid #6B11C9';
          iframeBody.style.borderRadius = '0.2rem';
          iframeBody.style.overflow = 'hidden';
          (iframeBody.children[0] as any).style.border = 'none';

          const contentTable = iframe?.contentDocument?.querySelectorAll('table')[1];
          const parentTable = iframe?.contentDocument.querySelectorAll('table')[0];

          const child = blockGrid?.children[0];
          const childWidth = child?.getBoundingClientRect().width;
          const moduleContent = document.querySelector('#module-content') as HTMLDivElement;

          const contentDocument = (document.getElementById('template') as HTMLElementWithDocument)?.contentDocument;
          const curModuleInTemplate = contentDocument?.getElementById(elemId);

          // set the iframe width
          if (moduleContent && curModuleInTemplate) {
            if (viewportSize === ViewportSize.Mobile) {
              const moduleWidth = addWidths(
                [ViewportWidth.Mobile, HIGHLIGHTED_ELEM_BORDER_WIDTH, HIGHLIGHTED_ELEM_BORDER_WIDTH],
                'px',
              );
              moduleContent.style.minWidth = moduleWidth;
              moduleContent.style.width = moduleWidth;
            } else {
              const moduleWidth = addWidths(
                [ViewportWidth.Desktop, HIGHLIGHTED_ELEM_BORDER_WIDTH, HIGHLIGHTED_ELEM_BORDER_WIDTH],
                'px',
              );
              moduleContent.style.minWidth = moduleWidth; // `${curModuleInTemplate?.getBoundingClientRect().width ?? 0}px`;
              moduleContent.style.width = moduleWidth; // `${curModuleInTemplate?.getBoundingClientRect().width ?? 0}px`;
            }

            iframe.style.height = `${iframe.contentDocument.body.getBoundingClientRect().height}px`;
          } else if (blockGrid && child) {
            const curTemplateWidth = childWidth;

            if (moduleContent) {
              moduleContent.style.minWidth = `${curTemplateWidth + 10}px`;
            }

            return;
          } else if (
            !contentTable ||
            (contentTable.parentElement?.tagName === 'BODY' && parentTable?.getBoundingClientRect().height > 1)
          ) {
            const curTemplateWidth = parentTable?.getBoundingClientRect().width;

            if (moduleContent && curTemplateWidth) {
              moduleContent.style.minWidth = `${curTemplateWidth + 6}px`;
            }

            return;
          } else if (moduleContent) {
            // const curTemplateWidth = contentTable?.getBoundingClientRect().width;
            // moduleContent.style.minWidth = `${curTemplateWidth + 6}px`;

            // if displaying a module without a template, default to desktop width
            const moduleWidth = addWidths(
              [ViewportWidth.Desktop, HIGHLIGHTED_ELEM_BORDER_WIDTH, HIGHLIGHTED_ELEM_BORDER_WIDTH],
              'px',
            );
            moduleContent.style.minWidth = moduleWidth; // `${curModuleInTemplate?.getBoundingClientRect().width ?? 0}px`;
            moduleContent.style.width = moduleWidth;

            // reset the iframe height
            iframe.style.height = `${iframe.contentDocument.body.getBoundingClientRect().height}px`;
          }
        }

        cb();

        iframe.onload = () => {
          const iframeBodyNew = iframe.contentDocument.body;

          if (iframeBodyNew) {
            iframeBodyNew.style.margin = '0px';
            iframeBodyNew.style.border = '0.2rem solid #6B11C9';
            iframeBodyNew.style.borderRadius = '0.2rem';
            (iframeBody.children[0] as any).style.border = 'none';
          }
        };
      };
    },
    [templateStyles, viewportSize],
  );

  useEffect(() => {
    onIframeLoad(async () => {
      const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;

      if (redefinedDoc) {
        //const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;

        contentDocument.getElementById(chosenElemId)?.removeAttribute('draggable');
        initializeElemStyleId(contentDocument, 'img');
        initializeElemStyleId(contentDocument, 'h1');
        initializeElemStyleId(contentDocument, 'strong');
        initializeElemStyleId(contentDocument, 'span');
        initializeElemStyleId(contentDocument, 'p');
        initializeElemStyleId(contentDocument, 'button');
        initializeLinkStyleId(contentDocument);
        initializeDivStyleId(contentDocument);

        redefineElements();
        setChosenModuleElemId('a');

        await delay(500);

        redefineElements();
        setChosenModuleElemId('');
      }
    }, chosenElemId);
  }, [
    redefinedDoc,
    redefineHtml,
    documentElemId,
    chosenElemId,
    handleElemClick,
    handleElemDoubleCLick,
    handleElemMouseEnter,
    handleElemMouseLeave,
    handleSelectionChange,
    chosenModuleElemId,
    onIframeLoad,
    redefineElements,
    setChosenModuleElemId,
  ]);

  useEffect(() => {
    wrapperRef.current = window.setTimeout(async () => {
      if (redefinedDoc) {
        const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;

        await delay(300);
        redefineElements();
        await delay(500);

        const elems = contentDocument.querySelectorAll('h1, p, img, strong, span');

        elems.forEach((textElem: any) => {
          textElem.onmouseover = (e: MouseEvent) => handleElemMouseEnter(e, textElem.id);
          textElem.onmouseout = (e: MouseEvent) => handleElemMouseLeave(e, textElem.id);
          textElem.onclick = (e: MouseEvent) => handleElemClick(e);
        });

        const doubleClickableElems = contentDocument.querySelectorAll('h1, p, button, strong, span');

        doubleClickableElems.forEach((textElem: any) => {
          textElem.ondblclick = (e: MouseEvent) => handleElemDoubleCLick(e, textElem.id, contentDocument);
        });
      }
    });

    return () => {
      window.clearTimeout(wrapperRef.current);
    };
  }, [
    redefinedDoc,
    handleElemMouseEnter,
    handleElemMouseLeave,
    handleElemClick,
    handleElemDoubleCLick,
    redefineElemMethods,
    redefineLinkElemMethods,
    documentElemId,
    chosenModuleElemId,
    highlightedElems,
    redefineElements,
    redefineDivElemMethods,
  ]);

  const initRedefinedDocRef = useRef('');
  const prevRedefinedDocRef = useRef('');

  useLayoutEffect(() => {
    // const prevRedefinedDoc = prevRedefinedDocRef.current;

    // if (prevRedefinedDoc && prevRedefinedDoc !== redefinedDoc) {
    prevRedefinedDocRef.current = redefinedDoc;

    onChange(redefinedDoc, initRedefinedDocRef.current !== redefinedDoc, getClearModuleHtml);
    // }
  }, [onChange, getClearModuleHtml, redefinedDoc, documentElemId]);

  useLayoutEffect(() => {
    if (!initRedefinedDocRef.current && redefinedDoc) {
      initRedefinedDocRef.current = redefinedDoc;
      prevRedefinedDocRef.current = redefinedDoc;
    }
  }, [redefinedDoc]);

  return {
    redefinedDoc,
  };
};

export const getClearModuleHtmlExternally = (docElemId: string, chosenElemId: string) => {
  resetPrevElem('', docElemId, chosenElemId, func.nop); // clear selection
  removeElemInfo('', docElemId); // clear hover

  const moduleFrame = document.getElementById(docElemId) as HTMLIFrameElement | null;
  const redundantElems = Array.from(moduleFrame?.contentDocument?.querySelectorAll(`div`) ?? []).filter((item) =>
    item.id.includes('elem-info'),
  );

  redundantElems.forEach((elem) => elem.remove());

  const hoveredElems = moduleFrame?.contentDocument?.querySelectorAll('[hovered="true"]') ?? [];

  hoveredElems.forEach((element: any) => {
    element.style.borderColor = 'transparent';
    element.removeAttribute('hovered');
  });

  const selectedElems = moduleFrame?.contentDocument?.querySelectorAll('[style*="border-color: #6B11C9"]');
  const selectedBorderElems = moduleFrame?.contentDocument?.querySelectorAll(
    '[style*="border: 2px solid rgb(107, 17, 201)"]',
  );
  const elems: any[] = Array.from(selectedElems ?? []).concat(Array.from(selectedBorderElems ?? []));

  elems.forEach((element: any) => {
    element.style.borderColor = 'transparent';
    element.removeAttribute('hovered');
  });

  const redundantPrevElems = moduleFrame?.contentDocument?.querySelectorAll(`div[style*="position: absolute;"]`) ?? [];

  redundantPrevElems.forEach((elem) => elem.remove());

  const innerDoc = moduleFrame?.contentDocument || moduleFrame?.contentWindow?.document;
  const moduleElement = innerDoc?.querySelector(`[${DATA_SPLITTER_ATTRIBUTE}]`);

  const textAreas = moduleFrame?.contentDocument?.querySelectorAll('textarea');

  textAreas?.forEach((textArea) => {
    const textAreaHtml = textArea.innerHTML;
    const parentElement = textArea.parentElement;

    if (parentElement) {
      const placeholder = document.createElement('div');
      placeholder.innerHTML = textArea.parentElement?.getAttribute('original-element') ?? '';
      const newNode = placeholder.firstElementChild;

      parentElement.replaceWith(newNode!);
      parentElement.innerHTML = textAreaHtml;
    }
  });

  const preElems = moduleFrame?.contentDocument?.querySelectorAll('[contenteditable="true"]') ?? [];

  preElems?.forEach((preElem: any) => {
    preElem.onblur({ target: preElem });
  });

  return {
    moduleHtml: moduleElement?.innerHTML || innerDoc?.body?.innerHTML || '',
    filledAttrValue: moduleElement?.getAttribute(DATA_FILLED_ATTRIBUTE) || '',
  };
};
