import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState, SetStateAction } from 'react';

import { useLoader } from '@utils';
import { deployment, experience } from '@modules';
import { useAppDispatch } from '@store';

import { RightPanelElems, TreatmentBuilderContext } from '../TemplateView.const';
import { DeletedExperience } from '../TemplateView.types';

import {
  CurrentTarget,
  HTMLElementWithDocument,
  ModuleTemplateType,
  UseWrapHtmlElemsHook,
} from './SampleTemplate.types';
import {
  elemClick,
  elemMouseEnter,
  elemMouseLeave,
  getUniqueStyles,
  getTemplateDoc,
  initializeJourneyStyleId,
  removePrevElemInfo,
} from './SampleTemplate.utils';
import { DATA_FILLED_ATTRIBUTE, DATA_SPLITTER_ATTRIBUTE, DOC_REDEFINE_DELAY } from './SampleTemplate.const';
import { TopBarContext } from '@layouts';

/**
 * SampleTemplate hooks
 * @returns {Function}
 */

export const useWrapHtmlElems = (
  originalInnerHtml: string | undefined,
  setShowLibraryPanel: (value: SetStateAction<boolean>) => void,
  setModalTopPosition: (value: SetStateAction<number>) => void,
  setRightPanelElem: (value: SetStateAction<RightPanelElems>) => void,
  setDeletedExperience: (value: SetStateAction<DeletedExperience>) => void,
  moduleAction: boolean,
): UseWrapHtmlElemsHook => {
  const dispatch = useAppDispatch();

  const {
    chosenExperienceId,
    chosenModuleInnerHtml,
    chosenElemId,
    showModuleEditor,
    setChosenElemId,
    setShowModuleEditor,
    setModuleIndex,
    setChosenModuleElemId,
    setChosenExperienceId,
    setChosenLibrary,
    setIsModuleChanged,
    setShowRemoveExperienceModal,
  } = useContext(TreatmentBuilderContext);

  const [redefinedDoc, setRedefinedDoc] = useState('');
  const [moduleHtmlElem, setModuleHtmlElem] = useState('');
  const [moduleEvent, setModuleEvent] = useState<MouseEvent | null>(null);

  const wrapperRef = useRef(0);
  const [, setTopBarCtx] = useContext(TopBarContext);

  const treatments = experience.useMindsetsData();

  // useLoader(experience.useMindsetsMeta(), deployment.useInfoEmailTemplateMeta());

  const handleElemMouseEnter = useCallback(
    (e: MouseEvent, elemId: string, isTemplateView: boolean) => {
      if (chosenElemId !== elemId) {
        elemMouseEnter(e, elemId, 'template', isTemplateView);
      }
    },
    [chosenElemId],
  );

  const handleElemMouseLeave = useCallback(
    (e: MouseEvent, elemId: string) => {
      if (chosenElemId !== elemId) {
        elemMouseLeave(e);

        //redefine elem styles
        const target = e.currentTarget as CurrentTarget;
        const styles = target.getAttribute('style');
        const prevStyleItems = getUniqueStyles(styles || '');

        target?.setAttribute('style', `${prevStyleItems} \nborder-color: transparent;`);

        //removing elemInfo element
        const { contentDocument } = document.getElementById('template') as HTMLElementWithDocument;
        const elemInfo = contentDocument.getElementById(`elem-info-${elemId}`);
        const trashCan = contentDocument.getElementById(`trash-can-${elemId}`);

        if (elemInfo) {
          elemInfo.remove();
        }

        if (trashCan) {
          trashCan.remove();
        }
      }
    },
    [chosenElemId],
  );

  const handleElemClick = useCallback(
    (e, elemId: string, isTemplateView: boolean) => {
      if (e.preventDefault) {
        e.preventDefault();
      }

      const experienceId = +e.currentTarget.getAttribute(DATA_FILLED_ATTRIBUTE);

      elemClick(e, elemId, 'template', chosenElemId, isTemplateView, setChosenElemId, setShowRemoveExperienceModal);
      setChosenExperienceId(experienceId);
      setRightPanelElem(RightPanelElems.TREATMENTS);
      setModuleEvent(e);

      if (e) {
        setDeletedExperience((prevState) => ({
          ...prevState,
          splitterId: (e.target as CurrentTarget).getAttribute(DATA_SPLITTER_ATTRIBUTE),
        }));
      }

      const { contentDocument } = document.getElementById('template') as HTMLElementWithDocument;
      const moduleDivs = contentDocument.querySelectorAll('div');

      Array.from(moduleDivs)
        .filter((item) => item.id.includes('div') && item.getAttribute(DATA_FILLED_ATTRIBUTE))
        .forEach((module, index) => {
          if (elemId === module.id) {
            setModuleIndex(index);
          }
        });
    },
    [
      chosenElemId,
      setChosenElemId,
      setChosenExperienceId,
      setDeletedExperience,
      setModuleIndex,
      setRightPanelElem,
      setShowRemoveExperienceModal,
    ],
  );

  const handleOpenModuleEditor = useCallback(
    (e: MouseEvent) => {
      setModuleHtmlElem((e.target as CurrentTarget)?.outerHTML);

      setShowLibraryPanel(true);

      const rect = (e.target as CurrentTarget)?.getBoundingClientRect();

      setModalTopPosition(rect?.top);
      setRightPanelElem(RightPanelElems.MODULE_SETTINGS);

      //TODO: Lock scroll of the content
      // contentDocument.body.style.overflow = 'hidden';
    },
    [setModalTopPosition, setRightPanelElem, setShowLibraryPanel],
  );

  const handleJourneyDoubleCLick = useCallback(
    (e: MouseEvent, elemId: string) => {
      e.preventDefault();
      e.stopPropagation();
      const templateDoc = getTemplateDoc();

      const scrollingArea = document.getElementById('sample-template');
      const { contentDocument } = document.getElementById('template') as HTMLElementWithDocument;
      const currentElem = contentDocument.getElementById(elemId);
      const elementTopPosition = (currentElem?.getBoundingClientRect().top ?? 0) - 180;

      if (scrollingArea) {
        scrollingArea.scrollTo(0, elementTopPosition);
        scrollingArea.style.overflow = 'hidden';
      }

      if (templateDoc) {
        removePrevElemInfo(elemId, templateDoc);
      }

      //setting current element
      handleOpenModuleEditor(e);
    },
    [handleOpenModuleEditor],
  );

  const handleCloseWindow = useCallback(() => {
    setModuleHtmlElem('');
    setRightPanelElem(RightPanelElems.TREATMENTS);
    setChosenLibrary(null);
    setShowLibraryPanel(false);
    setIsModuleChanged(false);

    setTopBarCtx((prev) => ({
      ...prev,
      contentModuleName: '',
    }));

    if (showModuleEditor) {
      setShowModuleEditor(false);
    }
  }, [
    setChosenLibrary,
    setIsModuleChanged,
    setRightPanelElem,
    setShowLibraryPanel,
    setShowModuleEditor,
    setTopBarCtx,
    showModuleEditor,
  ]);

  const redefineJourneyElemMethods = useCallback(
    (contentDocument: Document) => {
      const divs = contentDocument.querySelectorAll('div');
      const moduleDivs = Array.from(divs).filter(
        (div) => div.id.includes('div') && div.getAttribute(DATA_FILLED_ATTRIBUTE),
      );

      moduleDivs.forEach((div, index) => {
        const divPrevId = div.id;
        const filledAttrValue = div.getAttribute(DATA_FILLED_ATTRIBUTE);

        if (divPrevId.includes('div') && filledAttrValue) {
          const experienceId = +filledAttrValue;
          const treatment = treatments.find((item) => item.experienceId === experienceId);
          const modulesCount = (treatment?.modules || []).length;

          if (modulesCount) {
            const type: ModuleTemplateType = modulesCount === 1 ? 'single' : 'group';
            const divId = `journey-${type}-div-${index}`;

            div.onmouseenter = (event) => handleElemMouseEnter(event, divId, true);
            div.onmouseleave = (event) => handleElemMouseLeave(event, divId);
            div.onclick = (event) => !event.detail || (event.detail == 1 && handleElemClick(event, divId, false));
            div.ondblclick = (event) => handleJourneyDoubleCLick(event, divId);
          }
        }
      });

      const hRefs = contentDocument.querySelectorAll('a');

      hRefs.forEach((hRef) => {
        hRef.onclick = (e: any) => {
          e.preventDefault();
        };
      });
    },
    [handleElemClick, handleElemMouseEnter, handleElemMouseLeave, handleJourneyDoubleCLick, treatments],
  );

  const redefineHtml = useCallback(() => {
    const parser = new DOMParser();

    if (originalInnerHtml) {
      const parsedDoc = parser.parseFromString(originalInnerHtml, 'text/html');
      const templateDoc = getTemplateDoc();

      if (templateDoc?.body) {
        templateDoc.body.innerHTML = parsedDoc.documentElement?.innerHTML;

        return templateDoc.body.innerHTML;
      }
    }

    return '';
  }, [originalInnerHtml]);

  useEffect(() => {
    setRedefinedDoc(redefineHtml());
  }, [redefineHtml]);

  useLayoutEffect(() => {
    wrapperRef.current = window.setTimeout(() => {
      if (redefinedDoc) {
        const templateDoc = getTemplateDoc();

        if (templateDoc) {
          initializeJourneyStyleId(templateDoc, treatments, chosenElemId);
        }
      }
    }, DOC_REDEFINE_DELAY);

    return () => {
      window.clearTimeout(wrapperRef.current);
    };
  }, [redefinedDoc, setChosenElemId, moduleAction, treatments, chosenElemId]);

  useEffect(() => {
    wrapperRef.current = window.setTimeout(() => {
      if (redefinedDoc) {
        const templateDoc = getTemplateDoc();

        if (templateDoc) {
          redefineJourneyElemMethods(templateDoc);
        }
      }
    }, DOC_REDEFINE_DELAY);
  }, [redefinedDoc, redefineJourneyElemMethods, moduleAction]);

  useEffect(() => {
    if (chosenExperienceId) {
      const promise = dispatch(experience.thunk.getItem(chosenExperienceId));

      return () => promise.abort();
    }
  }, [dispatch, chosenExperienceId]);

  useEffect(() => {
    if (showModuleEditor && moduleEvent) {
      handleOpenModuleEditor(moduleEvent);
    }
  }, [handleOpenModuleEditor, moduleEvent, showModuleEditor]);

  useEffect(() => {
    const templeteDoc = document.getElementById('template') as HTMLElementWithDocument;

    if (chosenModuleInnerHtml && templeteDoc?.contentDocument) {
      const moduleElem = templeteDoc?.contentDocument.getElementById(chosenElemId);

      if (moduleElem) {
        moduleElem.innerHTML = chosenModuleInnerHtml;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenModuleInnerHtml]);

  return {
    redefinedDoc,
    chosenExperienceId,
    moduleHtmlElem,
    handleCloseWindow,
    handleElemClick,
  };
};
