import { useStyles } from './CopyrightFooter.styles';
import { content } from '@content';
import { textTemplate } from '@utils';
import { Box } from '@material-ui/core';

export function CopyrightFooter() {
  const styles = useStyles();

  return (
    <Box className={styles.copyright}>
      {textTemplate(content.copyright, { year: new Date().getFullYear(), company: content.company })}
    </Box>
  );
}
