import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    pwdResetContainer: {
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F4F6F9',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: '100vh',
      width: '100vw',
    },
    pwdReset: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    form: {
      width: '42.9rem',
      background: variables.color.primary.white,
      padding: theme.spacing(6),
    },
    buttons: {
      flex: 1,
      justifyContent: 'flex-end',
    },
  }),
);
