import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Bar } from '@nivo/bar';
import { BarTooltipDatum } from './BarStackedChart.props';

import { Typography } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { format } from '@utils';

import { BarStackedChartProps, BarStackedChartTooltipProps as TooltipProps } from './BarStackedChart.props';
import { generateColors, calculatePadding, getYAxisMilestones, calculateWidth } from './BarStackedChart.utils';
import { useStyles } from './BarStackedChart.styles';
import { BarStackedChartTooltip as Tooltip } from './BarStackedChartTooltip';

export const BarStackedChart = ({ data, title, keys }: BarStackedChartProps): JSX.Element | null => {
  const styles = useStyles();

  const [barWidth, setBarWidth] = React.useState(0);

  const chartRef = React.useRef(null);

  const yAxisRef = React.useRef(null);

  const maxValue = useMemo(() => Math.max(...data.map((item) => item.value ?? 0)), [data]);

  const sanitizedData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        value: item.value ?? 0,
      })),
    [data],
  );

  // The following removes the hidden value for compatibility with bar data
  const modifiedSanitizedData = useMemo(
    () =>
      data.map(({ hidden, data: nestedData, ...item }) => ({
        ...item,
        value: item.value ?? 0,
      })),
    [data],
  );

  React.useLayoutEffect(() => {
    setBarWidth(calculateWidth(sanitizedData, chartRef, yAxisRef));
  }, [setBarWidth, chartRef, yAxisRef, sanitizedData]);

  return sanitizedData && !sanitizedData.length ? null : (
    <Box className={styles.barStackedChart} {...{ ref: chartRef }}>
      <Box className={styles.axisY} {...{ ref: yAxisRef }}>
        <Typography.Caption className={styles.legendY}>{content.impressions}</Typography.Caption>
        <Box className={styles.milestonesY}>
          {getYAxisMilestones(maxValue).map((milestone) => (
            <Typography.Tag key={milestone}>{format.number(milestone)}</Typography.Tag>
          ))}
        </Box>
      </Box>
      <Box className={styles.content}>
        <Typography.Headline className={styles.title}>{title}</Typography.Headline>
        <Box className={styles.bar}>
          <Bar
            width={barWidth}
            height={390}
            gridYValues={[0]}
            margin={{ right: -20, bottom: 25, left: -20 }}
            data={modifiedSanitizedData}
            indexBy="deploymentId"
            keys={keys}
            colors={generateColors(keys.length)}
            padding={calculatePadding(sanitizedData)}
            labelSkipWidth={16}
            labelSkipHeight={16}
            enableLabel={false}
            axisLeft={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              format: (id) => sanitizedData.find((item) => item.data.deploymentId === id)?.data.label,
            }}
            tooltip={(props: BarTooltipDatum) => <Tooltip {...(props as TooltipProps)} />}
            theme={{
              tooltip: {
                container: {
                  background: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                },
              },
              text: { color: variables.color.secondary.gray },
            }}
            layers={['grid', 'axes', 'bars', 'markers']}
          />
        </Box>
      </Box>
    </Box>
  );
};
