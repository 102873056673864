import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageContainer: {
      backgroundColor: variables.color.primary.white,

      '&[data-ui-type=primary]': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),

        '& $title': {
          padding: theme.spacing(2),
        },
      },
    },

    label: {
      marginTop: '0',
      marginBottom: '5px',
    },

    titleWrapper: {
      padding: '2rem 2.5rem',
    },
    title: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      paddingTop: '9px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      gap: '8px',
      borderBottom: `solid 1px ${variables.color.primary.lightGray}`,
      marginBottom: '10px',
      marginTop: '1.5rem',
      paddingLeft: '30px',
    },
    headerItem: {
      height: '25px',
      width: '109px',
      fontSize: '10px',
      textTransform: 'uppercase',
    },
    caption: {
      color: variables.color.primary.darkestGray,
    },
    maxScale: {
      width: '153px',
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
      paddingBottom: '10px',
    },
  }),
);
