import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

/**
 * TopBar component styles
 * @returns {Function}
 */

const smallViewWidth = '1250px';

export const useStyles = makeStyles((theme) =>
  createStyles({
    topBar: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'right', // 'space-between',
      padding: theme.spacing(0, 1.5),
      background: variables.color.primary.white,
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      minHeight: '6.3rem',

      [`@media (max-width: ${smallViewWidth})`]: {
        flexWrap: 'wrap',
      },
    },

    sectionName: {
      fontSize: variables.font.size.xxl,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xxxl,
      color: variables.color.primary.darkestGray,
    },

    crumbsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    edit: {
      '&&': {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },

    crumbs: {
      marginLeft: theme.spacing(2),

      '&:first-child': {
        marginLeft: 0,
      },
    },

    backBtn: {
      stroke: variables.color.primary.mediumGray,

      '&:hover': {
        stroke: variables.color.primary.darkestGray,
      },
    },

    crumbsLink: {
      display: 'block',
      textDecoration: 'none',
      color: variables.color.primary.mediumGray,

      '&:hover': {
        color: variables.color.primary.mainPurple,
      },
    },

    crumbsActive: {
      color: variables.color.primary.darkestGray,
    },

    crumbsLabel: {
      color: variables.color.primary.darkGray,
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.xs,
    },

    crumbsValue: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xl,
    },

    editableCrumbsLabel: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
    },

    last: {
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.bold,
    },

    menu: {
      padding: theme.spacing(1, 0),
      width: '4.2rem',
    },

    actions: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start', //'left',
      width: '100%',
    },

    pickerWrapper: {
      flex: '1 1 auto',
      marginLeft: theme.spacing(6),
      display: 'flex',

      [`@media (max-width: ${smallViewWidth})`]: {
        marginLeft: theme.spacing(2),
      },
    },

    experiencePicker: {
      maxWidth: '22rem',
    },

    businessUnitPicker: {
      maxWidth: '22rem',
    },

    picker: {
      padding: theme.spacing(1, 0),
      margin: theme.spacing(0, 1.5),
      maxWidth: `calc(25% - ${theme.spacing(1)}px)`,

      [`@media (max-width: ${smallViewWidth})`]: {
        margin: theme.spacing(0, 1),
      },
    },

    buttonContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      minWidth: '42.3rem', // '43.6rem',
      width: '42.3rem', // '43.6rem',
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        minWidth: '21.1rem', //'25.8rem',
        width: '21.1rem', //'25.8rem',
      },
    },
    teamplteListButtonContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'right',
      width: '100%',
    },

    saveButton: {
      '&:disabled': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `0.1rem solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.lightGrayDarker,
      },
    },
    cancelButton: {
      backgroundColor: variables.color.primary.white,
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      color: variables.color.primary.darkestGray,

      '&:hover': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `1px solid ${variables.color.primary.mainPurple}`,
        color: variables.color.primary.mainPurple,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },

    disableScreenOverlay: {
      backgroundColor: 'rgba(255,255,255, 0.8)',
      height: `calc(100% - ${variables.topBar.height})`,
      left: 0,
      position: 'fixed',
      top: `calc(${variables.topBar.height} - 0.7rem)`,
      width: 'calc(100% - 44.8rem)', // 44.8rem comes from the width of the right side panel on a wide display
      zIndex: 1000,
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        width: 'calc(100% - 27rem)', // 27rem comes from the width of the right side panel on a narrow display
      },
    },
    addSignalRightPanel: {
      backgroundColor: variables.color.primary.white,
      height: `calc(100% - ${variables.topBar.height})`,
      // height: `calc(100% - ${variables.topBar.height} + 0.8rem)`,
      position: 'absolute',
      right: '0',
      top: `calc(${variables.topBar.height} - 0.8rem)`,
      width: '44.8rem',
      zIndex: 1000,
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        width: '27rem', // 27rem comes from the width of the right side panel on a narrow display
      },
    },
  }),
);
