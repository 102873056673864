import * as modules from '@modules';

const { base, ...customModules } = modules;

function hasSlice(module: any): module is { slice: { name: string; reducer: any } } {
  return module && module.slice && typeof module.slice.name === 'string' && typeof module.slice.reducer !== 'undefined';
}

export default Object.values(customModules).reduce(
  (reducers, module) =>
    hasSlice(module)
      ? {
          ...reducers,
          [module.slice.name]: module.slice.reducer,
        }
      : reducers,
  {},
);
