import { Bar, ComputedBarDatum } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';

import { format } from '@utils';
import { variables } from '@styles';
import { BarChartProps } from './BarChart.props';
import { BarChartTitle } from './BarChartTitle';
import { BarChartComponent } from './BarChartComponent';
import { BarChartAreaLayer } from './BarChartAreaLayer';
import { BarChartTick } from './BarChartTick';
import { BarChartTrend } from './BarChartTrend';
import { BarChartData } from '@modules';

export const BarChart = ({
  data,
  average,
  color,
  title,
  className,
  unit,
  legendLeft,
  trend,
  roundYAxeValues = false,
}: BarChartProps): JSX.Element => {
  const roundTo5 = (x: number) => Math.ceil(x / 5) * 5;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const maxValue = Math.max(...data.map((item: any) => item.value));
  if (data && !data.length) {
    return <></>;
  }

  const renderTick = (props: AxisTickProps<string>) => <BarChartTick data={data} {...props} />;

  // TODO: determine how these work with BarChartAreaLayer and implement accordingly.
  // These are just placeholder functions to avoid typescript errors.
  const showTooltip = (tooltip: React.ReactNode, event: React.MouseEvent<SVGElement>) => {};
  const hideTooltip = () => {};

  // transform the bar data to work with BarChartAreaLayer
  const transformBars = (bars: ComputedBarDatum<BarChartData>[]): [number, number][] => {
    return bars.map((bar) => [bar.data.indexValue as number, bar.data.value as number]);
  };

  return (
    <div>
      {trend ? <BarChartTrend trend={trend} /> : null}
      <Bar
        enableLabel={false}
        gridYValues={[0]}
        width={450}
        height={520}
        data={data}
        keys={['value']}
        margin={{
          top: 60,
          right: 10,
          bottom: 180,
          left: 56,
        }}
        theme={{
          tooltip: {
            container: {
              background: 'transparent',
              border: 0,
              boxShadow: 'none',
            },
          },
          labels: {
            text: {
              fill: variables.color.secondary.gray,
            },
          },
        }}
        barComponent={(props) => (
          <BarChartComponent
            {...props}
            className={className}
            unit={unit}
            height={props.bar.height}
            data={props.bar.data}
            showTooltip={showTooltip}
            hideTooltip={hideTooltip}
            y={props.bar.y}
            color={props.bar.color}
          />
        )}
        borderRadius={4}
        indexBy="id"
        colors={[color]}
        layers={[
          'grid',
          'axes',
          'bars',
          'markers',
          () => (data.length ? <BarChartTitle title={title} x={53} /> : null),
          (props) => (
            <BarChartAreaLayer
              {...props}
              className={className}
              average={average ?? 0}
              unit={unit}
              showTooltip={showTooltip}
              hideTooltip={hideTooltip}
              bars={transformBars(props.bars)}
              yScale={props.yScale as (x: number) => number}
            />
          ),
        ]}
        markers={
          average
            ? [
                {
                  axis: 'y',
                  value: average,
                  lineStyle: { stroke: variables.color.primary.gray, strokeWidth: 1 },
                  legendOrientation: 'vertical',
                },
              ]
            : []
        }
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: -90,
          renderTick,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          legend: legendLeft || '',
          legendPosition: 'middle',
          legendOffset: -50,
          format: (value) => {
            if (roundYAxeValues) {
              return Math.floor(value) === value ? value : false;
            }
            return format.number(value);
          },
        }}
        maxValue={roundTo5(maxValue)}
      />
    </div>
  );
};
