export const getInnerHTMLLengthExcludingTokens = (element: HTMLElement): number => {
  // Clone the element to avoid modifying the original
  const clonedElement = element.cloneNode(true) as HTMLElement;

  // Remove all personefy-token elements from the cloned element
  const tokens = clonedElement.querySelectorAll('personefy-token');
  tokens.forEach((token) => token.remove());

  const innerHTML = clonedElement.innerHTML.replaceAll('&amp;', `&`).replaceAll('&nbsp;', ` `);

  // Return the length of the innerHTML of the modified cloned element
  return innerHTML.length;
};
