import { SVGProps } from 'react';

export const SaveOutlined = ({
  width = 40,
  height = 40,
  fill = '#494949',
  stroke = '#494949',
  strokeWidth = '1',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    aria-hidden="true"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    ></path>
  </svg>
);
