/* Main (basic) application styles */

import { image } from '@content';
import { createTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { variables } from './variables';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: true;
    xl: true;
  }
}

type OverridesNameToClassKey = {
  [P in keyof Required<MuiPickersOverrides>]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends OverridesNameToClassKey {
    [key: string]: unknown;
  }
}

const datePickerHighlightColor = `${variables.color.primary.green} !important`;

export const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: [variables.font.family.base, variables.font.family.fallback].join(','),
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: datePickerHighlightColor,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: datePickerHighlightColor,
      },
      current: {
        '&:not(.MuiPickersDay-daySelected)': {
          color: datePickerHighlightColor,
        },
      },
    },
    MuiPickersYear: {
      root: {
        '&:focus': {
          color: datePickerHighlightColor,
        },
      },
      yearSelected: {
        color: datePickerHighlightColor,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginBottom: '1.2rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '62.5%',
          height: '100%',
          overflowY: 'hidden',
        },
        body: {
          margin: 0,
          fontFamily: variables.font.family.base,
          fontSize: variables.font.size.md,
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100vw',
          overflow: 'hidden',
        },
        '#root': {
          height: '100%',
        },
        '*::-webkit-scrollbar': {
          width: variables.scrollbar.width,
          height: variables.scrollbar.height,
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#dfe5eb',
          borderRadius: variables.borderRadius.md,
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: variables.font.size.lg,
        padding: '0.8rem 1.2rem',
      },
    },
    MuiButton: {
      contained: {
        color: variables.color.primary.white,
        lineHeight: variables.lineHeight.sm,
        border: `${variables.borderWidth.sm} solid transparent`,
        borderRadius: variables.borderRadius.sm,
        boxShadow: 'none',
        fontWeight: variables.font.weight.bold,
        fontSize: variables.font.size.md,
        textTransform: 'capitalize',
        '&:hover': {
          boxShadow: 'none',
        },
        '&$focusVisible': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: variables.color.primary.lightGray,
          color: variables.color.primary.mediumGray,
        },
      },
      containedPrimary: {
        backgroundColor: variables.color.primary.mainPurple,
        padding: '0.6rem 1.2rem',
        '&:hover': {
          color: variables.color.primary.white,
          backgroundColor: 'none',
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), ${variables.color.primary.mainPurple}`,
        },
        '&:active': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${variables.color.primary.mainPurple}`,
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.08)',
        },
      },
      containedSecondary: {
        padding: `0.2rem ${variables.gap.lg} 0.1rem`,
        backgroundColor: variables.color.primary.lightPurple,
        '&:hover': {
          border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
          backgroundColor: 'transparent',
          color: variables.color.primary.lightPurple,
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        borderRadius: variables.borderRadius.sm,
        lineHeight: variables.lineHeight.md,
        letterSpacing: variables.letterSpacing.lg,
        fontWeight: variables.font.weight.bold,
        fontSize: variables.font.size.md,
        textTransform: 'capitalize',
      },
      outlinedPrimary: {
        border: `${variables.borderWidth.lg} solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.darkestGray,
        padding: '0.6rem 1.2rem',

        '&:hover, &:active': {
          border: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
          color: variables.color.primary.mainPurple,
          '& svg path': {
            stroke: variables.color.primary.mainPurple,
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
          backgroundColor: variables.color.primary.backgroundLightGray,
          boxShadow: 'inset 0 0.2rem 0.2rem rgba(0, 0, 0, 0.16)',
        },
      },
      outlinedSecondary: {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}`,
        color: variables.color.primary.mainPurple,
        padding: '0.6rem 1.2rem',
        backgroundColor: variables.color.primary.backgroundLightGray,
        '&:hover': {
          backgroundColor: 'transparent',
          border: `${variables.borderWidth.sm} solid ${variables.color.secondary.blue}`,
          color: variables.color.secondary.blue,
        },
      },
      text: {
        paddingTop: variables.gap.xxs,
        paddingBottom: variables.gap.xxs,
        textTransform: 'capitalize',
        fontWeight: variables.font.weight.bold,
        borderRadius: variables.borderRadius.sm,

        '&:disabled': {
          opacity: variables.opacity.medium,
        },
      },
    },
    MuiLink: {
      root: {
        color: variables.color.primary.lightPurple,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.md,
        letterSpacing: '0.06rem',
        '&:hover': {
          color: variables.color.secondary.blue,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        color: variables.color.primary.darkestGray,
        lineHeight: variables.lineHeight.lg,

        '&$disabled': {
          color: variables.color.primary.lightGrayDarker,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '0.8rem 1.2rem',
      },
      inputAdornedStart: {
        paddingLeft: '0.8rem',
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        color: variables.color.primary.mediumGray,
        paddingRight: 8,
        transform: 'scale(0.889)',

        '&$checked': {
          color: variables.color.primary.mainPurple,

          '& svg': {
            background: `url(${image.checkboxChecked}) center no-repeat`,
          },
          '& svg path': {
            display: 'none',
          },
        },

        '&$disabled': {
          color: variables.color.primary.lightGray,
        },

        '&$checked$disabled': {
          '& svg': {
            background: `url(${image.checkboxCheckedDisabled}) center no-repeat`,
          },
        },

        '&.MuiCheckbox-indeterminate': {
          color: variables.color.primary.mainPurple,
        },
      },
      checked: {},
    },
    MuiRadio: {
      root: {
        color: variables.color.primary.mediumGray,
        paddingRight: 8,
        transform: 'scale(0.8)',

        '&$checked': {
          color: `${variables.color.primary.mainPurple} !important`,
        },

        '&$disabled': {
          color: variables.color.primary.lightGray,
        },
      },
      checked: {},
    },
    MuiSwitch: {
      root: {
        width: '4rem',
        height: '2rem',
        padding: 0,
      },
      switchBase: {
        padding: '0.2rem',
        color: variables.color.primary.white,
        '& + $track': {
          backgroundColor: variables.color.primary.lightGrayDarker,
        },
        '&$checked': {
          color: variables.color.primary.white,
          transform: 'translateX(2rem)',
          '& + $track': {
            opacity: 1,
            backgroundColor: variables.color.primary.mainPurple,
          },
        },
        '&$disabled': {
          color: variables.color.primary.backgroundLightestGray,
          '&$checked': {
            transform: 'translateX(2rem)',
          },
          '& + $track': {
            opacity: 1,
            backgroundColor: `${variables.color.primary.lightGrayDarker} !important`,
          },
        },
      },
      thumb: {
        width: '1.6rem',
        height: '1.6rem',
        boxShadow: 'none',
      },
      track: {
        borderRadius: '2rem',
        opacity: 1,
        background:
          "#fff url(\"data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9.49993 2.41414C9.89045 2.02361 9.89045 1.39045 9.49992 0.999924C9.1094 0.6094 8.47623 0.6094 8.08571 0.999924L3.49992 5.58571L1.91414 3.99993C1.52361 3.6094 0.890449 3.6094 0.499924 3.99992C0.1094 4.39045 0.1094 5.02361 0.499924 5.41414L2.79282 7.70703C3.18334 8.09756 3.81651 8.09756 4.20703 7.70703L9.49993 2.41414Z' fill='white'/></svg>\") 5px center no-repeat",
      },
      checked: {},
    },
    MuiInput: {
      root: {
        padding: '0.8rem 1.2rem',
        border: `1px solid ${variables.color.primary.lightGray}`,
        borderRadius: variables.borderRadius.sm,
        fontWeight: variables.font.weight.semiBold,
        fontSize: variables.font.size.md,
        color: variables.color.primary.darkestGray,

        '& ::placeholder': {
          color: variables.color.primary.mediumGray,
        },
      },
      input: {
        lineHeight: variables.lineHeight.lg,
        padding: '1px 0',
        height: 'auto',
      },
    },
    MuiInputBase: {
      root: {
        padding: '0.2rem 1.2rem',
        border: `0.01rem solid ${variables.color.primary.lightGray}`,
        borderRadius: variables.borderRadius.sm,
        fontWeight: variables.font.weight.semiBold,
        fontSize: variables.font.size.md,
        color: variables.color.primary.darkestGray,
        lineHeight: variables.lineHeight.md,

        '& ::placeholder': {
          color: variables.color.primary.mediumGray,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        marginBottom: variables.gap.xs,
      },
    },
    MuiTextField: {
      root: {
        '& .MuiInputBase-root': {
          color: variables.color.primary.gray,
          fontSize: variables.font.size.md,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline.Mui-focused:after': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline.Mui-error::after': {
          borderBottom: 'none',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: variables.color.secondary.orange,
          fontSize: variables.font.size.sm,
          fontWeight: variables.font.weight.bold,
          lineHeight: variables.lineHeight.sm,
          letterSpacing: variables.letterSpacing.sm,
        },
        '& .Mui-focused': {
          backgroundColor: variables.color.secondary.white,
        },
        '& .MuiInput-underline::before': {
          borderBottom: 'none',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '0.1rem',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: variables.color.primary.green,
      },
    },
    MuiTable: {
      root: {
        borderSpacing: '0 0.5rem',
        borderCollapse: 'separate',
        marginTop: '-0.5rem',
      },
    },
    MuiTableRow: {
      head: {
        background: variables.color.primary.white,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        padding: variables.gap.lg,
      },
      body: {
        background: 'rgba(255, 255, 255, 0.7)',
        height: '12rem',
        wordBreak: 'break-word',
        '&:first-child': {
          borderTopLeftRadius: variables.borderRadius.sm,
          borderBottomLeftRadius: variables.borderRadius.sm,
        },
        '&:last-child': {
          borderBottomRightRadius: variables.borderRadius.sm,
          borderTopRightRadius: variables.borderRadius.sm,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.lg,
        color: variables.color.primary.darkestGray,
        borderRadius: variables.borderRadius.sm,
        minHeight: 'initial',

        '&:hover': {
          color: variables.color.primary.mainPurple,
          backgroundColor: variables.color.primary.lighterPurple,
        },
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: variables.borderRadius.sm,
          borderTopRightRadius: variables.borderRadius.sm,
        },
        '&:last-child': {
          borderBottomLeftRadius: variables.borderRadius.sm,
          borderBottomRightRadius: variables.borderRadius.sm,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '2.4rem',

        '&$expanded': {
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
        },
      },
      expandIcon: {
        marginRight: 0,
        backgroundImage: `url(${image.accordionExpand})`,

        '&$expanded': {
          backgroundImage: `url(${image.accordionCollapse})`,
        },
      },
      content: {
        alignItems: 'center',
        margin: 0,
        minHeight: '4.4rem',

        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 24,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: variables.color.primary.lightGray,
      },
    },
  },
  breakpoints: {
    values: {
      lg: 1072,
      xl: 1346,
    },
  },
});
