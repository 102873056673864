import Button from '@material-ui/core/Button';
import { SLPInput } from './SLPInput';

import { content } from '@content';
import { useStyles } from './SubjectLinePreheader.styles';
import { useState, useCallback, useEffect } from 'react';

import { SubjectLinePreheaderProps } from './SubjectLinePreheader.props';
import Cursor from '@utils/cursor';

export const SubjectLinePreheader = ({
  subject = '',
  preheader = '',
  onSave,
  onDoubleClick,
  onCancel,
  onCursorPositionChange,
}: SubjectLinePreheaderProps): JSX.Element => {
  const styles = useStyles();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [editedSubjectLine, setEditedSubjectLine] = useState(subject);
  const [editedPreheader, setEditedPreheader] = useState(preheader);
  const [initialSubjectLine] = useState(subject);
  const [initialPreheader] = useState(preheader);
  const [isEditModeSubjectLine, setIsEditModeSubjectLine] = useState(false);
  const [isEditModePreheader, setIsEditModePreheader] = useState(false);

  useEffect(() => {
    setEditedSubjectLine(subject);
  }, [subject]);

  useEffect(() => {
    setEditedPreheader(preheader);
  }, [preheader]);

  const handleSave = useCallback(() => {
    //TODO: disable the form fields and buttons

    // disable edit mode
    setIsEditMode(false);
    setIsEditModeSubjectLine(false);
    setIsEditModePreheader(false);
    onCursorPositionChange(undefined, -1);

    // Save
    onSave(editedSubjectLine, editedPreheader);
  }, [editedPreheader, editedSubjectLine, onCursorPositionChange, onSave]);

  const handleTextDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const targetName = event.currentTarget.getAttribute('data-name');

      if (!isEditMode && targetName) {
        setIsEditMode(true);
        setIsEdited(false);

        if (targetName === 'editor-subjectLine') {
          setIsEditModeSubjectLine(true);
          setIsEditModePreheader(false);

          // set the target field to edit to the parent
          onDoubleClick(targetName);
        } else if (targetName === 'editor-preheader') {
          setIsEditModePreheader(true);
          setIsEditModeSubjectLine(false);

          // set the target field to edit to the parent
          onDoubleClick(targetName);
        } else {
          setIsEditMode(false);
          setIsEditModePreheader(false);
          setIsEditModeSubjectLine(false);
        }
      }
    },
    [isEditMode, onDoubleClick],
  );

  const handleCancel = useCallback(() => {
    setEditedSubjectLine(subject);
    setEditedPreheader(preheader);

    setIsEditModeSubjectLine(false);
    setIsEditModePreheader(false);

    setIsEditMode(false);
    setIsEdited(false);

    onCursorPositionChange(undefined, -1);
    onCancel();
  }, [onCancel, onCursorPositionChange, preheader, subject]);

  const handleInputChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      const currentTarget = event.currentTarget;
      const { innerHTML } = currentTarget;
      let position = -1;

      if (document.activeElement === currentTarget) {
        position = Cursor.getCursorPosition(event.currentTarget);

        // pass the updated cursor position to TopBar
        onCursorPositionChange(event.currentTarget, position);
      }

      switch (currentTarget.id) {
        case 'editor-subjectLine':
          if (innerHTML !== subject) {
            setEditedSubjectLine(innerHTML);
            setIsEdited(true);
          }
          break;
        case 'editor-preheader':
          if (innerHTML !== preheader) {
            setEditedPreheader(innerHTML);
            setIsEdited(true);
          }
          break;
        default:
          break;
      }

      if (document.activeElement === currentTarget) {
        setTimeout(() => {
          Cursor.setCursorPosition(currentTarget, position);
        }, 0);
      }
    },
    [onCursorPositionChange, preheader, subject],
  );

  // ignore carriage returns
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;

    if (document.activeElement === currentTarget) {
      return;
    }
  }, []);

  // Check if the edited values are the same as the initial values
  useEffect(() => {
    if (initialSubjectLine === subject && initialPreheader === preheader) {
      setIsEdited(false);
    } else {
      setIsEdited(true);
    }
  }, [initialPreheader, initialSubjectLine, preheader, subject]);

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={styles.inputRow}>
          <SLPInput
            id="editor-subjectLine"
            isEditMode={isEditModeSubjectLine}
            label={content.subjectLine}
            initialValue={editedSubjectLine}
            onInput={handleInputChange}
            onKeyDown={handleKeyDown}
            onDoubleClick={handleTextDoubleClick}
            onCursorPositionChange={onCursorPositionChange}
            setValue={setEditedSubjectLine}
          />
        </div>
        <div className={styles.inputRow}>
          <SLPInput
            id="editor-preheader"
            isEditMode={isEditModePreheader}
            label={content.preheader}
            initialValue={editedPreheader}
            onInput={handleInputChange}
            onKeyDown={handleKeyDown}
            onDoubleClick={handleTextDoubleClick}
            onCursorPositionChange={onCursorPositionChange}
            setValue={setEditedPreheader}
          />
        </div>
      </div>
      {isEditMode && (
        <div className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isEdited}
            onClick={handleSave}
            className={[styles.button, styles.saveButton].join(' ')}
          >
            {content.save}
          </Button>

          <Button variant="contained" onClick={handleCancel} className={[styles.button, styles.cancelButton].join(' ')}>
            {content.cancel}
          </Button>
        </div>
      )}
    </>
  );
};
