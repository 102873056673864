export default class Cursor {
  /**
   * Get the current cursor position within a contenteditable div.
   * @param parentElement The contenteditable div element.
   * @returns The character offset of the cursor position.
   */
  static getCursorPosition(parentElement: HTMLElement): number {
    const selection = window.getSelection();
    let charCount = 0;

    if (selection?.focusNode) {
      const focusNode = selection.focusNode;
      const focusOffset = selection.focusOffset;

      if (Cursor.isChildOf(focusNode, parentElement)) {
        let node = focusNode;
        charCount = focusOffset;

        // Traverse backward to calculate the total character offset
        while (node && node !== parentElement) {
          if (node.previousSibling) {
            node = node.previousSibling;

            if (node.nodeType === Node.TEXT_NODE) {
              charCount += node.textContent?.length ?? 0;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              charCount += (node as HTMLElement).outerHTML.length;
            }
          } else {
            node = node.parentNode as Node;
          }
        }
      }
    }

    return charCount;
  }

  /**
   * Set the cursor position within a contenteditable div.
   * @param parentElement The contenteditable div element.
   * @param position The character offset where the cursor should be placed.
   */
  static setCursorPosition(parentElement: HTMLElement, position: number): void {
    const range = document.createRange();
    const selection = window.getSelection();

    let charCount = 0;
    let node: ChildNode | null = parentElement.firstChild; // Start from the first child node

    // Traverse the child nodes to find the correct position
    while (node && charCount <= position) {
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent?.length ?? 0;

        if (charCount + textLength >= position) {
          // Place the caret within the text node at the correct offset
          range.setStart(node, position - charCount);
          range.setEnd(node, position - charCount);
          break;
        } else {
          charCount += textLength;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementLength = (node as HTMLElement).outerHTML.length;

        if (charCount + elementLength >= position) {
          // Place the caret after the element
          range.selectNodeContents(node);
          range.collapse(false);
          break;
        } else {
          charCount += elementLength;
        }
      }

      node = node.nextSibling; // Move to the next sibling node
    }

    // If the position is at or beyond the end, collapse the range at the end of the content
    if (!node) {
      range.setStart(parentElement, parentElement.childNodes.length);
      range.collapse(true);
    }

    // Apply the range to the selection
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  /**
   * Check if a node is a child of a given parent element.
   * @param node The node to check.
   * @param parentElement The parent element.
   * @returns True if the node is a child of the parent element, false otherwise.
   */
  static isChildOf(node: Node | null, parentElement: HTMLElement): boolean {
    while (node) {
      if (node === parentElement) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
}
