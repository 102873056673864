import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    inputContainer: {
      maxWidth: 'calc(100% - 10rem)',
      minWidth: '500px',
      width: 'calc(100vw - 10rem - 7.2rem - 44.8rem - 44.8rem)', // label offset, left nav, left panel, right panel widths
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        width: 'calc(100vw - 10rem - 7.2rem - 25rem - 27rem)', // label offset, left nav, left panel, right panel widths
      },
    },
    fieldLabel: {
      lineHeight: '2.6rem',
      minWidth: '53.6rem',
      padding: '0 1rem 0 0',
      textAlign: 'right',
      width: '53.6rem',
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        minWidth: '33.8rem',
        width: '33.8rem',
      },
    },
    textContainer: {
      fontSize: '1.2rem',
      height: '2.6rem',
    },

    input: {
      boxSizing: 'border-box',
      fontFamily: 'Manrope, sans-serif',
      fontSize: '1.2rem',
      height: '2.6rem',
      lineHeight: '2.4rem',
      outline: 'none',
      overflow: 'hidden',
      resize: 'none',
      whiteSpace: 'nowrap',
      width: '100%',
      '&[contenteditable="true"]': {
        border: `0.1rem solid ${variables.color.primary.lightGray}`,
        borderRadius: '0.6rem',
      },
      '& personefy-token': {
        backgroundColor: variables.color.primary.capsulePurple,
        borderRadius: '0.7rem',
        color: variables.color.primary.black,
        padding: '0 1rem',
        pointerEvents: 'none',
      },
    },
    textEdit: {
      border: `0.1rem solid ${variables.color.primary.lightGray}`,
      borderRadius: '0.6rem',
      padding: '0 1.2rem 0 1.2rem',
    },
    textNoEdit: {
      fontSize: '1.2rem',
      lineHeight: '2.6rem',
      height: '2.6rem',
      paddingLeft: '1.3rem',
      width: '100%',
      '&:hover': {
        border: `0.1rem solid ${variables.color.primary.lightGray}`,
        borderRadius: '0.6rem',
        lineHeight: '2.4rem',
        padding: '0 1.2rem',
      },
    },
  }),
);
